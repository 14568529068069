/** @format */

import React from 'react';
import sidebar from 'img/sidebar.png';
import notification from 'img/bell.png';
import Date from 'components/datePicker';
import RightSideBar from 'components/admin/widgets/rightSidebar';
import { toggleNotification, toggleMenu } from 'state/slice/sidebar';
import { useDispatch } from 'react-redux';
import UserProfile from 'components/userProfile';
import Right from './widgets/right';

import Menu from './widgets/menu';

const SuperAdminLayout = ({ children }) => {
  const dispatch = useDispatch();

  return (
    <div className='container'>
      <Menu />

      <header className='header'>
        {' '}
        <div className='right-heading-div'>
          <img
            src={sidebar}
            alt='Toogle side menu'
            onClick={() => dispatch(toggleMenu())}
          />
          <span>Dashboard</span>
        </div>
        <div className='date-filter-container'>
          {/* filter*/}

          <>
            <p>Filter By</p>
            <Date />
          </>
        </div>
        <div div className='left-heading-div '>
          <img src={notification} alt='bell icon' />
          <img
            src={sidebar}
            alt='Toogle side bar'
            onClick={() => dispatch(toggleNotification())}
          />
        </div>
      </header>
      <main className='main'>{children}</main>
      <RightSideBar path={'api/stats/super-admin/sales'} />
      <UserProfile />
    </div>
  );
};

export default SuperAdminLayout;
