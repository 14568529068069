/** @format */

import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AgentLayout from 'components/agents/layout';
import axios from 'axios';

import { Col, Row, Progress } from 'antd';
import ACARequestForm from 'components/agents/widgets/ACARequestForm';
import SubmitionForm from 'components/agents/widgets/saleSubmissionForm';
import { setFormDisplay } from 'state/slice/buttonSlice';
const FormType = () => {
  const dispatch = useDispatch();
  const [lastSubmission, setLastSubmission] = useState(null); // State to store the last submission
  const [lastRequest, setLastRequest] = useState(null);
  const [monthlyStats, setMonthlyStats] = useState(null);
  console.log(monthlyStats);
  const formDisplay = useSelector((state) => state.button.formDisplay);

  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

  useEffect(() => {
    const fetchLastRequest = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/aca/request-form/last-submission`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include auth token if needed
            },
          }
        );
        setLastRequest(response.data); // Set the last submission in state
      } catch (error) {
        console.error('Error fetching last submission:', error);
      }
    };
    fetchLastRequest(); // Fetch last submission on component mount
  }, [apiUrl]);

  useEffect(() => {
    const fetchLastSubmission = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/policy-form/last-submission`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include auth token if needed
            },
          }
        );
        setLastSubmission(response.data); // Set the last submission in state
      } catch (error) {
        console.error('Error fetching last submission:', error);
      }
    };
    fetchLastSubmission(); // Fetch last submission on component mount
  }, [apiUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');

        const response = await axios.get(
          `${apiUrl}/api/stats/agent/monthly/sales-premium`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setMonthlyStats(response.data);
      } catch (err) {
        console.log(
          err.response ? err.response.data : 'Error fetching sales data'
        );
      }
    };

    fetchData();
  }, [apiUrl]);

  // Function to fetch the last submission
  const getProgressColor = (totalSales) => {
    return totalSales < 80 ? '#95A4FC' : '#A1E3CB';
  };

  // Function to get status name
  const getStatus = (totalSales) => {
    return totalSales < 80 ? 'In Progress' : 'Complete';
  };

  const handleDisplay = (type) => {
    dispatch(setFormDisplay(type));
  };

  return (
    <div style={{ marginLeft: '40px' }}>
      {formDisplay === 'table' && (
        <Row className='form-link' gutter={48}>
          <Col
            xs={12}
            sm={8}
            md={8}
            lg={6}
            xl={6}
            onClick={() => handleDisplay('Sub')}
            className='form-card-wrapper'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='form-card-Title'>Sales Submission Form</span>
              {lastSubmission && (
                <span style={{ color: 'rgba(28, 28, 28, 0.40)' }}>
                  Last Submission:{' '}
                  {new Date(lastSubmission.createdAt).toLocaleString()}
                </span>
              )}
            </div>

            {monthlyStats && ( // Check if monthlyStats is not null
              <>
                {' '}
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}>
                    <div
                      style={{
                        backgroundColor: getProgressColor(
                          monthlyStats.totalSales
                        ),
                        height: '10px',
                        width: '10px',
                      }}
                      className='lead-indicator'></div>
                    <p
                      style={{
                        color: getProgressColor(monthlyStats.totalSales),
                      }}>
                      {getStatus(monthlyStats.totalSales)}
                    </p>
                  </div>
                  <Progress
                    percent={Math.min(monthlyStats.totalSales, 100)}
                    strokeColor={getProgressColor(monthlyStats.totalSales)}
                    status='active'
                  />

                  <div style={{ marginTop: '5px' }}>
                    {' '}
                    {monthlyStats.totalSales}{' '}
                    <span style={{ color: 'rgba(28, 28, 28, 0.20)' }}>/</span>{' '}
                    100{' '}
                    <span style={{ color: 'rgba(28, 28, 28, 0.20)' }}>
                      Sales Goal
                    </span>
                  </div>
                </div>
              </>
            )}
            <div className='card-line'></div>
            {monthlyStats && (
              <div className='deals-wrapper'>
                <div>
                  <span style={{ color: 'rgba(28, 28, 28, 0.20)' }}>ACA</span>
                  <span>{monthlyStats.totalMajorMed}</span>
                </div>
                <div>
                  <span style={{ color: 'rgba(28, 28, 28, 0.20)' }}>U65</span>
                  <span>{monthlyStats.totalprivateMed}</span>
                </div>
              </div>
            )}
          </Col>
        </Row>
      )}

      {formDisplay === 'Sub' && <SubmitionForm />}
    </div>
  );
};

const Form = () => {
  return (
    <AgentLayout>
      <FormType />
    </AgentLayout>
  );
};

export default Form;
