/** @format */

import axios from 'axios';
import { accessToken } from 'utils/accessToken';
const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

// Function to add or update downline access
export const addDownlineAccess = async (userId, officeIds) => {
  try {
    const token = localStorage.getItem('accessToken'); // Assuming you are storing the auth token in localStorage
    const response = await axios.post(
      `${apiUrl}/api/super-admin/downline-access`,
      { userId, officeIds },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data; // Returning the response data
  } catch (error) {
    console.error('Error adding or updating downline access:', error);
    throw error; // Re-throwing the error to handle it in the calling component
  }
};
/**
 * Fetches the downline access data for a given user.
 *
 * @param {string} userId - The ID of the user whose downline access data is to be fetched.
 * @returns {Promise<Object>} - A promise that resolves to the downline access data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getDownlineAccess = async (userId) => {
  try {
    const token = localStorage.getItem('accessToken'); // Assuming you are storing the auth token in localStorage
    const response = await axios.get(
      `${apiUrl}/api/super-admin/downline-access/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Returning the response data
  } catch (error) {
    console.error('Error fetching downline access:', error);
    throw error; // Re-throwing the error to handle it in the calling component
  }
};

/**
 * Fetches downline access for a user by userId.
 * @param {String} userId - The ID of the user.
 * @returns {Object} - The response data containing offices.
 */
export const getDownlineAccessByUserId = async (userId) => {
  try {
    const token = localStorage.getItem('accessToken'); // Assuming you are storing the auth token in localStorage
    const response = await axios.get(
      `${apiUrl}/api/super-admin/downline-access/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Expected format: { message, offices: [ { officeId, officeName }, ... ] }
  } catch (error) {
    console.error('Error fetching downline access:', error);
    throw error; // Re-throwing the error to handle it in the calling component
  }
};

/**
 * Fetches downline access for the authenticated user.
 * @returns {Object} - The response data containing offices.
 */
export const getAuthenticatedUserDownlineAccess = async () => {
  try {
    const token = localStorage.getItem('accessToken'); // Assuming you are storing the auth token in localStorage
    const response = await axios.get(
      `${apiUrl}/api/super-admin/downline-access`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.downlineAccess; // Expected format: { offices: [ { officeId, officeName }, ... ] }
  } catch (error) {
    console.error('Error fetching downline access:', error);
    throw error; // Re-throwing the error to handle it in the calling component
  }
};

/**
 * Adds user access.
 * @param {Object} accessData - The data to be added.
 * @param {string} accessData.userId - The user ID.
 * @param {boolean} accessData.ssnAccess - Access to SSN.
 * @param {Array<string>} accessData.downlineAccess - List of office IDs for downline access.
 * @param {boolean} accessData.QAFormAccess - Access to QA form.
 * @returns {Object} - The response data containing the result.
 */
export const addUserAccess = async (accessData) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/super-admin/access`,
      accessData,
      accessToken
    );

    return response.data; // Expected response format
  } catch (error) {
    console.error('Error adding user access:', error);
    throw error; // Re-throw the error to handle it in the calling component
  }
};

/**
 * Edits user access.
 * @param {string} userId - The user ID.
 * @param {Object} updatedAccess - The data to be updated.
 * @param {boolean} updatedAccess.ssnAccess - Access to SSN.
 * @param {Array<string>} updatedAccess.downlineAccess - List of office IDs for downline access.
 * @param {boolean} updatedAccess.QAFormAccess - Access to QA form.
 * @returns {Object} - The response data containing the result.
 */
export const editUserAccess = async (userId, updatedAccess) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/super-admin/access/${userId}`,
      updatedAccess,
      accessToken
    );

    return response.data; // Expected response format
  } catch (error) {
    console.error('Error editing user access:', error);
    throw error; // Re-throw the error to handle it in the calling component
  }
};

/**
 * Gets user access.
 * @param {string} userId - The user ID.
 * @returns {Object} - The response data containing the user access with populated office names.
 */
export const getUserAccess = async (userId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/super-admin/access/user/${userId}`,
      accessToken
    );

    return response.data; // Expected response format
  } catch (error) {
    console.error('Error getting user access:', error);
    throw error; // Re-throw the error to handle it in the calling component
  }
};
