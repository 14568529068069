import React, { useState } from "react";
import { useSelector } from "react-redux";

import axios from "axios";

const BatchAORegistrationForm = () => {
  const [entries, setEntries] = useState([{ firstName: "", lastName: "" }]);
  const officeId = useSelector((state) => state.dropDown.officeId);

  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App
  const handleInputChange = (index, event) => {
    const values = [...entries];
    values[index][event.target.name] = event.target.value;
    setEntries(values);
  };

  const handleAddEntry = () => {
    setEntries([...entries, { firstName: "", lastName: "" }]);
  };

  const handleRemoveEntry = (index) => {
    const values = [...entries];
    values.splice(index, 1);
    setEntries(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate entries
    if (entries.some((entry) => !entry.firstName || !entry.lastName)) {
      alert("Please fill out all fields in each entry");
      return;
    }

    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${apiUrl}/api/aor/batch-register`,
        {
          officeId,
          entries,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(officeId);
      if (response.status === 201) {
        alert("NPN overrides registered successfully");
        // Clear form fields or redirect as needed
        setEntries([{ firstName: "", lastName: "" }]);
      }
    } catch (error) {
      console.error("There was an error registering the NPN overrides", error);
      alert("Failed to register NPN overrides");
    }
  };

  return (
    <div className="form-layout">
      <form onSubmit={handleSubmit}>
        <div className="form-wrapper">
          {entries.map((entry, index) => (
            <div key={index}>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={entry.firstName}
                onChange={(event) => handleInputChange(index, event)}
                required
              />

              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={entry.lastName}
                onChange={(event) => handleInputChange(index, event)}
                required
              />

              <button type="button" onClick={() => handleRemoveEntry(index)}>
                Remove
              </button>
            </div>
          ))}
        </div>
        <button type="button" onClick={handleAddEntry}>
          Add Another Entry
        </button>
        <button type="submit">Register Batch</button>
      </form>
    </div>
  );
};

export default BatchAORegistrationForm;
