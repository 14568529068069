/** @format */

import React, { useState } from 'react';
import SuperAdminLayout from '../../components/super-admin/layout';
import Filter from 'img/ArrowsDownUp.svg';
import Search from 'img/Search.svg';
import AgentStatsTable from 'components/admin/widgets/agentU65StatsTable';

import U65Podium from 'components/u65Podium';

const U65Leaderboard = () => {
  const [activeTab, setActiveTab] = useState('Leaderboard');

  const handleTab = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '28px',
      }}>
      <div className='campaign-header-containger'>
        <div className='leaderboard-tab-wrapper'>
          <span
            onClick={() => handleTab('Leaderboard')}
            className={activeTab === 'Leaderboard' ? 'active' : ''}>
            Agent leaderboard
          </span>
        </div>
      </div>
      {activeTab === 'Leaderboard' && (
        <div>
          {' '}
          <U65Podium path={'api/stats/super-admin/sales-table'} />
          <div style={{ marginTop: '20px' }}>
            <div className='filter-container'>
              <div className='filter'>
                <img src={Filter} alt='filer icon' />
              </div>

              <div className='search'>
                <img src={Search} alt='search icon' />
                <input />
              </div>
            </div>
            <AgentStatsTable endpoint='api/stats/super-admin/sales-table' />
          </div>
        </div>
      )}
    </div>
  );
};

const Leaderboard = () => {
  return (
    <SuperAdminLayout>
      <U65Leaderboard />
    </SuperAdminLayout>
  );
};

export default Leaderboard;
