/** @format */

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import U65AgentSalesChart from './u65AgentSaleChart';
import ProfileIcon from 'img/profileRound.svg';
import AgentSalesChart from 'components/super-admin/widgets/agentSalesChart';
import First from 'img/1st.svg';
import Second from 'img/2nd.svg';
import { getProfilePic } from 'api/routes';
import Third from 'img/3rd.svg';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

const U65Podium = ({ path }) => {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profilePics, setProfilePics] = useState({}); // State to store profile pictures
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

  useEffect(() => {
    const fetchSalesData = async () => {
      const token = localStorage.getItem('accessToken');

      try {
        const response = await axios.get(`${apiUrl}/${path}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            startDate,
            endDate,
          },
        });
        setSalesData(response.data);
        // Fetch profile pictures for each agent
        response.data.forEach(async (agent) => {
          if (!agent.userId) {
            return;
          }
          try {
            const profilePicData = await getProfilePic(agent.userId); // Fetch profile picture
            const profilePicUrl = profilePicData
              ? profilePicData.profilePicUrl
              : null;

            // Check if profile picture exists
            setProfilePics((prevState) => ({
              ...prevState,
              [agent.userId]: profilePicUrl || ProfileIcon, // Set fetched picture or fallback to default
            }));
          } catch (error) {
            console.error(
              `Error fetching profile picture for ${agent.userId}:`,
              error
            );
            setProfilePics((prevState) => ({
              ...prevState,
              [agent.userId]: ProfileIcon, // Fallback to default if error occurs
            }));
          }
        });
      } catch (err) {
        setError(
          err.response ? err.response.data : 'Error fetching sales data'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchSalesData();
  }, [apiUrl, path, startDate, endDate]);

  const sortedSalesData = [...salesData].sort(
    (a, b) =>
      b.totalPrivateMedPremium +
      b.totalAncillariesPremium -
      (a.totalPrivateMedPremium + a.totalAncillariesPremium)
  );

  const topThreeAgents = sortedSalesData.slice(0, 3);

  const capitalizeFullName = (name) => {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let isDown = false;
    let startX = 0;
    let scrollLeft = 0;

    const end = () => {
      isDown = false;
      container.style.cursor = 'default';
      container.style.removeProperty('user-select');
    };

    const start = (e) => {
      isDown = true;
      container.style.cursor = 'grabbing';
      container.style.userSelect = 'none'; // Prevent text selection
      startX = e.pageX || e.touches[0].pageX;
      scrollLeft = container.scrollLeft;
    };

    const move = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX || e.touches[0].pageX;
      const walk = (x - startX) * 2; // Multiply movement for faster scroll
      container.scrollLeft = scrollLeft - walk;
    };

    // Add event listeners
    container.addEventListener('mousedown', start);
    container.addEventListener('mouseleave', end);
    container.addEventListener('mouseup', end);
    container.addEventListener('mousemove', move);
    container.addEventListener('touchstart', start);
    container.addEventListener('touchend', end);
    container.addEventListener('touchmove', move);

    // Clean up event listeners on unmount
    return () => {
      container.removeEventListener('mousedown', start);
      container.removeEventListener('mouseleave', end);
      container.removeEventListener('mouseup', end);
      container.removeEventListener('mousemove', move);
      container.removeEventListener('touchstart', start);
      container.removeEventListener('touchend', end);
      container.removeEventListener('touchmove', move);
    };
  }, [containerRef]);

  function BackgroundImageComponent({ imageUrl, place }) {
    const style = {
      width: '73px',
      height: '55px',
      position: 'relative',
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '50%',
    };

    return (
      <div style={style}>
        <img className='place' alt='number placement' src={place} />
      </div>
    );
  }

  const displayAgentDetails = (agent, placeImage, place) => {
    if (!agent) {
      return (
        <div className={`podium ${place}`}>
          <div>
            <span style={heading}>No Agent</span>
          </div>
          <div className='pf-l-s-cr-wrapper'>
            <BackgroundImageComponent
              imageUrl={ProfileIcon}
              place={placeImage}
            />
            <div className='profile-stats-table'>
              <table>
                <thead>
                  <tr>
                    <th>Premium</th>
                    <th>ACA</th>
                    <th style={{ borderRight: 'none' }}>U65</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>$0</td>
                    <td>0</td>
                    <td style={{ borderRight: 'none' }}>0</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <span style={heading}>Weekly Sales Activity</span>
        </div>
      );
    }

    const agentName = capitalizeFullName(agent.agentName);
    const totalPremium =
      agent.totalPrivateMedPremium + agent.totalAncillariesPremium;
    const totalACA = agent.totalMajorMedSales;
    const totalU65 = agent.totalPrivateMedSales;

    return (
      <div className={`podium ${place}`}>
        <div>
          <span style={heading}>
            {place.charAt(0).toUpperCase() + place.slice(1)} - {agentName}
          </span>
        </div>
        <div className='pf-l-s-cr-wrapper'>
          <BackgroundImageComponent
            imageUrl={profilePics[agent.userId] || ProfileIcon}
            place={placeImage}
          />
          <div className='profile-stats-table'>
            <table>
              <thead>
                <tr>
                  <th>Premium</th>
                  <th>ACA</th>
                  <th style={{ borderRight: 'none' }}>U65</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${totalPremium.toLocaleString()}</td>
                  <td>{totalACA}</td>
                  <td style={{ borderRight: 'none' }}>{totalU65}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <span style={heading}>Weekly Sales Activity</span>
        <U65AgentSalesChart userId={agent.userId} />
      </div>
    );
  };

  const heading = {
    fontSize: '12px',
    fontWeight: 600,
  };

  return (
    <div>
      {loading ? (
        <div className='top-three-container'>
          <div className='podium gold'>
            <Skeleton height={30} width={200} />
            <div className='pf-l-s-cr-wrapper'>
              <Skeleton circle={true} height={73} width={73} />
              <Skeleton height={30} width={200} />
              <Skeleton height={30} width={200} />
            </div>
            <Skeleton height={30} width={200} />
            <Skeleton height={100} width={200} />
          </div>
          <div className='podium silver'>
            <Skeleton height={30} width={200} />
            <div className='pf-l-s-cr-wrapper'>
              <Skeleton circle={true} height={73} width={73} />
              <Skeleton height={30} width={200} />
              <Skeleton height={30} width={200} />
            </div>
            <Skeleton height={30} width={200} />
            <Skeleton height={100} width={200} />
          </div>
          <div className='podium bronze'>
            <Skeleton height={30} width={200} />
            <div className='pf-l-s-cr-wrapper'>
              <Skeleton circle={true} height={73} width={73} />
              <Skeleton height={30} width={200} />
              <Skeleton height={30} width={200} />
            </div>
            <Skeleton height={30} width={200} />
            <Skeleton height={100} width={200} />
          </div>
        </div>
      ) : (
        <div ref={containerRef} className='top-three-container'>
          {displayAgentDetails(topThreeAgents[0], First, 'gold')}
          {displayAgentDetails(topThreeAgents[1], Second, 'silver')}
          {displayAgentDetails(topThreeAgents[2], Third, 'bronze')}
        </div>
      )}
    </div>
  );
};

export default U65Podium;
