/** @format */

/**
 * Capitalizes the first letter of a given string.
 * @param {string} text - The string to capitalize.
 * @return {string} The capitalized string.
 */
export function capitalizeFirstLetter(text) {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
}
