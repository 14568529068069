/** @format */

import React, { useState } from 'react';
import CustoLayout from 'components/Custo/layout';
import PostDateTable from 'components/Custo/widgets/postDateTable';
import { useSelector } from 'react-redux';
import OfficeNames from 'components/super-admin/widgets/officeNames';
import AdminFormTable from 'components/admin/widgets/policyFormTable';
import RequestFormsTable from 'components/admin/widgets/requestFormTable';
//import U65ExportCSVButton from "components/super-admin/widgets/u65ExportButton";
const FormTables = () => {
  //const selectedFormId = useSelector((state) => state.button.selectedFormId);
  const selectedOfficeId = useSelector((state) => state.dropDown.officeId);
  const [formDatas, setFormDatas] = useState([]); // State to hold the form data
  const [tab, setTab] = useState('Post');
  const handleTab = (tab) => {
    setTab(tab);
  };
  const { userData } = useSelector((state) => state.user);
  const officeId = userData?.officeId || null; // This safely extracts officeId from userData
  return (
    <div className='form-page-container'>
      <div className='form-page-header'>
        <div className='admin-form-tab-wrapper'>
          {' '}
          <div>
            <span
              className={tab === 'Post' ? 'table-active' : ''}
              onClick={() => handleTab('Post')}>
              Post Date
            </span>
          </div>
        </div>

        <div className='a-p-tab-wrapper'>
          {officeId === null && <OfficeNames />}
          {/* CSV Export Button */}
        </div>
      </div>{' '}
      {tab === 'Forms' && selectedOfficeId && (
        <AdminFormTable
          endpoint={`api/policy-form/super-admin/form-list/${selectedOfficeId}`}
          setFormDatas={setFormDatas}
        />
      )}{' '}
      {tab === 'Post' && <PostDateTable />}
      {tab === 'Request' && (
        <RequestFormsTable
          path={`aca/request-form/forms/${selectedOfficeId}`}
        />
      )}
      {/* Pass setFormDatas to FormTable */}
    </div>
  );
};
const CustoPostDate = () => {
  return (
    <CustoLayout>
      <FormTables />
    </CustoLayout>
  );
};

export default CustoPostDate;
