/** @format */

import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

const U65AgentSalesChart = ({ userId, style }) => {
  const [chartData, setChartData] = useState({
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    datasets: [
      {
        label: 'Sales',
        data: [0, 0, 0, 0, 0],
        backgroundColor: '#1C1C1C',
        borderWidth: 1,
        borderRadius: 20, // Rounded corners for bars
        barThickness: 7,
      },
      {
        label: 'Premium',
        data: [0, 0, 0, 0, 0],
        backgroundColor: '#1c1c1c79',
        borderWidth: 1,
        borderRadius: 20, // Rounded corners for bars
        barThickness: 7,
      },
    ],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${apiUrl}/api/stats/agent/${userId}/weekly/sales-premium`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;

        // Debugging the API response
        console.log('API Response:', data);

        const salesData = data.map((item) => item.totalSales);
        const premiumData = data.map((item) => item.totalPremium);

        setChartData((prevState) => ({
          ...prevState,
          datasets: [
            {
              ...prevState.datasets[0],
              data: salesData,
            },
            {
              ...prevState.datasets[1],
              data: premiumData,
            },
          ],
        }));
      } catch (error) {
        setError('Error fetching sales and leads data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, apiUrl]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={style} className='leaderboard-bar-chart'>
      <Bar width={544} height={193} options={options} data={chartData} />
    </div>
  );
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
  },
};

export default U65AgentSalesChart;
