import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminLayout from "components/admin/layout";
import Filter from "img/ArrowsDownUp.svg";
import RegisterAgentsForm from "components/admin/widgets/registerAgents";
import Search from "img/Search.svg";
import { useSelector, useDispatch } from "react-redux";
import { toggleForm } from "state/slice/buttonSlice";
import DownArrow from "img/ArrowLineDown.png";

const AdminAgentsPage = () => {
  const userData = useSelector((state) => state.user.userData);
  const userFormActive = useSelector((state) => state.button.addUserForm);
  const officeId = userData.officeId;
  const dispatch = useDispatch();
  //console.log(userData.officeId);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App
  ////////////dispatch funtion
  const handleForm = (state) => {
    dispatch(toggleForm(state));
  };
  /////////////get the list off agents
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  // Using Redux to access the user's office ID

  useEffect(() => {
    const fetchAgents = async () => {
      setIsLoading(true);
      try {
        // Assuming the endpoint to fetch agents by office ID is correctly set up on your backend
        const response = await axios.get(`${apiUrl}/api/users/agents`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        setAgents(response.data);
        console.log(response.data);
      } catch (err) {
        console.error("Failed to fetch agents:", err);
        setError("Failed to load agents");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgents();
  }, [apiUrl]);

  return (
    <AdminLayout>
      <div>
        <div className="user-header">
          <span className="user-tb-name ">Users</span>

          <div className="add-Agent-Wrapper">
            <button onClick={() => handleForm(true)}>
              <span>New Agent</span>
              <img src={DownArrow} alt="down arrow" />
            </button>
          </div>
        </div>
        <div className="filter-container">
          <div className="filter">
            <img src={Filter} alt="Filter" />
            <div className="a-p-tab-wrapper"> </div>
          </div>

          <div className="search">
            <img src={Search} alt="Search" />
            <input placeholder="Search..." />
          </div>
        </div>
        <table
          className="campaign-table"
          style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {agents.map((user) => (
              <tr key={user._id}>
                <td>{user.firstname}</td>
                <td>{user.lastname}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {userFormActive && <RegisterAgentsForm AdminofficeId={officeId} />}
      </div>
    </AdminLayout>
  );
};

export default AdminAgentsPage;
