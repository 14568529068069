/** @format */

import React, { useState } from 'react';
import AdminLayout from 'components/admin/layout';
import FullPieChart from 'components/super-admin/widgets/fullPieChart';
import GlobalTable from 'components/super-admin/widgets/globalTable';
const AdminVendorsOverview = () => {
  const [activeTab, setActiveTab] = useState('Top10');

  const handleTab = (tab) => {
    setActiveTab(tab);
  };
  return (
    <AdminLayout>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
        <div className='campaign-header-containger'>
          <div className='campaign-tab-wrapper'>
            <span
              onClick={() => handleTab('Top10')}
              className={activeTab === 'Top10' ? 'active' : ''}>
              Top Global Vendors (beta)
            </span>{' '}
            <span
              onClick={() => handleTab('Global Vendors')}
              className={activeTab === 'Global Vendors' ? 'active' : ''}>
              Global Vendors
            </span>
          </div>
        </div>

        {activeTab === 'Top10' && <FullPieChart />}
        {activeTab === 'Global Vendors' && <GlobalTable />}
      </div>
    </AdminLayout>
  );
};

export default AdminVendorsOverview;
