/** @format */

import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Key from 'img/Key.svg';
import Envelope from 'img/EnvelopeSimple.svg';
import ArrowLeft from 'img/ArrowLineLeft.svg';

const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App

const MultiStepResetForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    username: '',
    confirmationCode: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInitiateReset = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/api/auth/initiate-reset`, {
        username: formData.username,
      });
      toast.success(response.data.message);
      setStep(2);
    } catch (error) {
      console.error(
        error.response?.data?.message || 'An error occurred. Please try again.'
      );
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setStep(3);
  };

  const handleConfirmReset = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }
    try {
      const response = await axios.post(`${apiUrl}/api/auth/confirm-reset`, {
        username: formData.username,
        confirmationCode: formData.confirmationCode,
        newPassword: formData.newPassword,
      });
      toast.success(response.data.message);
      setStep(4);
    } catch (error) {
      console.error(
        error.response?.data?.message || 'An error occurred. Please try again.'
      );
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <div className='password-reset-container'>
      <form className='password-reset-wrapper'>
        {step === 1 && (
          <div className='reset-wrapper'>
            <img src={Key} alt='key illustration' />
            <div className='reset-heading'>
              <span style={{ fontSize: '24px', fontWeight: 600 }}>
                Reset Password
              </span>
              <span style={{ fontSize: '14px' }}>
                Please enter the your user name used for you account
              </span>
            </div>

            <input
              type='text'
              name='username'
              value={formData.username}
              onChange={handleInputChange}
              required
              placeholder='Username'
            />

            <button type='button' onClick={handleInitiateReset}>
              Send Reset Code
            </button>
          </div>
        )}
        {step === 2 && (
          <div>
            <div className='back-arrow-wrapper ' onClick={() => setStep(1)}>
              {' '}
              <img src={ArrowLeft} alt='left arrow' />
            </div>

            <div className='reset-wrapper'>
              <img src={Envelope} alt='evelope icon' />
              <div className='reset-heading'>
                <span style={{ fontSize: '24px', fontWeight: 600 }}>
                  Check Your Inbox
                </span>
                <span style={{ fontSize: '14px' }}>
                  A verification code was sent to your email, please enter it
                  below.
                </span>
              </div>

              <input
                type='text'
                name='confirmationCode'
                placeholder='6 digit security code'
                value={formData.confirmationCode}
                onChange={handleInputChange}
                required
              />

              <button type='button' onClick={handleVerifyCode}>
                Verify Code
              </button>
            </div>
          </div>
        )}
        {step === 3 && (
          <div>
            <div className='back-arrow-wrapper ' onClick={() => setStep(2)}>
              {' '}
              <img src={ArrowLeft} alt='left arrow' />
            </div>
            <div className='reset-wrapper'>
              <img src={Key} alt='key illustration' />
              <span style={{ fontSize: '24px', fontWeight: 600 }}>
                Set New Password
              </span>

              <input
                type='password'
                name='newPassword'
                placeholder='New Password'
                value={formData.newPassword}
                onChange={handleInputChange}
                required
              />

              <input
                type='password'
                name='confirmPassword'
                placeholder=' Confirm Password'
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
              />

              <button type='button' onClick={handleConfirmReset}>
                Reset Password
              </button>
            </div>
          </div>
        )}
        {step === 4 && (
          <div className='reset-wrapper'>
            <span style={{ fontSize: '24px', fontWeight: 600 }}>Sucess!</span>
            <span style={{ fontSize: '14px' }}>
              Your password has been successfully reset.
            </span>
            <button
              type='button'
              onClick={() => (window.location.href = '/signin')}>
              Go to Sign In
            </button>
          </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
};

export default MultiStepResetForm;
