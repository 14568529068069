/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import zxcvbn from "zxcvbn";
import Key from "img/Key.svg";

const UpdatePasswordForm = () => {
  const [newPassword, setNewPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [error, setError] = useState("");
  const [isUppercaseMet, setIsUppercaseMet] = useState(false);
  const [isNumberMet, setIsNumberMet] = useState(false);
  const [isSpecialCharMet, setIsSpecialCharMet] = useState(false);
  const [isLengthMet, setIsLengthMet] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

  useEffect(() => {
    const storedSessionJson = sessionStorage.getItem("updatePasswordSession");
    if (!storedSessionJson) {
      setError(
        "Session information is missing or invalid. Please log in again."
      );
      navigate("/signin");
    }
  }, [navigate]);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;

    setNewPassword(newPassword);
    setPasswordStrength(zxcvbn(newPassword).score);

    // Check if each requirement is met
    setIsUppercaseMet(/[A-Z]/.test(newPassword));
    setIsNumberMet(/[0-9]/.test(newPassword));
    setIsLengthMet(newPassword.length >= 8);
    setIsSpecialCharMet(/[!@#$%^&*(),.?":{}|<>]/.test(newPassword));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate password before submission
    if (!isUppercaseMet || !isNumberMet || !isSpecialCharMet) {
      setError(
        "Password must contain at least one uppercase letter, one number, and one special character."
      );
      return;
    }

    const storedSessionJson = sessionStorage.getItem("updatePasswordSession");
    if (!storedSessionJson) {
      setError(
        "Session information is missing or invalid. Please log in again."
      );
      navigate("/signin");
      return;
    }

    let storedSession;
    try {
      storedSession = JSON.parse(storedSessionJson);
    } catch (e) {
      console.error("Parsing stored session data failed:", e);
      setError("Invalid session data. Please log in again.");
      navigate("/signin");
      return;
    }

    const { username, session } = storedSession;

    try {
      // Replace the URL with your API endpoint for password update
      const response = await axios.post(`${apiUrl}/api/auth/update-password`, {
        username,
        newPassword,
        session,
      });

      console.log("Password updated successfully", response.data);

      sessionStorage.removeItem("updatePasswordSession"); // Clear session after successful update

      // Decode the ID token to decide where to navigate after password update
      const idToken = localStorage.getItem("idToken");
      if (idToken) {
        const decodedToken = jwtDecode(idToken);
        const userGroups = decodedToken["cognito:groups"] || [];

        if (userGroups.includes("Admin")) {
          navigate("/admin/dashboard");
        } else if (userGroups.includes("Agent")) {
          navigate("/agent/dashboard");
        } else {
          navigate("/"); // Default route if no specific role found
        }
      } else {
        // No ID token found, navigate to login
        navigate("/signin");
      }
    } catch (error) {
      console.error("Failed to update password:", error);
      setError(`Access Token has expired. Please sign in again.`);
    }
  };

  return (
    <div className="update-formcontainer">
      <div className="update-password-form">
        <img src={Key} alt="" />
        <h2>Setup Password</h2>
        <form style={{ width: "60%" }} onSubmit={handleSubmit}>
          <input
            id="newPassword"
            type="password"
            value={newPassword}
            onChange={handlePasswordChange}
            required
          />
          <div className={`strength-bar strength-${passwordStrength}`}></div>

          {/* Password Requirements */}
          <ul className="password-requirements">
            <li style={{ color: isUppercaseMet ? "green" : "red" }}>
              <p style={{ color: isUppercaseMet ? "green" : "red" }}>
                {" "}
                At least one uppercase letter
              </p>
            </li>
            <li style={{ color: isNumberMet ? "green" : "red" }}>
              <p style={{ color: isNumberMet ? "green" : "red" }}>
                At least one number
              </p>
            </li>
            <li style={{ color: isSpecialCharMet ? "green" : "red" }}>
              <p style={{ color: isSpecialCharMet ? "green" : "red" }}>
                {" "}
                At least one special character
              </p>
            </li>
            <li style={{ color: isLengthMet ? "green" : "red" }}>
              <p style={{ color: isLengthMet ? "green" : "red" }}>
                {" "}
                At least 8 characters
              </p>
            </li>
          </ul>

          <div>
            <button type="submit">Submit</button>
          </div>
        </form>
        {error && <div style={{ color: "red" }}>{error}</div>}
      </div>
    </div>
  );
};

export default UpdatePasswordForm;
