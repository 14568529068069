import { createSlice } from "@reduxjs/toolkit";

const switchSlice = createSlice({
  name: "switchButton",
  initialState: {
    division: "U65",
  },
  reducers: {
    setDivision: (state, action) => {
      state.division = action.payload.officeType;
    },
  },
});

export const { setDivision } = switchSlice.actions;

export default switchSlice.reducer;
