/** @format */
/**
 * # this page is to list all of the forms, by office type + officeID.
 * # Author: Claude Lamarre
 */
import React, { useState } from 'react';
import SuperAdminLayout from '../../components/super-admin/layout';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedFormId } from 'state/slice/buttonSlice';
import AdminFormTable from 'components/admin/widgets/formTable';
import RequestFormsTable from 'components/admin/widgets/requestFormTable';
import PostDateTable from 'components/super-admin/widgets/postDateTable';
import U65ExportCSVButton from 'components/super-admin/widgets/u65ExportButton';
import OfficeNames from 'components/super-admin/widgets/officeNames';
import FormTable from 'components/super-admin/widgets/formTable';
import ExportCSVButton from 'components/super-admin/widgets/exportButton';
import FormDetails from 'components/admin/widgets/formDetails';
import CustomerDetails from 'components/customerDetails';
import Filter from 'img/ArrowsDownUp.svg';
import { Select } from 'antd';
import DownArrow from 'img/ArrowLineDown.png';
import ACARequestFormsTable from 'components/super-admin/widgets/acaRequestFormTable';
const { Option } = Select;
const ACAFormTable = () => {
  const [formDatas, setFormDatas] = useState([]);
  const division = useSelector((state) => state.switchButton.division);

  const selectedOfficeId = useSelector((state) => state.dropDown.officeId); // State to hold the form data
  return (
    <div className='form-page-container'>
      <div className='form-page-header'>
        {' '}
        <div>
          <span className='f-p-t'>Forms</span>
        </div>
        <div className='a-p-tab-wrapper'>
          <OfficeNames division={division} />{' '}
          <ExportCSVButton data={formDatas} /> {/* CSV Export Button */}
        </div>{' '}
      </div>
      <FormTable officeId={selectedOfficeId} setFormDatas={setFormDatas} />{' '}
    </div>
  );
};

const U65FormTables = () => {
  const selectedFormId = useSelector((state) => state.button.selectedFormId);
  const selectedOfficeId = useSelector((state) => state.dropDown.officeId);
  const [formDatas, setFormDatas] = useState([]); // State to hold the form data
  const [tab, setTab] = useState('Forms');
  const [filterBy, setFilterBy] = useState('No Office');
  const handleTab = (tab) => {
    setTab(tab);
  };
  const dispatch = useDispatch();
  const handleFormView = (formId) => {
    dispatch(setSelectedFormId(formId));
    // Additional logic for form view if needed
  };
  const handleFilterChange = (value) => {
    setFilterBy(value);
  };
  const getEndpoint = (activeTab, path1, path2) => {
    if (activeTab === tab && filterBy === 'Office') {
      return path1;
    } else {
      return path2;
    }
  };
  return (
    <div className='form-page-container' style={{ position: 'relative' }}>
      <div className='form-page-header'>
        <div className='admin-form-tab-wrapper'>
          {' '}
          <div>
            <span
              className={tab === 'Forms' ? 'table-active' : ''}
              onClick={() => handleTab('Forms')}>
              Forms
            </span>
          </div>
          <div>
            <span
              className={tab === 'Post' ? 'table-active' : ''}
              onClick={() => handleTab('Post')}>
              Post Dates
            </span>
          </div>
          <div>
            <span
              className={tab === 'Request' ? 'table-active' : ''}
              onClick={() => handleTab('Request')}>
              ACA Request Form (Legacy)
            </span>
          </div>
          <div>
            <span
              className={tab === 'New Request' ? 'table-active' : ''}
              onClick={() => handleTab('New Request')}>
              ACA Request Form
            </span>
          </div>
        </div>

        <div className='a-p-tab-wrapper'>
          {tab === 'Forms' && <U65ExportCSVButton data={formDatas} />}
          {/* CSV Export Button */}
        </div>
      </div>{' '}
      <div className='filter-container ' style={{ marginBottom: '0px' }}>
        <div className='filter'>
          <img src={Filter} alt='Filter' />
          <span>Filter By </span>
          <div className='a-p-tab-wrapper'>
            {/* Dropdown to select between "Office" and "No Office" */}
            <Select
              value={filterBy}
              onChange={handleFilterChange}
              style={{ width: 150 }}>
              <Option value='Office'>Office</Option>
              <Option value='No Office'>All Office</Option>
            </Select>
            {filterBy === 'Office' && <OfficeNames />}
          </div>
        </div>

        {/* Search Input using Ant Design */}
      </div>
      {selectedFormId && tab === 'Request' && <FormDetails />}
      {tab === 'Forms' && selectedOfficeId && (
        <AdminFormTable
          endpoint={getEndpoint(
            'Forms',
            `api/policy-form/super-admin/form-list/${selectedOfficeId}`,
            'api/super-admin/policies/paid-policies'
          )}
          endpoint2={`api/U65Vendors/super-admin/vendors-list/${selectedOfficeId}`}
          setFormDatas={setFormDatas}
        />
      )}{' '}
      {tab === 'Post' && (
        <PostDateTable
          path={getEndpoint(
            'Post',
            `policy-form/super-admin/post-date/list/${selectedOfficeId}`,
            'super-admin/policies/post-dates'
          )}
        />
      )}
      {tab === 'Request' && (
        <RequestFormsTable
          path={`aca/request-form/forms/${selectedOfficeId}`}
        />
      )}{' '}
      {!selectedFormId && tab === 'New Request' && (
        <ACARequestFormsTable
          path={getEndpoint(
            'New Request',
            `super-admin/aca-request-forms/${selectedOfficeId}`,
            'super-admin/policies/aca-request'
          )}
        />
      )}
      {tab === 'New Request' && selectedFormId && <CustomerDetails />}
      {/* Pass setFormDatas to FormTable */}
    </div>
  );
};

const SuperAdminForms = () => {
  const division = useSelector((state) => state.switchButton.division);
  return (
    <SuperAdminLayout>
      {division === 'ACA' ? <ACAFormTable /> : <U65FormTables />}
    </SuperAdminLayout>
  );
};

export default SuperAdminForms;
