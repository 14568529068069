/** @format */

import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';
import { deleteUser } from 'api/userRoutes';
import { useDispatch } from 'react-redux';
import { clearUserId } from 'state/slice/dropDownSlice';

const DeleteUserComponent = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    // Dispatch the action to clear the userId
    dispatch(clearUserId());
  };

  const handleDeleteUser = async () => {
    try {
      setLoading(true);
      await deleteUser(userId); // Call the deleteUser function with userId only
      message.success('User deleted successfully!');
      handleClose();
    } catch (error) {
      message.error('Failed to delete user');
    } finally {
      setLoading(false);
    }
  };

  const showConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this user?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete',
      okType: 'danger',
      cancelText: 'No, cancel',
      onOk() {
        handleDeleteUser(); // Proceed with deletion if confirmed
      },
      onCancel() {
        message.info('User deletion canceled');
      },
    });
  };

  return (
    <div>
      <Button
        type='danger'
        loading={loading}
        onClick={showConfirm} // Show confirmation modal before deletion
        disabled={!userId} // Disable button if no userId
        style={{
          backgroundColor: '#ff4d4f', // Custom red background color
          borderColor: '#ff4d4f', // Ensure the border color matches
          color: '#fff', // White text color
        }}>
        Delete User
      </Button>
    </div>
  );
};

export default DeleteUserComponent;
