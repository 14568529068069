/** @format */

import React, { useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import Exit from 'img/exit.svg'; // Ensure the path to your 'Exit' icon is correct
import { toggleForm } from 'state/slice/buttonSlice';
import { ToastContainer, toast } from 'react-toastify';

const RegisterUsersForm = () => {
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App
  const [userDetails, setUserDetails] = useState({
    firstname: '',
    lastname: '',
    username: '',
    email: '',

    role: '',
  });

  const [isError, setIsError] = useState(false); // Flag for error state

  const selectedOfficeId = useSelector((state) => state.dropDown.officeId); // Optionally fetch the office ID too if needed

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    if (type === 'checkbox' && name === 'division') {
      // Handle checkbox inputs specifically for the 'division' array
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        division: checked
          ? [...prevDetails.division, value]
          : prevDetails.division.filter((div) => div !== value),
      }));
    } else if (type === 'checkbox') {
      // Handle general boolean checkbox inputs (like isAOR)
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [name]: checked,
      }));
    } else {
      // Handle regular inputs like text, email, password, etc.
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsError(false); // Reset error state before attempting registration

    const payload = {
      ...userDetails,
      officeId: selectedOfficeId,
    };

    try {
      await axios.post(`${apiUrl}/api/auth/register`, payload);

      toast.success('User created successfully!');

      // Reset the form fields after successful submission
      setUserDetails({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        role: '',
      });
    } catch (error) {
      // Ensure we properly log and display the error message
      console.error('Error registering user:', error);

      // Check if error response contains a specific error message
      if (error.response && error.response.data && error.response.data.error) {
        // Display the specific error message from the server
        toast.error(error.response.data.error);
      } else {
        // Fallback for other errors
        toast.error('Error creating user. Please try again.');
      }
    }
  };

  return (
    <div className='form-layout '>
      <div className='exit-form-wrapper'>
        <button
          className='exit-form'
          style={{ float: 'right', marginBottom: '30px' }}
          onClick={() => dispatch(toggleForm(false))}>
          <img src={Exit} alt='Exit' style={{ cursor: 'pointer' }} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className='form-container'>
        <div className='form-wrapper'>
          <div className='form-header'>
            <h2
              style={{
                marginTop: '25px',
                textAlign: 'center',
                marginBottom: '8px',
              }}>
              Register New User
            </h2>

            <div className='help-msg-wrapper'>
              <span>If you need any help, please access Help Page.</span>
            </div>
          </div>
          <div className='input-wrapper'>
            <div className='name-wrapper'>
              <input
                className='input'
                type='text'
                name='firstname'
                value={userDetails.firstname}
                onChange={handleChange}
                placeholder='First Name'
                required
              />

              <input
                type='text'
                className='input'
                name='lastname'
                value={userDetails.lastname}
                onChange={handleChange}
                placeholder='Last Name'
                required
              />
            </div>
            <input
              type='text'
              className='input'
              name='username'
              value={userDetails.username}
              onChange={handleChange}
              placeholder='Username'
              required
            />

            <input
              type='email'
              className='input'
              name='email'
              value={userDetails.email}
              onChange={handleChange}
              placeholder='Email'
              required
            />

            <div className='aor-and-role-wrapper'>
              <select
                name='role'
                value={userDetails.role}
                onChange={handleChange}
                required>
                <option value=''>Select Role</option>
                <option value='Admin'>Admin</option>
                <option value='Agent'>Agent</option>
                <option value='Custo'>Customer Service</option>
              </select>
              {/* New Checkbox for isAOR */}
            </div>
            <div style={{ width: '100%' }} className='choice-button'>
              <button type='button' onClick={() => dispatch(toggleForm(false))}>
                <span>Cancel</span>
              </button>
              <button type='submit'>
                <span>Confirm</span>{' '}
              </button>
            </div>
          </div>
          <ToastContainer />
        </div>
      </form>
    </div>
  );
};

export default RegisterUsersForm;
