/** @format */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  DatePicker,
  Switch,
  Button,
  Upload,
  message,
  Row,
  Col,
  Select,
  Transfer,
  TimePicker,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { submitQAForm } from 'api/qaRoutes';
import axios from 'axios';
import moment from 'moment';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
const { TextArea } = Input;
const { Option } = Select;

const QAForm = () => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [officeId, setOfficeId] = useState('');
  const [offices, setOffices] = useState([]);
  const [agentId, setAgentId] = useState('');
  const [agents, setAgents] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedDeductions, setSelectedDeductions] = useState([]);
  const [sale, setSale] = useState(false);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

  const inputStyle = {
    padding: '24px',
    borderRadius: '16px',
    background: '#f7f9fb',
  };

  const deductions = [
    { key: '1', faultType: 'Misrepresent Benefits', percentage: 15 },
    { key: '2', faultType: 'Misrepresent Identity', percentage: 10 },
    { key: '3', faultType: 'Not following script', percentage: 7 },
    { key: '4', faultType: 'Qualifying Improperly', percentage: 5 },
    { key: '5', faultType: 'Not using rebuttals', percentage: 7 },
    {
      key: '6',
      faultType: 'Poor Tonality / Bad attitude / Low effort',
      percentage: 7,
    },
    { key: '7', faultType: 'Disqualifying / Not qualifying', percentage: 5 },
    { key: '8', faultType: 'Agent Didn’t Seek T.O', percentage: 15 },
    { key: '9', faultType: 'AOR mismatch', percentage: 15 },
  ];

  const [questions, setQuestions] = useState([
    {
      question:
        'Did the agent represent themselves to be someone other than who they are?',
      answer: '',
      notes: '',
      timeStamp: '',
    },
    {
      question: 'Was the caller looking for health insurance?',
      answer: '',
      notes: '',
      timeStamp: '',
    },
    {
      question: 'Did the prospect have pre-existing conditions?',
      answer: '',
      notes: '',
      timeStamp: '',
    },
    {
      question:
        'Did the agent make a good decision regarding the program they selected?',
      answer: '',
      notes: '',
      timeStamp: '',
    },
    {
      question: 'Did the agent sound like a professional?',
      answer: '',
      notes: '',
      timeStamp: '',
    },
    {
      question:
        'Did the agent represent the benefits of the program accurately?',
      answer: '',
      notes: '',
      timeStamp: '',
    },
    {
      question:
        'Did the agent maintain control of the call? or did he allow the prospect to ask questions and interrupt the flow of the sale?',
      answer: '',
      notes: '',
      timeStamp: '',
    },
    {
      question: 'Did the agent ask ALL pre-qualifying questions?',
      answer: '',
      notes: '',
      timeStamp: '',
    },
    {
      question:
        'What could the agent have done better when delivering the benefits of the plan to the prospect?',
      answer: '',
      notes: '',
      timeStamp: '',
    },

    {
      question:
        'Was there any non-compliance practices that should be addressed?',
      answer: '',
      notes: '',
      timeStamp: '',
    },
  ]);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/offices/office-list`);
        setOffices(response.data);
      } catch (error) {
        console.error('Error fetching offices:', error);
      }
    };
    fetchOffices();
  }, [apiUrl]);

  const fetchAgentsByOffice = async (officeId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/users/byOffice/${officeId}`
      );
      setAgents(response.data || []);
    } catch (error) {
      console.error('Failed to fetch agents:', error);
      message.error('Failed to load agents');
    }
  };

  const handleOfficeChange = (value) => {
    setOfficeId(value);
    fetchAgentsByOffice(value);
  };

  const handleTransferChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
    setSelectedDeductions(
      deductions.filter((item) => nextTargetKeys.includes(item.key))
    );
  };

  const convertMomentToStrings = (obj) => {
    for (let key in obj) {
      if (moment.isMoment(obj[key])) {
        obj[key] =
          key === 'dateOfCall'
            ? obj[key].toISOString()
            : obj[key].format('HH:mm:ss');
      }
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    values.officeId = officeId;
    values.agentId = agentId;
    values.sale = sale;
    values.pointDeduction = selectedDeductions;
    values.questions = questions.map((q) => ({
      ...q,
      timeStamp: moment.isMoment(q.timeStamp)
        ? q.timeStamp.format('HH:mm:ss')
        : q.timeStamp,
    }));
    convertMomentToStrings(values);

    if (fileList.length > 0) {
      values.recording = fileList[0].originFileObj;
    }

    try {
      await submitQAForm(values);
      message.success('QA Form submitted successfully');
    } catch (error) {
      console.error('Error submitting the form:', error);
      message.error('Failed to submit form');
    } finally {
      setLoading(false);
    }
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  return (
    <Form layout='vertical' onFinish={onFinish}>
      <Row justify='center'>
        <Col sm={24} md={24} lg={16} xl={16}>
          <Row gutter={[24, 24]}>
            <Col sm={24} md={24} lg={24} xl={24}>
              <div className='form-title'>
                <span>Sales Agents Quality Assurance Form</span>
              </div>
            </Col>

            <Col sm={24} md={12}>
              <div style={inputStyle}>
                <Form.Item
                  label='Lead ID'
                  name='LeadId'
                  rules={[{ required: true }]}>
                  <Input placeholder='Enter Lead ID' />
                </Form.Item>
              </div>
            </Col>
            <Col sm={24} md={12}>
              <div style={inputStyle}>
                <Form.Item
                  label='MemberId'
                  name='memberId'
                  rules={[{ required: true }]}>
                  <Input placeholder='Enter Lead ID' />
                </Form.Item>
              </div>
            </Col>
            <Col sm={24} md={12}>
              <div style={inputStyle}>
                <Form.Item
                  label='Office'
                  name='officeId'
                  rules={[{ required: true }]}>
                  <Select
                    placeholder='Select Office'
                    onChange={handleOfficeChange}>
                    {offices.map((office) => (
                      <Option key={office._id} value={office._id}>
                        {office.officeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col sm={24} md={12}>
              <div style={inputStyle}>
                <Form.Item
                  label='Agent'
                  name='agentId'
                  rules={[{ required: true }]}>
                  <Select
                    placeholder='Select Agent'
                    onChange={setAgentId}
                    value={agentId}>
                    {agents.map((agent) => (
                      <Option key={agent._id} value={agent._id}>
                        {capitalizeFirstLetter(agent.firstname)}{' '}
                        {capitalizeFirstLetter(agent.lastname)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col sm={24} md={12}>
              <div style={inputStyle}>
                <Form.Item
                  label='Date of Call'
                  name='dateOfCall'
                  rules={[{ required: true }]}>
                  <DatePicker
                    showTime
                    format='YYYY-MM-DD hh:mm:ss A'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </Col>

            <Col sm={24} md={12}>
              <div style={inputStyle}>
                <Form.Item label='Was this a sale?' name='sale'>
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    checked={sale}
                    onChange={setSale}
                  />
                </Form.Item>
              </div>
            </Col>

            <Col sm={24}>
              <div
                className='form-heading'
                style={{ margin: '20px 0', fontWeight: 'bold' }}>
                <h3>Questions</h3>
              </div>
            </Col>
            {questions.map((q, index) => (
              <React.Fragment key={index}>
                <Col sm={24} md={12}>
                  <div style={inputStyle}>
                    <Form.Item label={q.question}>
                      <Select
                        placeholder='Please Select'
                        rules={[{ required: true }]}
                        value={q.answer}
                        onChange={(value) =>
                          handleQuestionChange(index, 'answer', value)
                        }>
                        <Option value='yes'>Yes</Option>
                        <Option value='no'>No</Option>
                        <Option value='unsure'>Unsure</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col sm={24} md={12}>
                  <div style={inputStyle}>
                    <Form.Item label='Timestamp'>
                      <TimePicker
                        format='HH:mm:ss'
                        style={{ width: '100%' }}
                        value={
                          q.timeStamp ? moment(q.timeStamp, 'HH:mm:ss') : null
                        }
                        onChange={(time) =>
                          handleQuestionChange(
                            index,
                            'timeStamp',
                            time ? time.format('HH:mm:ss') : ''
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col sm={24}>
                  <div style={inputStyle}>
                    <Form.Item label='Notes'>
                      <TextArea
                        placeholder='Enter Notes'
                        value={q.notes}
                        onChange={(e) =>
                          handleQuestionChange(index, 'notes', e.target.value)
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
              </React.Fragment>
            ))}

            <Col sm={24}>
              <div style={inputStyle}>
                <Form.Item label='Upload Recording'>
                  <Upload
                    accept='.mp3,.wav'
                    maxCount={1}
                    fileList={fileList}
                    onChange={({ fileList }) => setFileList(fileList)}
                    beforeUpload={() => false}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              </div>
            </Col>

            <Col sm={24}>
              <div style={inputStyle}>
                <Form.Item
                  label='Point Deduction'
                  name='pointDeduction'
                  rules={[{ required: true }]}>
                  <Transfer
                    dataSource={deductions.map((item) => ({
                      key: item.key,
                      title: `${item.faultType} (${item.percentage}%)`,
                    }))}
                    targetKeys={targetKeys}
                    onChange={handleTransferChange}
                    render={(item) => item.title}
                    listStyle={{ width: 250, height: 300 }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col sm={24}>
              <Form.Item>
                <Button type='primary' htmlType='submit' loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default QAForm;
