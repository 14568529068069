/** @format */

import axios from 'axios';

// Assuming the API URL is stored in your environment variables
const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

/**
 * Fetches the office details (ID and name) by officeId from the office list API.
 * @param {string} officeId - The ID of the office to fetch.
 * @returns {Promise<Object>} - An object containing the office ID and name.
 */
export const getOfficeDetails = async () => {
  try {
    // Construct the API URL to fetch the office list
    const url = `${apiUrl}/api/offices/office-list`;

    // Fetch the office list from the API
    const response = await axios.get(url);

    // Assuming the response data contains an array of offices
    const offices = response.data; // Adjust according to your actual response structure

    // Return the entire list of offices
    return offices;
  } catch (error) {
    console.error('Error fetching office details:', error.message);
    throw error; // Re-throw the error so it can be handled by the caller
  }
};

export const getAllOffices = async () => {
  try {
    // Construct the API URL to fetch the office list
    const url = `${apiUrl}/api/offices/office-list`;

    // Fetch the office list from the API
    const response = await axios.get(url);

    // Assuming the response data contains an array of offices
    const offices = response.data; // Adjust according to your actual response structure

    // Return the entire list of offices
    return offices;
  } catch (error) {
    console.error('Error fetching office details:', error.message);
    throw error; // Re-throw the error so it can be handled by the caller
  }
};
