/** @format */

import CustoLayout from 'components/Custo/layout';
import QAForm from 'components/QAForm';

const QualityAssurance = () => {
  return (
    <CustoLayout>
      <QAForm />
    </CustoLayout>
  );
};

export default QualityAssurance;
