/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveLink } from 'state/slice/sidebar';
import { Col, Row } from 'antd';
import LogoMain from 'img/synergy-main.png';
import LogoCircle from 'img/Synergy-Logo-Circle.png';
import { NavLink } from 'react-router-dom';
import menuData from './menuData.json';
import ChartPieSlice from 'img/ChartPieSlice.png';
import ChartPieFilled from 'img/ChartPieFilled.png';
import ChartBar from 'img/ChartBar.png';
import ChartBarFilled from 'img/ChartBarFilled.png';
import Trophy from 'img/Trophy.png';
import TrophyFilled from 'img/TrophyFilled.png';
import Building from 'img/Buildings.png';
import Globe from 'img/globe.svg';
import GlobFilled from 'img/globeFilled.svg';
import Thermostate from 'img/thermostate.svg';
import ThermostateFilled from 'img/thermostateFilled.svg';
import BuildingFilled from 'img/BuildingsFilled.png';
import Textbox from 'img/Textbox.png';
import TexboxFilled from 'img/TextboxFilled.png';
import UsersThree from 'img/UsersThree.svg';
import UserThreeFilled from 'img/UsersThreeFilled.svg';
import Headset from 'img/Headset.svg';
import HeasetFilled from 'img/HeadsetFilled.svg';
import Logout from 'components/widgets/logoutButton';
import Setting from 'img/settings.png';
import UserSetting from 'components/userSetting';
import CustomerFilled from 'img/customerFilledsvg.svg';
import Customer from 'img/Customer.svg';
import { toggleSetting } from 'state/slice/buttonSlice';

const Menu = () => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.sidebar.menuCollapse);
  const isLinkActive = useSelector((state) => state.sidebar.activeLink);
  const { userAccess } = useSelector((state) => state.user);
  const { role } = useSelector((state) => state.user.userData);
  const hasDownlineAccess = userAccess?.downlineAccess?.length > 0;
  console.log(userAccess);
  // const [role, setRole] = useState(null);
  const handleSetting = () => {
    dispatch(toggleSetting(true));
  };

  const userFormActive = useSelector((state) => state.button.setting);
  const leftStyle = {
    width: isMenuOpen ? '212px' : '70px',
    transition: 'width 0.3s',
  };
  useEffect(() => {
    console.log('User role in production:', role);
  }, [role]);
  const imgStyle = {
    height: isMenuOpen ? '44px' : '40px',
    width: isMenuOpen ? '180px' : '40px',
    alignItems: !isMenuOpen ? 'center' : 'flex-start',
    transition: 'width 0.3s, height 0.3s',
  };

  const navlink = (role, endpoint) => {
    if (!role) {
      // Handle users without roles explicitly
      return `/${endpoint}`;
    }
    return `/${role.toLowerCase()}/${endpoint}`;
  };

  return (
    <div className='menu-container' style={leftStyle}>
      <div>
        <img
          src={isMenuOpen ? LogoMain : LogoCircle}
          alt='Synergy Logo'
          style={imgStyle}
        />
      </div>
      {/* Ensure the role is handled correctly */}
      {role !== 'Custo' && (
        <Row gutter={[4, 4]}>
          {isMenuOpen ? (
            <div style={{ padding: '8px' }}>
              <span className='title'>Analytics</span>
            </div>
          ) : (
            <div style={{ padding: '12px 4px' }}>
              <span className='menu-title'>Ana...</span>
            </div>
          )}
          <Col xs={24} lg={24}>
            <div className='menu-list'>
              <ul>
                <li>
                  <NavLink
                    onClick={() => dispatch(setActiveLink('dashboard'))}
                    to={navlink(role, 'dashboard')}
                    className={`nav-link ${
                      isLinkActive === 'dashboard' ? 'active-link' : ''
                    }`}
                    style={{
                      justifyContent: isMenuOpen ? 'normal' : 'center',
                    }}>
                    <img
                      src={
                        isLinkActive === 'dashboard'
                          ? ChartPieFilled
                          : ChartPieSlice
                      }
                      alt='Chart Pie Icon'
                    />
                    {isMenuOpen && <span>Dashboard</span>}
                  </NavLink>
                </li>
                {((hasDownlineAccess && role === 'Admin') || !role) && (
                  <li>
                    <NavLink
                      onClick={() =>
                        dispatch(setActiveLink('affiliates-performance'))
                      }
                      to={navlink(role, 'affiliates-performance')}
                      className={`nav-link ${
                        isLinkActive === 'affiliates-performance'
                          ? 'active-link'
                          : ''
                      }`}
                      style={{
                        justifyContent: isMenuOpen ? 'normal' : 'center',
                      }}>
                      <img
                        src={
                          isLinkActive === 'affiliates-performance'
                            ? ChartBarFilled
                            : ChartBar
                        }
                        alt='Trophy Icon'
                      />
                      {isMenuOpen && <span>Affiliate Performance</span>}
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    onClick={() => dispatch(setActiveLink('leaderboard'))}
                    to={navlink(role, 'leaderboard')}
                    className={`nav-link ${
                      isLinkActive === 'leaderboard' ? 'active-link' : ''
                    }`}
                    style={{
                      justifyContent: isMenuOpen ? 'normal' : 'center',
                    }}>
                    <img
                      src={
                        isLinkActive === 'leaderboard' ? TrophyFilled : Trophy
                      }
                      alt='Trophy Icon'
                    />
                    {isMenuOpen && <span>Agent Leaderboard</span>}
                  </NavLink>
                </li>
                {(role === 'Admin' || !role) && (
                  <li>
                    <NavLink
                      onClick={() => dispatch(setActiveLink('campaign'))}
                      to={navlink(role, 'campaign')}
                      className={`nav-link ${
                        isLinkActive === 'campaign' ? 'active-link' : ''
                      }`}
                      style={{
                        justifyContent: isMenuOpen ? 'normal' : 'center',
                      }}>
                      <img
                        src={
                          isLinkActive === 'campaign' ? HeasetFilled : Headset
                        }
                        alt='Headset Icon'
                      />
                      {isMenuOpen && <span>Campaign Overview</span>}
                    </NavLink>
                  </li>
                )}
                {(role === 'Admin' || !role) && (
                  <li>
                    <NavLink
                      onClick={() => dispatch(setActiveLink('vendors'))}
                      to={navlink(role, 'vendors')}
                      className={`nav-link ${
                        isLinkActive === 'vendors' ? 'active-link' : ''
                      }`}
                      style={{
                        justifyContent: isMenuOpen ? 'normal' : 'center',
                      }}>
                      <img
                        src={isLinkActive === 'vendors' ? GlobFilled : Globe}
                        style={{ width: '20px' }}
                        alt='Globe Icon'
                      />
                      {isMenuOpen && <span>Global Performance</span>}
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </Col>
        </Row>
      )}
      <Row gutter={[4, 4]}>
        {isMenuOpen ? (
          <div style={{ padding: '8px' }}>
            <span className='title'>Management</span>
          </div>
        ) : (
          <div style={{ padding: '12px 4px' }}>
            {' '}
            <span className='menu-title'>Mana...</span>
          </div>
        )}
        {/** */}{' '}
        <Col xs={24} lg={24}>
          <div className='menu-list'>
            <ul>
              {!role && (
                <li>
                  <NavLink
                    onClick={() => dispatch(setActiveLink('offices'))}
                    to={navlink(role, 'offices')} // Using the navlink function to generate the link
                    activeclassname='active-link' // Optional: Adds a class when the link is active
                    className={`nav-link ${
                      isLinkActive === 'offices' ? 'active-link' : ''
                    }`}
                    style={{
                      justifyContent: isMenuOpen ? 'normal' : 'center',
                    }}>
                    <img
                      src={
                        isLinkActive === 'offices' ? BuildingFilled : Building
                      }
                      alt='Building Icon'
                    />
                    {isMenuOpen && <span>Affiliate Offices</span>}
                  </NavLink>
                </li>
              )}

              <li>
                <NavLink
                  onClick={() => dispatch(setActiveLink('forms'))}
                  to={navlink(role, 'forms')}
                  className={`nav-link ${
                    isLinkActive === 'forms' ? 'active-link' : ''
                  }`}
                  style={{
                    justifyContent: isMenuOpen ? 'normal' : 'center',
                  }}>
                  <img
                    src={isLinkActive === 'forms' ? TexboxFilled : Textbox}
                    alt='Trophy Icon'
                  />
                  {isMenuOpen && <span>Forms</span>}
                </NavLink>
              </li>

              {(role === 'Admin' || !role) && (
                <li>
                  <NavLink
                    onClick={() => dispatch(setActiveLink('users'))}
                    to={navlink(role, 'users')} // Using the navlink function to generate the link
                    className={`nav-link ${
                      isLinkActive === 'users' ? 'active-link' : ''
                    }`}
                    style={{
                      justifyContent: isMenuOpen ? 'normal' : 'center',
                    }}>
                    <img
                      src={
                        isLinkActive === 'users' ? UserThreeFilled : UsersThree
                      }
                      alt='trophy   Icon'
                    />
                    {isMenuOpen && <span>Users</span>}
                  </NavLink>
                </li>
              )}
              {(role === 'Custo' || role === 'Admin') && (
                <li>
                  <NavLink
                    onClick={() => dispatch(setActiveLink('customers'))}
                    to={'/dir/customers'} // Using the navlink function to generate the link
                    className={`nav-link ${
                      isLinkActive === 'customers' ? 'active-link' : ''
                    }`}
                    style={{
                      justifyContent: isMenuOpen ? 'normal' : 'center',
                    }}>
                    <img
                      src={
                        isLinkActive === 'customers' ? CustomerFilled : Customer
                      }
                      style={{ width: '20px' }}
                      alt='trophy   Icon'
                    />
                    {isMenuOpen && <span>Customer Directory </span>}
                  </NavLink>
                </li>
              )}

              {((role === 'Custo' && userAccess.QAFormAccess !== null) ||
                role === 'Admin') && (
                <li>
                  <NavLink
                    onClick={() => dispatch(setActiveLink('Quality'))}
                    to={navlink(role, 'quality-assurance')}
                    className={`nav-link ${
                      isLinkActive === 'Quality' ? 'active-link' : ''
                    }`}
                    style={{
                      justifyContent: isMenuOpen ? 'normal' : 'center',
                    }}>
                    <img
                      src={
                        isLinkActive === 'Quality'
                          ? ThermostateFilled
                          : Thermostate
                      }
                      style={{ width: '20px' }}
                      alt='trophy   Icon'
                    />
                    {isMenuOpen && <span> Quality Assurance </span>}
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </Col>
      </Row>
      <ul></ul>

      <Row gutter={[4, 4]}>
        {isMenuOpen ? (
          <div style={{ padding: '8px' }}>
            <span className='title'>Account</span>
          </div>
        ) : (
          <div style={{ padding: '12px 4px' }}>
            {' '}
            <span className='menu-title'>acc...</span>
          </div>
        )}
        {/** */}{' '}
        <Col xs={24} lg={24}>
          <div className='menu-list'>
            <ul>
              {role && (
                <li
                  className='nav-link'
                  style={{
                    alignItems: isMenuOpen ? '' : 'center',
                    width: isMenuOpen ? '' : '44px',
                    cursor: 'pointer',
                  }}
                  onClick={handleSetting}>
                  <img
                    style={{ width: '20px', height: '20px' }}
                    src={Setting}
                    alt='setting Icon'
                  />
                  {isMenuOpen && <span>Settings</span>}
                </li>
              )}{' '}
              <Logout />
            </ul>
          </div>
        </Col>
      </Row>
      {userFormActive && <UserSetting />}
    </div>
  );
};

export default Menu;
