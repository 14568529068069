/** @format */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Table,
  Input,
  InputNumber,
  Button,
  Modal,
  Select,
  Space,
  DatePicker,
} from 'antd'; // Import InputNumber
import moment from 'moment';
import io from 'socket.io-client';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

const CustomModal = ({ visible, onCancel, onVendorSelect, vendors }) => (
  <Modal
    title='Select Vendor'
    visible={visible}
    onCancel={onCancel}
    footer={null}>
    <ul className='vendor-Modal'>
      {vendors.map((vendor) => (
        <li key={vendor._id} onClick={() => onVendorSelect(vendor._id)}>
          {vendor.vendorName}
        </li>
      ))}
    </ul>
  </Modal>
);

const AdminFormTable = ({ setFormDatas, endpoint }) => {
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);
  const [formDatas, setFormsDatasLocal] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditingStatus, setIsEditingStatus] = useState(null);
  const [editingForm, setEditingForm] = useState(null);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

  useEffect(() => {
    const socket = io(apiUrl);
    socket.on('newForm', (newForm) => {
      setFormsDatasLocal((prevForms) => [newForm, ...prevForms]);
      setFormDatas((prevForms) => [newForm, ...prevForms]);
    });
    return () => {
      socket.disconnect();
    };
  }, [apiUrl, setFormDatas]);

  useEffect(() => {
    const getFormDatas = async () => {
      const token = localStorage.getItem('accessToken');
      try {
        const response = await axios.get(`${apiUrl}/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            startTime: startDate,
            endTime: endDate,
          },
        });
        setFormsDatasLocal(response.data);
        setFormDatas(response.data);
      } catch (error) {
        setError('Failed to load data');
        console.error('Error fetching data:', error);
      }
    };

    const getVendors = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/U65Vendors/admin/vendors-list`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
        setVendors(response.data);
      } catch (error) {
        setError('Failed to load vendors');
        console.error('Error fetching vendors:', error);
      }
    };

    getFormDatas();
    getVendors();
  }, [startDate, endDate, apiUrl, setFormDatas, endpoint]);

  const handleEdit = (index, form) => {
    setEditIndex(index);
    setEditedData({ ...form });
  };

  const handleSave = async (index) => {
    const token = localStorage.getItem('accessToken');
    console.log('Saving data:', editedData);
    try {
      const response = await axios.put(
        `${apiUrl}/api/policy-form/admin/update-form/${editedData._id}`,
        editedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Save response:', response.data);
      const updatedForms = [...formDatas];
      updatedForms[index] = editedData;
      setFormsDatasLocal(updatedForms);
      setFormDatas(updatedForms);
      setEditIndex(null);
      setEditedData({});
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error saving data:', error);
      setError('Failed to save data');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData({
      ...editedData,
      [name]: value,
    });
  };

  const handleVendorChange = (vendorId) => {
    const selectedVendor = vendors.find((vendor) => vendor._id === vendorId);
    console.log('Selected vendor:', selectedVendor);
    setEditedData((prevData) => ({
      ...prevData,
      vendorName: selectedVendor.vendorName,
    }));
    setIsModalVisible(false);
  };

  const dateFormat = (date) => {
    if (!date) return '';
    const dateObj = new Date(date);
    if (isNaN(dateObj)) return '';
    const localDate = new Date(
      dateObj.getTime() + dateObj.getTimezoneOffset() * 60000
    );
    return !isNaN(localDate) ? localDate.toDateString() : 'Invalid date';
  };

  const capitalizeFullName = (name) => {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  if (error) return <div>Error: {error}</div>;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchQuery(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchQuery('');
  };

  const handleStatusChange = (id, status, type, parentFormId) => {
    const updatedForms = formDatas.map((form) => {
      if (form._id === parentFormId) {
        if (type === 'Ancillary') {
          form.ancillaries = form.ancillaries.map((item) =>
            item._id === id ? { ...item, status } : item
          );
        } else if (type === 'Major Med') {
          form.majorMed.status = status;
        } else if (type === 'Private Med') {
          form.privateMed.status = status;
        }
      }
      return form;
    });
    setFormsDatasLocal(updatedForms);
    setFormDatas(updatedForms);
  };

  const handleSaveStatus = async (parentFormId) => {
    const token = localStorage.getItem('accessToken');
    const form = formDatas.find((form) => form._id === parentFormId);
    if (!form) return;

    try {
      await axios.put(
        `${apiUrl}/api/policy-form/admin/update-form/${form._id}`,
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsEditingStatus(null);
      setEditingForm(null);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleDateChange = (date, dateString, nestedRecordId, parentFormId) => {
    const updatedForms = formDatas.map((form) => {
      if (form._id === parentFormId) {
        if (form.ancillaries) {
          form.ancillaries = form.ancillaries.map((item) =>
            item._id === nestedRecordId
              ? { ...item, billingDate: dateString }
              : item
          );
        }
        if (form.majorMed && form.majorMed._id === nestedRecordId) {
          form.majorMed.billingDate = dateString;
        }
        if (form.privateMed && form.privateMed._id === nestedRecordId) {
          form.privateMed.billingDate = dateString;
        }
      }
      return form;
    });
    setFormsDatasLocal(updatedForms);
    setFormDatas(updatedForms);
    setEditedData({ ...editedData, billingDate: dateString });
  };

  const handlePremiumChange = (value, nestedRecordId, parentFormId) => {
    const updatedForms = formDatas.map((form) => {
      if (form._id === parentFormId) {
        if (form.ancillaries) {
          form.ancillaries = form.ancillaries.map((item) =>
            item._id === nestedRecordId ? { ...item, premium: value } : item
          );
        }
        if (form.majorMed && form.majorMed._id === nestedRecordId) {
          form.majorMed.premium = value;
        }
        if (form.privateMed && form.privateMed._id === nestedRecordId) {
          form.privateMed.premium = value;
        }
      }
      return form;
    });
    setFormsDatasLocal(updatedForms);
    setFormDatas(updatedForms);
    setEditedData({ ...editedData, premium: value });
  };

  const statusColors = {
    Paid: 'green',
    Cancelled: 'red',
    Declined: 'orange',
    Pending: 'blue',
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...getColumnSearchProps('firstName'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.firstName}
            name='firstName'
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...getColumnSearchProps('lastName'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.lastName}
            name='lastName'
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      ...getColumnSearchProps('phoneNumber'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.phoneNumber}
            name='phoneNumber'
            type='text' // Changed to "text" to avoid selection issues
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      key: 'vendorName',
      ...getColumnSearchProps('vendorName'),
      render: (text, record, index) =>
        editIndex === index ? (
          <>
            <Input
              value={editedData.vendorName}
              name='vendorName'
              readOnly
              onClick={() => setIsModalVisible(true)}
            />
            <CustomModal
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              onVendorSelect={handleVendorChange}
              vendors={vendors}
            />
          </>
        ) : (
          text
        ),
    },
    {
      title: 'FFMID',
      dataIndex: 'FFMID',
      key: 'FFMID',
      ...getColumnSearchProps('FFMID'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.FFMId}
            name='FFMID'
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Member ID',
      dataIndex: 'memberId',
      key: 'memberId',
      ...getColumnSearchProps('memberId'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.memberId}
            name='memberId'
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Health Sherpa',
      dataIndex: 'healthSherpa',
      key: 'healthSherpa',
      ...getColumnSearchProps('healthSherpa'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.healthSherpa}
            name='healthSherpa'
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentName',
      key: 'agentName',
      ...getColumnSearchProps('agentName'),
      render: (text) => capitalizeFullName(text),
    },
    {
      title: 'Date',
      dataIndex: 'todaysDate',
      key: 'todaysDate',
      render: (text) => dateFormat(text),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record, index) =>
        editIndex === index ? (
          <Button onClick={() => handleSave(index)}>Save</Button>
        ) : (
          <Button onClick={() => handleEdit(index, record)}>Edit</Button>
        ),
    },
  ];

  const expandedRowRender = (record) => {
    const nestedColumns = [
      { title: 'Type', dataIndex: 'type', key: 'type' },
      { title: 'Carrier', dataIndex: 'carrier', key: 'carrier' },
      {
        title: 'Billing Date',
        dataIndex: 'billingDate',
        key: 'billingDate',
        render: (text, nestedRecord) =>
          isEditingStatus === nestedRecord._id ? (
            <DatePicker
              value={
                editedData.billingDate ? moment(editedData.billingDate) : null
              }
              onChange={(date, dateString) =>
                handleDateChange(date, dateString, nestedRecord._id, record._id)
              }
            />
          ) : (
            dateFormat(text)
          ),
      },
      {
        title: 'Premium',
        dataIndex: 'premium',
        key: 'premium',
        render: (text, nestedRecord) =>
          isEditingStatus === nestedRecord._id ? (
            <InputNumber // Changed to InputNumber
              value={nestedRecord.premium}
              onChange={(value) =>
                handlePremiumChange(value, nestedRecord._id, record._id)
              }
            />
          ) : (
            text
          ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, nestedRecord) =>
          isEditingStatus === nestedRecord._id ? (
            <Select
              value={text}
              style={{ color: statusColors[text] }}
              onChange={(value) =>
                handleStatusChange(
                  nestedRecord._id,
                  value,
                  nestedRecord.type,
                  record._id
                )
              }>
              <Option value='Paid' style={{ color: statusColors['Paid'] }}>
                Paid
              </Option>
              <Option
                value='Cancelled'
                style={{ color: statusColors['Cancelled'] }}>
                Cancelled
              </Option>
              <Option
                value='Declined'
                style={{ color: statusColors['Declined'] }}>
                Declined
              </Option>
              <Option
                value='Pending'
                style={{ color: statusColors['Pending'] }}>
                Pending
              </Option>
            </Select>
          ) : (
            <span style={{ color: statusColors[text] }}>{text}</span>
          ),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, nestedRecord) =>
          isEditingStatus === nestedRecord._id ? (
            <Button onClick={() => handleSaveStatus(record._id)}>Save</Button>
          ) : (
            <Button
              onClick={() => {
                setIsEditingStatus(nestedRecord._id);
                setEditingForm(record._id);
              }}>
              Edit
            </Button>
          ),
      },
    ];

    return (
      <Table
        columns={nestedColumns}
        dataSource={[
          ...(record.ancillaries.map((item) => ({
            ...item,
            type: 'Ancillary',
          })) || []),
          ...(record.majorMed
            ? [{ ...record.majorMed, type: 'Major Med' }]
            : []),
          ...(record.privateMed
            ? [{ ...record.privateMed, type: 'Private Med' }]
            : []),
        ]}
        pagination={false}
        rowKey={(record) => record._id}
      />
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        dataSource={formDatas}
        rowKey={(record) => record._id}
        expandable={{ expandedRowRender }}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default AdminFormTable;
