/** @format */

import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const getDefaultDateRange = () => {
  // Calculate local start and end of day, then adjust to UTC
  const startDate = dayjs()
    .startOf('day')
    .add(dayjs().utcOffset(), 'minute')
    .toISOString();
  const endDate = dayjs()
    .endOf('day')
    .add(dayjs().utcOffset(), 'minute')
    .toISOString();
  return { startDate, endDate };
};

const { startDate, endDate } = getDefaultDateRange();

const dropDownSlice = createSlice({
  name: 'dropDown',
  initialState: {
    officeType: null,
    officeId: null,
    campaignId: null,
    startDate: startDate,
    reportId: null,
    endDate: endDate,
    startRange: startDate, // Initial startRange and startDate are the same
    endRange: endDate, // Initial endRange and endDate are the same
    userId: null,
  },
  reducers: {
    setOfficeType: (state, action) => {
      state.officeType = action.payload.officeType;
    },
    setOfficeId: (state, action) => {
      state.officeId = action.payload.officeId;
    },
    setCampaignId: (state, action) => {
      state.campaignId = action.payload.campaignId;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload.startDate;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload.endDate;
    },
    setStartRange: (state, action) => {
      state.startRange = action.payload.startRange;
    },
    setEndRange: (state, action) => {
      state.endRange = action.payload.endRange;
    },
    setUserId: (state, action) => {
      state.userId = action.payload.userId;
    },
    setReportId: (state, action) => {
      state.reportId = action.payload;
      console.log(state.reportId);
    },
    clearUserId: (state) => {
      state.userId = null;
    },
  },
});

export const {
  setOfficeType,
  setOfficeId,
  setCampaignId,
  setStartDate,
  setEndDate,
  setStartRange,
  setEndRange,
  setUserId,
  clearUserId,
  setReportId,
} = dropDownSlice.actions;

export default dropDownSlice.reducer;
