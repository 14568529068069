/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveLink } from 'state/slice/sidebar';
import { Col, Row } from 'antd';
import LogoMain from 'img/synergy-main.png';
import LogoCircle from 'img/Synergy-Logo-Circle.png';
import { NavLink } from 'react-router-dom';

import ChartPieSlice from 'img/ChartPieSlice.png';
import ChartPieFilled from 'img/ChartPieFilled.png';
import ChartBar from 'img/ChartBar.png';
import ChartBarFilled from 'img/ChartBarFilled.png';
import Trophy from 'img/Trophy.png';
import TrophyFilled from 'img/TrophyFilled.png';
import Building from 'img/Buildings.png';
import Globe from 'img/globe.svg';
import GlobFilled from 'img/globeFilled.svg';

import BuildingFilled from 'img/BuildingsFilled.png';
import Textbox from 'img/Textbox.png';
import TexboxFilled from 'img/TextboxFilled.png';
import UsersThree from 'img/UsersThree.svg';
import UserThreeFilled from 'img/UsersThreeFilled.svg';
import Headset from 'img/Headset.svg';
import HeasetFilled from 'img/HeadsetFilled.svg';
import Logout from 'components/widgets/logoutButton';
import CustomerFilled from 'img/customerFilledsvg.svg';
import Customer from 'img/Customer.svg';
import Setting from 'img/settings.png';
import UserSetting from 'components/userSetting';
import { toggleSetting } from 'state/slice/buttonSlice';

const Menu = () => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.sidebar.menuCollapse);
  const isLinkActive = useSelector((state) => state.sidebar.activeLink);
  const handleSetting = () => {
    dispatch(toggleSetting(true));
  };

  const userFormActive = useSelector((state) => state.button.setting);
  const leftStyle = {
    width: isMenuOpen ? '212px' : '70px',
    transition: 'width 0.3s',
  };

  const imgStyle = {
    height: isMenuOpen ? '44px' : '40px',
    width: isMenuOpen ? '180px' : '40px',
    alignItems: !isMenuOpen ? 'center' : 'flex-start',
    transition: 'width 0.3s, height 0.3s',
  };

  return (
    <div className='menu-container' style={leftStyle}>
      <div>
        <img
          src={isMenuOpen ? LogoMain : LogoCircle}
          alt='Synergy Logo'
          style={imgStyle}
        />
      </div>
      {/* Ensure the role is handled correctly */}

      <Row gutter={[4, 4]}>
        {isMenuOpen ? (
          <div style={{ padding: '8px' }}>
            <span className='title'>Analytics</span>
          </div>
        ) : (
          <div style={{ padding: '12px 4px' }}>
            <span className='menu-title'>Ana...</span>
          </div>
        )}
        <Col xs={24} lg={24}>
          <div className='menu-list'>
            <ul>
              <li>
                <NavLink
                  onClick={() => dispatch(setActiveLink('dashboard'))}
                  to={'/dashboard'}
                  className={`nav-link ${
                    isLinkActive === 'dashboard' ? 'active-link' : ''
                  }`}
                  style={{
                    justifyContent: isMenuOpen ? 'normal' : 'center',
                  }}>
                  <img
                    src={
                      isLinkActive === 'dashboard'
                        ? ChartPieFilled
                        : ChartPieSlice
                    }
                    alt='Chart Pie Icon'
                  />
                  {isMenuOpen && <span>Dashboard</span>}
                </NavLink>
              </li>

              <li>
                <NavLink
                  onClick={() =>
                    dispatch(setActiveLink('affiliates-performance'))
                  }
                  to={'/affiliates-performance'}
                  className={`nav-link ${
                    isLinkActive === 'affiliates-performance'
                      ? 'active-link'
                      : ''
                  }`}
                  style={{
                    justifyContent: isMenuOpen ? 'normal' : 'center',
                  }}>
                  <img
                    src={
                      isLinkActive === 'affiliates-performance'
                        ? ChartBarFilled
                        : ChartBar
                    }
                    alt='Trophy Icon'
                  />
                  {isMenuOpen && <span>Affiliate Performance</span>}
                </NavLink>
              </li>

              <li>
                <NavLink
                  onClick={() => dispatch(setActiveLink('leaderboard'))}
                  to={'/leaderboard'}
                  className={`nav-link ${
                    isLinkActive === 'leaderboard' ? 'active-link' : ''
                  }`}
                  style={{
                    justifyContent: isMenuOpen ? 'normal' : 'center',
                  }}>
                  <img
                    src={isLinkActive === 'leaderboard' ? TrophyFilled : Trophy}
                    alt='Trophy Icon'
                  />
                  {isMenuOpen && <span>Agent Leaderboard</span>}
                </NavLink>
              </li>

              <li>
                <NavLink
                  onClick={() => dispatch(setActiveLink('campaign'))}
                  to={'/campaign'}
                  className={`nav-link ${
                    isLinkActive === 'campaign' ? 'active-link' : ''
                  }`}
                  style={{
                    justifyContent: isMenuOpen ? 'normal' : 'center',
                  }}>
                  <img
                    src={isLinkActive === 'campaign' ? HeasetFilled : Headset}
                    alt='Headset Icon'
                  />
                  {isMenuOpen && <span>Campaign Overview</span>}
                </NavLink>
              </li>

              <li>
                <NavLink
                  onClick={() => dispatch(setActiveLink('vendors'))}
                  to={'/vendors'}
                  className={`nav-link ${
                    isLinkActive === 'vendors' ? 'active-link' : ''
                  }`}
                  style={{
                    justifyContent: isMenuOpen ? 'normal' : 'center',
                  }}>
                  <img
                    src={isLinkActive === 'vendors' ? GlobFilled : Globe}
                    style={{ width: '20px' }}
                    alt='Globe Icon'
                  />
                  {isMenuOpen && <span>Global Performance</span>}
                </NavLink>
              </li>
            </ul>
          </div>
        </Col>
      </Row>

      <Row gutter={[4, 4]}>
        {isMenuOpen ? (
          <div style={{ padding: '8px' }}>
            <span className='title'>Management</span>
          </div>
        ) : (
          <div style={{ padding: '12px 4px' }}>
            {' '}
            <span className='menu-title'>Mana...</span>
          </div>
        )}
        {/** */}{' '}
        <Col xs={24} lg={24}>
          <div className='menu-list'>
            <ul>
              <li>
                <NavLink
                  onClick={() => dispatch(setActiveLink('offices'))}
                  to={'/offices'}
                  activeclassname='active-link' // Optional: Adds a class when the link is active
                  className={`nav-link ${
                    isLinkActive === 'offices' ? 'active-link' : ''
                  }`}
                  style={{
                    justifyContent: isMenuOpen ? 'normal' : 'center',
                  }}>
                  <img
                    src={isLinkActive === 'offices' ? BuildingFilled : Building}
                    alt='Building Icon'
                  />
                  {isMenuOpen && <span>Affiliate Offices</span>}
                </NavLink>
              </li>

              <li>
                <NavLink
                  onClick={() => dispatch(setActiveLink('forms'))}
                  to={'/forms'}
                  className={`nav-link ${
                    isLinkActive === 'forms' ? 'active-link' : ''
                  }`}
                  style={{
                    justifyContent: isMenuOpen ? 'normal' : 'center',
                  }}>
                  <img
                    src={isLinkActive === 'forms' ? TexboxFilled : Textbox}
                    alt='Trophy Icon'
                  />
                  {isMenuOpen && <span>Forms</span>}
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => dispatch(setActiveLink('customers'))}
                  to={'/customers'} // Using the navlink function to generate the link
                  className={`nav-link ${
                    isLinkActive === 'customers' ? 'active-link' : ''
                  }`}
                  style={{
                    justifyContent: isMenuOpen ? 'normal' : 'center',
                  }}>
                  <img
                    src={
                      isLinkActive === 'customers' ? CustomerFilled : Customer
                    }
                    style={{ width: '20px' }}
                    alt='trophy   Icon'
                  />
                  {isMenuOpen && <span>Customer Directory </span>}
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => dispatch(setActiveLink('users'))}
                  to={'/users'} // Using the navlink function to generate the link
                  className={`nav-link ${
                    isLinkActive === 'users' ? 'active-link' : ''
                  }`}
                  style={{
                    justifyContent: isMenuOpen ? 'normal' : 'center',
                  }}>
                  <img
                    src={
                      isLinkActive === 'users' ? UserThreeFilled : UsersThree
                    }
                    alt='trophy   Icon'
                  />
                  {isMenuOpen && <span>Users</span>}
                </NavLink>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <ul></ul>

      <Row gutter={[4, 4]}>
        {isMenuOpen ? (
          <div style={{ padding: '8px' }}>
            <span className='title'>Account</span>
          </div>
        ) : (
          <div style={{ padding: '12px 4px' }}>
            {' '}
            <span className='menu-title'>acc...</span>
          </div>
        )}
        {/** */}{' '}
        <Col xs={24} lg={24}>
          <div className='menu-list'>
            <ul>
              <Logout />
            </ul>
          </div>
        </Col>
      </Row>
      {userFormActive && <UserSetting />}
    </div>
  );
};

export default Menu;
