/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Select, Checkbox, message } from 'antd';
import {
  addUserAccess,
  editUserAccess,
  getUserAccess,
} from 'api/downlineAccessRoutes'; // Update the import paths accordingly
import { getOfficeDetails } from 'api/officeRoutes'; // Import the function to fetch office details

const { Option } = Select;

const UserAccessComponent = () => {
  const userId = useSelector((state) => state.dropDown.userId); // Get userId from Redux
  const [editingField, setEditingField] = useState(null);
  const [officeIds, setOfficeIds] = useState([]);
  const [downlineOffices, setDownlineOffices] = useState([]);
  const [ssnAccess, setSsnAccess] = useState(false);
  const [QAFormAccess, setQAFormAccess] = useState(false);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewAccess, setIsNewAccess] = useState(false); // New state to determine if adding new access

  useEffect(() => {
    const fetchUserAccess = async () => {
      try {
        const result = await getUserAccess(userId);
        if (result.message === 'User access retrieved successfully') {
          setOfficeIds(
            result.access.downlineAccess.map((office) => office._id)
          );
          setDownlineOffices(result.access.downlineAccess);
          setSsnAccess(result.access.ssnAccess);
          setQAFormAccess(result.access.QAFormAccess);
        } else {
          // If no access exists, assume we're adding new access
          setIsNewAccess(true);
        }
      } catch (err) {
        console.error('Error fetching user access:', err);
        setError('Failed to fetch user access');
        setIsNewAccess(true);
      }
    };

    if (userId) {
      fetchUserAccess();
    }
  }, [userId]);

  useEffect(() => {
    const fetchOfficeList = async () => {
      try {
        const officeResponse = await getOfficeDetails(); // Fetch all office options
        setOfficeOptions(officeResponse);
      } catch (error) {
        console.error('Error fetching office list:', error);
        setError('Error fetching office list');
      }
    };

    if (editingField === 'downlineOffices') {
      fetchOfficeList();
    }
  }, [editingField]);

  const handleSaveAccess = async (field) => {
    try {
      setLoading(true);
      let updatedAccess = {};

      if (field === 'downlineOffices') {
        updatedAccess = { downlineAccess: officeIds };
      } else if (field === 'ssnAndQAFormAccess') {
        updatedAccess = { ssnAccess, QAFormAccess };
      }

      let result;
      if (isNewAccess) {
        result = await addUserAccess({
          userId,
          ssnAccess,
          downlineAccess: officeIds,
          QAFormAccess,
        });
      } else {
        result = await editUserAccess(userId, updatedAccess);
      }

      if (
        result.message === 'Access successfully added' ||
        result.message === 'Access successfully updated'
      ) {
        message.success(result.message);
        setIsNewAccess(false); // Once added, it's no longer new access
        setEditingField(null);
      }
    } catch (err) {
      console.error(`Error updating ${field}:`, err);
      setError(`Failed to update ${field}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {' '}
      <Row justify='center' gutter={[28, 28]}>
        {/* Row for Downline Offices */}{' '}
        <div className='user-details-wrapper'>
          <Col xs={24} sm={24} lg={24}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
              }}>
              <h3 className='prof-heading'>Downline Offices</h3>
              {editingField !== 'downlineOffices' && (
                <Button
                  type='primary'
                  onClick={() => setEditingField('downlineOffices')}>
                  Edit
                </Button>
              )}
            </div>
          </Col>

          <Col xs={24} sm={24} lg={24}>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              placeholder='Select Office IDs'
              value={officeIds}
              onChange={(value) => setOfficeIds(value)}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              disabled={editingField !== 'downlineOffices'}>
              {editingField === 'downlineOffices'
                ? officeOptions.map((office) => (
                    <Option key={office._id} value={office._id}>
                      {office.officeName}
                    </Option>
                  ))
                : downlineOffices.map((office) => (
                    <Option key={office._id} value={office._id}>
                      {office.officeName}
                    </Option>
                  ))}
            </Select>
          </Col>

          {editingField === 'downlineOffices' && (
            <Col xs={24} sm={24} lg={24} style={{ marginTop: '16px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '8px',
                }}>
                <Button
                  type='primary'
                  onClick={() => handleSaveAccess('downlineOffices')}
                  loading={loading}>
                  Save
                </Button>
                <Button type='default' onClick={() => setEditingField(null)}>
                  Cancel
                </Button>
              </div>
            </Col>
          )}
        </div>{' '}
        <div className='user-details-wrapper'>
          <Col xs={24} sm={24} lg={24}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
              }}>
              <h3 className='prof-heading'>Access Permissions</h3>
              {editingField !== 'ssnAndQAFormAccess' && (
                <Button
                  type='primary'
                  onClick={() => setEditingField('ssnAndQAFormAccess')}>
                  Edit
                </Button>
              )}
            </div>
          </Col>

          <Col xs={24} sm={12} lg={12}>
            <Checkbox
              checked={ssnAccess}
              onChange={(e) => setSsnAccess(e.target.checked)}
              disabled={editingField !== 'ssnAndQAFormAccess'}>
              Access to SSN
            </Checkbox>
          </Col>

          <Col xs={24} sm={12} lg={12} style={{ marginBottom: '16px' }}>
            <Checkbox
              checked={QAFormAccess}
              onChange={(e) => setQAFormAccess(e.target.checked)}
              disabled={editingField !== 'ssnAndQAFormAccess'}>
              Access to QA Form
            </Checkbox>
          </Col>

          {editingField === 'ssnAndQAFormAccess' && (
            <Col xs={24} sm={24} lg={24} style={{ marginTop: '16px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '8px',
                }}>
                <Button
                  type='primary'
                  onClick={() => handleSaveAccess('ssnAndQAFormAccess')}
                  loading={loading}>
                  Save
                </Button>
                <Button type='default' onClick={() => setEditingField(null)}>
                  Cancel
                </Button>
              </div>
            </Col>
          )}

          {error && (
            <Col xs={24} sm={24} lg={24}>
              <p style={{ color: 'red' }}>{error}</p>
            </Col>
          )}
        </div>
      </Row>
      {/* Combined Row for SSN Access and QA Form Access */}
      <Row justify='center' gutter={[16, 16]}>
        {' '}
      </Row>
    </div>
  );
};

export default UserAccessComponent;
