/** @format */

import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Exit from 'img/exit.svg';
import { useDispatch } from 'react-redux';
import { setForm } from 'state/slice/tabSlice';
import ArrowRight from 'img/ArrowLineRight.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadIcon from 'img/UploadSimple.svg';

const FileDrop = ({ onFileAccepted, file }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      onFileAccepted(acceptedFiles[0]); // Handle only the first file
    },
  });

  return (
    <div
      {...getRootProps({
        style: {
          border: '1px dashed rgba(28, 28, 28, 0.20)',
          height: '100px',
          borderRadius: '12px',
          padding: '20px',
          textAlign: 'center',
          paddingTop: '34px',
        },
      })}>
      <input {...getInputProps()} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {file ? (
          <div style={{ flex: '1 0 0px' }} className='help-msg-wrapper'>
            <p>Selected file: {file.name}</p>{' '}
            {/* Display the selected file's name*/}
          </div>
        ) : (
          <div style={{ flex: '1 0 0px' }} className='help-msg-wrapper'>
            {' '}
            <span>Drag And Drop</span>{' '}
          </div>
        )}
        <div style={{ float: 'right' }}>
          <img src={uploadIcon} alt='upload-icon' />
        </div>
      </div>
    </div>
  );
};

const FileUpload = ({ onFileAccepted, file }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      onFileAccepted(acceptedFiles[0]); // Handle only the first file
    },
  });

  return (
    <div
      {...getRootProps({
        style: {
          border: '1px solid  rgba(28, 28, 28, 0.10)',
          borderRadius: '12px',
          textAlign: 'center',
          padding: '20px',
        },
      })}>
      <input {...getInputProps()} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {file ? (
          <p>Selected file: {file.name}</p> // Display the selected file's name
        ) : (
          <div style={{ flex: '1 0 0px' }} className='help-msg-wrapper'>
            {' '}
            <span>Drag And Drop</span>{' '}
          </div>
        )}
        <div style={{ float: 'right', cursor: 'pointer' }}>
          <img src={uploadIcon} alt='upload-icon' />
        </div>
      </div>
    </div>
  );
};

const MultiPartForm = () => {
  const [step, setStep] = useState(1);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App

  const [formData, setFormData] = useState({
    officeName: '',
    division: [],
    address: '',
    phone: '',
    file: null, // Ensure this is used for file handling
  });
  const label = {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
  };
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;

    if (type === 'checkbox') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        division: checked
          ? [...prevFormData.division, value]
          : prevFormData.division.filter((div) => div !== value),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('officeName', formData.officeName);
    data.append('address', formData.address);
    data.append('phone', formData.phone);
    formData.division.forEach((div) => data.append('division', div));
    if (formData.file) {
      data.append('logo', formData.file);
    }

    try {
      const response = await axios.post(
        `${apiUrl}/api/offices/register`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Success:', response.data);
      toast.success('Office created successfully!');
      setStep(3); // Navigate away after successful submission
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error creating office.');
    }
  };

  const handleExit = () => {
    dispatch(setForm(''));
    setStep(1);
    setFormData({
      officename: '',
      division: [],
      address: '',
      phone: '',
      file: null, //
    });
  };
  return (
    <div className='form-layout '>
      <div className='exit-form-wrapper'>
        <button
          className='exit-form'
          style={{ float: 'right', marginBottom: '30px' }}
          onClick={handleExit}>
          <img src={Exit} alt='Exit Button' style={{ cursor: 'pointer' }} />
        </button>
      </div>
      <form className='form-container' onSubmit={handleSubmit}>
        {step === 1 && (
          <div className='form-wrapper'>
            <div className='form-header'>
              <h2
                style={{
                  marginTop: '25px',
                  textAlign: 'center',
                  marginBottom: '8px',
                }}>
                Register New {/*props.officeType*/} Office
              </h2>

              <div className='help-msg-wrapper'>
                <span>If you need any help, please access Help Page.</span>
              </div>
            </div>
            <div className='input-wrapper'>
              <label>
                <span style={label}>Office Name</span>
                <input
                  className='input'
                  type='text'
                  name='officeName'
                  value={formData.officeName}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                <span style={label}>Division</span>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <input
                    type='checkbox'
                    id='aca'
                    name='division'
                    value='ACA'
                    checked={formData.division.includes('ACA')}
                    onChange={handleInputChange}
                  />
                  <label htmlFor='aca'>ACA</label>
                  <input
                    type='checkbox'
                    id='u65'
                    name='division'
                    value='U65'
                    checked={formData.division.includes('U65')}
                    onChange={handleInputChange}
                  />
                  <label htmlFor='u65'>U65</label>
                </div>
              </label>

              <label>
                <span style={label}>Address</span>
                <input
                  className='input'
                  type='text'
                  name='address'
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                <span style={label}>Phone</span>
                <input
                  className='input'
                  type='text'
                  name='phone'
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </label>
              <div className='choice-button'>
                <button type='button' onClick={() => setStep(2)}>
                  <span>Cancel</span>
                </button>
                <button type='button' onClick={() => setStep(2)}>
                  <span>Next</span>{' '}
                  <img
                    src={ArrowRight}
                    alt='Exit Button'
                    onClick={() => dispatch(setForm(''))}
                    style={{ cursor: 'pointer' }}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className='upload-wrapper'>
            <div className='form-header'>
              <h2
                style={{
                  marginTop: '25px',
                  textAlign: 'center',
                  marginBottom: '8px',
                }}>
                Register New Office
              </h2>

              <div className='help-msg-wrapper'>
                <span>If you need any help, please access Help Page.</span>
              </div>
            </div>
            <div className='file-wrapper'>
              <span>Company Logo</span>
              <FileUpload
                onFileAccepted={(file) => setFormData({ ...formData, file })}
                file={formData.file}
              />
              <div className='help-msg-wrapper'>
                {' '}
                <span style={{ color: 'black' }}>Or</span>
              </div>
              <FileDrop
                onFileAccepted={(file) => setFormData({ ...formData, file })}
                file={formData.file}
              />
            </div>
            <div style={{ width: '60%' }} className='choice-button'>
              <button type='button' onClick={() => setStep(1)}>
                <span>Back</span>
              </button>
              <button type='submit'>
                <span>Confirm</span>{' '}
                <img
                  src={ArrowRight}
                  alt='Exit Button'
                  onClick={() => dispatch(setForm(''))}
                  style={{ cursor: 'pointer' }}
                />
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div>
            <h1>Success!</h1>
            <p>Your office has been successfully created.</p>
          </div>
        )}
        <ToastContainer />
      </form>
    </div>
  );
};

export default MultiPartForm;
