import React from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setStartRange, setEndRange } from "state/slice/dropDownSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const { Option } = Select;

const TimeRangePicker = () => {
  const dispatch = useDispatch();
  const { startRange: currentStartRange, endRange: currentEndRange } =
    useSelector((state) => state.dropDown);

  const handleRangeChange = (value) => {
    let startRange, endRange;

    switch (value) {
      case "today":
        startRange = dayjs.utc().startOf("day");
        endRange = dayjs.utc().endOf("day");
        break;
      case "thisWeek":
        startRange = dayjs.utc().startOf("week");
        endRange = dayjs.utc().endOf("week");
        break;
      case "lastWeek":
        startRange = dayjs.utc().subtract(1, "week").startOf("week");
        endRange = dayjs.utc().subtract(1, "week").endOf("week");
        break;
      case "thisMonth":
        startRange = dayjs.utc().startOf("month");
        endRange = dayjs.utc().endOf("month");
        break;
      case "thisQuarter":
        startRange = dayjs.utc().startOf("quarter");
        endRange = dayjs.utc().endOf("quarter");
        break;
      case "thisYear":
        startRange = dayjs.utc().startOf("year");
        endRange = dayjs.utc().endOf("year");
        break;
      default:
        startRange = dayjs.utc().startOf("week");
        endRange = dayjs.utc().endOf("week");
    }

    if (
      startRange.toISOString() !== currentStartRange ||
      endRange.toISOString() !== currentEndRange
    ) {
      dispatch(setStartRange({ startRange: startRange.toISOString() }));
      dispatch(setEndRange({ endRange: endRange.toISOString() }));
    }
  };

  return (
    <Select defaultValue="today" onChange={handleRangeChange}>
      <Option value="today">Today</Option>
      <Option value="thisWeek">This Week</Option>
      <Option value="lastWeek">Last Week</Option>
      <Option value="thisMonth">This Month</Option>
      <Option value="thisQuarter">This Quarter</Option>
      <Option value="thisYear">This Year</Option>
    </Select>
  );
};

export default TimeRangePicker;
