/** @format */

import React, { createContext, useContext, useEffect } from 'react';
import Header from 'components/header';
import Menu from 'components/menu';
import CustoMenu from 'components/Custo/widgets/menu';

import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from 'state/slice/userSlice'; // Adjust the import path as needed

// Create a context
const UserContext = createContext();

// Export a hook to use the context
export const useUserContext = () => useContext(UserContext);

const CustoLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);

  // These inline styles will dynamically adjust the width of the sidebars for the transition.

  useEffect(() => {
    if (!userData) {
      // Check if userData is null or undefined
      dispatch(fetchUserData());
    }
  }, [dispatch, userData]);

  return (
    <UserContext.Provider value={userData}>
      <div className='container'>
        <Menu />

        <Header />
        <main className='main'>{children}</main>
      </div>
    </UserContext.Provider>
  );
};

export default CustoLayout;
