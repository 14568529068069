/** @format */
import React, { useState, useEffect } from 'react';
import { toggleBackoffice } from 'state/slice/tabSlice';
import SuperAdminLayout from '../../components/super-admin/layout';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toggleForm } from 'state/slice/buttonSlice';
import RegisterBackOffice from 'components/super-admin/widgets/backOffice';
import UserTable from 'components/super-admin/widgets/userTable';
import DownArrow from 'img/ArrowLineDown.png';
import RegisterUsersForm from 'components/super-admin/widgets/registerUsers';
import OfficeNames from 'components/super-admin/widgets/officeNames';
import Filter from 'img/ArrowsDownUp.svg';
import { searchUsers } from 'api/userRoutes';
import UserDetails from 'components/userDetails';
import { Select, Input, message } from 'antd';

const { Option } = Select;
const { Search } = Input;

const UsersTable = () => {
  const userFormActive = useSelector((state) => state.button.addUserForm);
  const selectedOfficeId = useSelector((state) => state.dropDown.officeId);
  const backOffice = useSelector((state) => state.activeTab.backOffice);
  const [filterBy, setFilterBy] = useState('No Office');
  const [endpoint, setEndpoint] = useState(
    `api/users/byOffice/${selectedOfficeId}`
  );
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  // Handle form toggle actions
  const handleNPNForm = () => {
    dispatch(toggleBackoffice(true));
  };
  const handleForm = (state) => {
    dispatch(toggleForm(state));
  };

  // Handle filter change between 'Office' and 'No Office'
  const handleFilterChange = (value) => {
    setFilterBy(value);
  };

  // Update endpoint whenever selectedOfficeId or filterBy changes
  useEffect(() => {
    if (filterBy === 'No Office') {
      setEndpoint(`api/super-admin/users/no-office`);
    } else if (selectedOfficeId) {
      setEndpoint(`api/users/byOffice/${selectedOfficeId}`);
    }
  }, [filterBy, selectedOfficeId]);

  // Fetch users based on the current endpoint
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
      const token = localStorage.getItem('accessToken');
      try {
        const response = await axios.get(`${apiUrl}/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        message.error('Failed to fetch users');
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [endpoint]);

  // Function to handle search by calling the backend
  const handleSearch = async (value) => {
    setLoading(true);
    try {
      const result = await searchUsers(value);
      setUsers(result); // Update the list of users with the search result from the backend
    } catch (error) {
      console.error('Error searching users:', error);
      message.error('Failed to search users');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div className='user-header'>
        <span className='user-tb-name '>Users</span>
        <div style={{ display: 'flex' }}>
          <div className='add-Agent-Wrapper'>
            <button onClick={() => handleForm(true)}>
              <span>New User</span>
              <img src={DownArrow} alt='down arrow' />
            </button>
          </div>
          <div className='add-Agent-Wrapper'>
            <button onClick={handleNPNForm}>
              <span>Back Office</span>
              <img src={DownArrow} alt='down arrow' />
            </button>
          </div>
        </div>
      </div>
      <div className='filter-container'>
        <div className='filter'>
          <img src={Filter} alt='Filter' />
          <span>Filter By </span>
          <div className='a-p-tab-wrapper'>
            {/* Dropdown to select between "Office" and "No Office" */}
            <Select
              value={filterBy}
              onChange={handleFilterChange}
              style={{ width: 150 }}>
              <Option value='Office'>Office</Option>
              <Option value='No Office'>No Office</Option>
            </Select>
            {filterBy === 'Office' && <OfficeNames />}
          </div>
        </div>

        {/* Search Input using Ant Design */}
        <div style={{ width: '200px' }}>
          <Search
            placeholder='Search users...'
            enterButton='Search'
            onSearch={handleSearch}
            loading={loading}
            style={{ marginLeft: 10 }}
          />
        </div>
      </div>

      {/* Pass the dynamically updated users list to the UserTable */}
      <UserTable users={users} loading={loading} />

      {userFormActive && <RegisterUsersForm />}
      {backOffice && <RegisterBackOffice />}
    </div>
  );
};

const Users = () => {
  const userId = useSelector((state) => state.dropDown.userId); // Access userId
  return (
    <SuperAdminLayout>
      {userId ? <UserDetails /> : <UsersTable />}
    </SuperAdminLayout>
  );
};

export default Users;
