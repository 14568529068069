/** @format */

import React from 'react';
import { CSVLink } from 'react-csv';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const U65ExportCSVButton = ({ data }) => {
  const headers = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Phone Number', key: 'phoneNumber' },
    { label: 'Vendor Name', key: 'vendorName' },
    { label: 'Member Id', key: 'memberId' },
    { label: 'Carrier', key: 'carrier' },
    { label: 'Plan Type', key: 'planType' },
    { label: 'Agent Name', key: 'agentName' },
    { label: 'Billing Date', key: 'billingDate' },
    { label: 'FFMID', key: 'FFMID' },
    { label: 'Health Sherpa', key: 'healthSherpa' },
    { label: 'Premium', key: 'premium' },
    { label: 'Status', key: 'status' },
    { label: 'Date', key: 'todaysDate' },
    { label: 'Ancillaries Type', key: 'ancillaries.type' },
    { label: 'Ancillaries Carrier', key: 'ancillaries.carrier' },
    { label: 'Ancillaries Billing Date', key: 'ancillaries.billingDate' },
    { label: 'Ancillaries Premium', key: 'ancillaries.premium' },
    { label: 'Ancillaries Status', key: 'ancillaries.status' },
    { label: 'Major Med Type', key: 'majorMed.type' },
    { label: 'Major Med Carrier', key: 'majorMed.carrier' },
    { label: 'Major Med Billing Date', key: 'majorMed.billingDate' },
    { label: 'Major Med Premium', key: 'majorMed.premium' },
    { label: 'Major Med Status', key: 'majorMed.status' },
    { label: 'Private Med Type', key: 'privateMed.type' },
    { label: 'Private Med Carrier', key: 'privateMed.carrier' },
    { label: 'Private Med Billing Date', key: 'privateMed.billingDate' },
    { label: 'Private Med Premium', key: 'privateMed.premium' },
    { label: 'Private Med Status', key: 'privateMed.status' },
  ];

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date)
      ? date.toLocaleDateString()
      : 'Invalid Date';
  };

  const csvData = data.map((form) => ({
    firstName: form.firstName,
    lastName: form.lastName,
    phoneNumber: form.phoneNumber,
    vendorName: form.vendorName,
    memberId: form.memberId,
    carrier: form.carrier,
    planType: Array.isArray(form.planType)
      ? form.planType.join(', ')
      : form.planType,
    agentName: form.agentName,
    billingDate: form.billingDate ? formatDate(form.billingDate) : '',
    FFMID: form.FFMID,
    healthSherpa: form.healthSherpa,
    premium: form.premium,
    status: form.status,
    todaysDate: formatDate(form.createdAt),
    'ancillaries.type':
      form.ancillaries && form.ancillaries[0] ? form.ancillaries[0].type : '',
    'ancillaries.carrier':
      form.ancillaries && form.ancillaries[0]
        ? form.ancillaries[0].carrier
        : '',
    'ancillaries.billingDate':
      form.ancillaries && form.ancillaries[0]
        ? formatDate(form.ancillaries[0].billingDate)
        : '',
    'ancillaries.premium':
      form.ancillaries && form.ancillaries[0]
        ? form.ancillaries[0].premium
        : '',
    'ancillaries.status':
      form.ancillaries && form.ancillaries[0] ? form.ancillaries[0].status : '',
    'majorMed.type': form.majorMed ? form.majorMed.type : '',
    'majorMed.carrier': form.majorMed ? form.majorMed.carrier : '',
    'majorMed.billingDate': form.majorMed
      ? formatDate(form.majorMed.billingDate)
      : '',
    'majorMed.premium': form.majorMed ? form.majorMed.premium : '',
    'majorMed.status': form.majorMed ? form.majorMed.status : '',
    'privateMed.type': form.privateMed ? form.privateMed.type : '',
    'privateMed.carrier': form.privateMed ? form.privateMed.carrier : '',
    'privateMed.billingDate': form.privateMed
      ? formatDate(form.privateMed.billingDate)
      : '',
    'privateMed.premium': form.privateMed ? form.privateMed.premium : '',
    'privateMed.status': form.privateMed ? form.privateMed.status : '',
  }));

  return (
    <CSVLink
      data={csvData}
      headers={headers}
      filename={'form-data.csv'}
      style={{ textDecoration: 'none' }}>
      <Button icon={<DownloadOutlined />}>Export CSV</Button>
    </CSVLink>
  );
};

export default U65ExportCSVButton;
