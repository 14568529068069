/** @format */

import axios from 'axios';
import { accessToken } from 'utils/accessToken';
const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

export const getFormDatas = async (endpoint, startDate, endDate) => {
  const token = localStorage.getItem('accessToken');
  try {
    const response = await axios.get(`${apiUrl}/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        startTime: startDate,
        endTime: endDate,
      },
    });
    //console.log('start date for table', startDate);
    return response.data;
  } catch (error) {
    console.error('Error fetching form data:', error);
    throw error; // Allow the calling function to handle the error
  }
};

export const getVendors = async (endpoint2) => {
  const token = localStorage.getItem('accessToken');
  try {
    const response = await axios.get(`${apiUrl}/${endpoint2}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching vendors:', error);
    throw error;
  }
};

// New deleteForm function to handle form deletion
export const deleteForm = async (formId) => {
  const token = localStorage.getItem('accessToken');
  try {
    const response = await axios.delete(
      `${apiUrl}/api/policy-form/admin/delete-form/${formId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting form:', error);
    throw error;
  }
};

// New updateForm function to handle form updates
export const updateForm = async (formId, updateData) => {
  const token = localStorage.getItem('accessToken');
  try {
    const response = await axios.put(
      `${apiUrl}/api/policy-form/admin/update-form/${formId}`,
      updateData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating form:', error);
    throw error;
  }
};

export const getProfilePic = async (userId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/settings/profile-picture/${userId}`,
      accessToken
    );
    return response.data;
  } catch (error) {
    console.error('Error getting global names list:', error);
  }
};
