/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, message } from 'antd';
import backArrow from 'img/ArrowLeft.svg';
import { EditOutlined } from '@ant-design/icons';
import Exit from 'img/exit.svg'; // Ensure the path to your 'Exit' icon is correct
import { toggleSetting } from 'state/slice/buttonSlice';
import { getUserProfile, getProfilePic } from 'api/userSettingsRoutes'; // Import your API calls
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { toggleProfileUpload } from 'state/slice/buttonSlice';
import ProfileIcon from 'img/profileRound.svg'; // Default profile picture
import ProfilePictureUpload from './ProfilePicUpload'; // Component for uploading new picture

const UserSetting = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null); // Store user data
  const [profilePicUrl, setProfilePicUrl] = useState(ProfileIcon); // Store profile picture URL
  const uploadPic = useSelector((state) => state.button.profileUpload); // Redux state for toggling upload
  console.log(profilePicUrl.profilePicUrl);
  // Fetch user profile data on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await getUserProfile();
        setUserData(user);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };
    fetchUserData();
  }, []);

  // Fetch the profile picture URL on component mount
  useEffect(() => {
    const fetchUserProfilePic = async () => {
      try {
        const profilePic = await getProfilePic();
        if (profilePic) {
          setProfilePicUrl(profilePic); // If a profile picture is found, update the state
        }
      } catch (error) {
        console.error('Failed to fetch profile picture:', error);
      }
    };
    fetchUserProfilePic();
  }, []);

  // Handle edit profile button toggle
  const handleEditProfile = (state) => {
    dispatch(toggleProfileUpload(state));
  };

  return (
    <div className='form-layout'>
      {userData && (
        <div className='exit-form-wrapper'>
          <button
            className='exit-form'
            style={{ float: 'right', marginBottom: '30px' }}
            onClick={() => dispatch(toggleSetting(false))}>
            <img src={Exit} alt='Exit' style={{ cursor: 'pointer' }} />
          </button>
        </div>
      )}

      <div className='settings-container'>
        {!uploadPic ? (
          <>
            {userData && (
              <Row gutter={[16, 16]} justify={'center'}>
                <Col xs={24} sm={24} lg={24}>
                  <div className='profile-pic-container'>
                    <div className='profile-pic-large'>
                      {/* Display the profile picture or a default icon */}
                      <img
                        src={profilePicUrl.profilePicUrl || ProfileIcon}
                        alt='Profile Icon'
                      />
                    </div>
                    <div
                      className='edit-icon'
                      onClick={() => handleEditProfile(true)}>
                      <EditOutlined />
                    </div>
                  </div>
                </Col>
                <Col lg={24}></Col>
                <Col lg={24}></Col>
                <Col xs={24} sm={24} lg={11}>
                  <div className='detail-wrapper'>
                    <p className='detail-title'>First Name</p>
                    <p className='detail-text'>
                      {capitalizeFirstLetter(userData.firstname)}
                    </p>
                  </div>
                </Col>
                <Col xs={24} sm={24} lg={11}>
                  <div className='detail-wrapper'>
                    <p className='detail-title'>Last Name</p>
                    <p className='detail-text'>
                      {capitalizeFirstLetter(userData.lastname)}
                    </p>
                  </div>
                </Col>
                <Col xs={24} sm={24} lg={11}>
                  <div className='detail-wrapper'>
                    <p className='detail-title'>User Name</p>
                    <p className='detail-text'>{userData.username}</p>
                  </div>
                </Col>
                <Col xs={24} sm={24} lg={11}>
                  <div className='detail-wrapper'>
                    <p className='detail-title'>Email</p>
                    <p className='detail-text'>{userData.email}</p>
                  </div>
                </Col>
                <Col xs={24} sm={24} lg={11}>
                  <div className='detail-wrapper'>
                    <p className='detail-title'>Role</p>
                    <p className='detail-text'>{userData.role}</p>
                  </div>
                </Col>
                {userData.officeName && (
                  <Col xs={24} sm={24} lg={11}>
                    <div className='detail-wrapper'>
                      <p className='detail-title'>Office Name</p>
                      <p className='detail-text'>{userData.officeName}</p>
                    </div>
                  </Col>
                )}
              </Row>
            )}
          </>
        ) : (
          <>
            <div style={{ paddingLeft: '20px' }}>
              <button
                className='white-back-button'
                onClick={() => handleEditProfile(false)}>
                <img src={backArrow} alt='back Arrow' />
                <span>Back</span>
              </button>
            </div>
            <div className='upload-wrapper '>
              <ProfilePictureUpload />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserSetting;
