/** @format */

import React from 'react';
import AdminLayout from 'components/admin/layout';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveStatsTab } from 'state/slice/tabSlice';
import DownArrow from 'img/ArrowLineDown.png';
import SalesSummary from 'components/super-admin/widgets/affiliatesSaleSummary';
import OfficeNames from 'components/admin/widgets/officeNames';
import AgentStatsTable from 'components/admin/widgets/agentU65StatsTable';

import RegisterUsersForm from 'components/super-admin/widgets/registerUsers';
import { toggleForm } from 'state/slice/buttonSlice';

const AffiliatePerformanceAcess = () => {
  const selectedOfficeId = useSelector((state) => state.dropDown.officeId);
  const activeStatsTab = useSelector((state) => state.activeTab.activeStatsTab);

  const dispatch = useDispatch();

  const handleForm = (state) => {
    dispatch(toggleForm(state));
  };

  const handleStatsTab = (tab) => {
    dispatch(setActiveStatsTab(tab));
  };

  return (
    <AdminLayout>
      <div className='affilliates-content-container'>
        <div className='a-p-tab-wrapper'>
          <OfficeNames />
        </div>
        <div className='stats-container'>
          <div className='stats-header'>
            <div className='stats-tab-wrapper'>
              <span
                onClick={() => handleStatsTab('operation')}
                className={activeStatsTab === 'operation' ? 'active' : ''}>
                Operation Overview
              </span>
              <span
                onClick={() => handleStatsTab('agent')}
                className={activeStatsTab === 'agent' ? 'active' : ''}>
                Agent Overview
              </span>
            </div>
            <div>
              <div className='add-Agent-Wrapper'>
                <button
                  onClick={() => handleForm(true)}
                  style={{
                    display: activeStatsTab === 'agent' ? 'flex' : 'none',
                  }}>
                  <span>New Agent</span>
                  <img src={DownArrow} alt='down arrow' />
                </button>
              </div>
            </div>
          </div>

          {activeStatsTab === 'operation' && <SalesSummary />}
          {activeStatsTab === 'agent' && (
            <AgentStatsTable
              endpoint={`api/stats/super-admin/offices/sales-table/${selectedOfficeId}`}
            />
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AffiliatePerformanceAcess;
