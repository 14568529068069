/** @format */

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer, persistStore } from 'redux-persist';
import toggleTab from './slice/toggleTabAction';
import viewOffice from './slice/viewOfficeAction';
import usersOfficeId from './slice/officeIdAction';
import dropDownReducer from './slice/dropDownSlice';
import userReducer from './slice/userSlice';
import sidebarReducer from './slice/sidebar';
import activeTabReducer from './slice/tabSlice';
import buttonReducer from './slice/buttonSlice';
import switchReducer from './slice/switchSlice';

import downlineAccessReducer from './slice/downlineAccessSlice';
// Creating a root reducer manually to add to persistReducer
const rootReducer = combineReducers({
  toggleTab,
  viewOffice,
  usersOfficeId,
  dropDown: dropDownReducer,
  user: userReducer,
  downlineAccess: downlineAccessReducer,
  sidebar: sidebarReducer,
  activeTab: activeTabReducer,
  button: buttonReducer,
  switchButton: switchReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['sidebar', 'switchButton', 'user'], // You can choose to persist specific reducers, here 'sidebar'
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {
        warnAfter: 128, // Adjust this value to a higher threshold if needed
      },
      serializableCheck: false, // Disable serializable check if it's causing issues
    }),
});

const persistor = persistStore(store);

export { store, persistor };
