/** @format */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUserProfile } from 'state/slice/buttonSlice';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ProfileIcon from 'img/defaultUser.jpg';
import RightIcon from 'img/ArrowLineRightBlack.svg';
import { getProfilePic } from 'api/routes';
const U65Sales = ({ path }) => {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [profilePics, setProfilePics] = useState({}); // State to store profile pictures
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
  const handleUserProfile = (Id) => {
    dispatch(setUserProfile({ userId: Id }));
  };
  useEffect(() => {
    const fetchSalesData = async () => {
      const token = localStorage.getItem('accessToken');

      try {
        const response = await axios.get(`${apiUrl}/${path}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSalesData(response.data);
        response.data.forEach(async (agent) => {
          if (!agent.userId) {
            return;
          }
          try {
            const profilePicData = await getProfilePic(agent.userId); // Fetch profile picture
            const profilePicUrl = profilePicData
              ? profilePicData.profilePicUrl
              : null;

            // Check if profile picture exists
            setProfilePics((prevState) => ({
              ...prevState,
              [agent.userId]: profilePicUrl || ProfileIcon, // Set fetched picture or fallback to default
            }));
          } catch (error) {
            console.error(
              `Error fetching profile picture for ${agent.userId}:`,
              error
            );
            setProfilePics((prevState) => ({
              ...prevState,
              [agent.userId]: ProfileIcon, // Fallback to default if error occurs
            }));
          }
        });
      } catch (err) {
        setError(
          err.response ? err.response.data : 'Error fetching sales data'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchSalesData();
  }, [apiUrl, path]);

  const sortedSalesData = [...salesData]
    .sort(
      (a, b) =>
        b.totalPremium +
        b.totalAncillariesPremium -
        (a.totalPremium + a.totalAncillariesPremium)
    )
    .slice(0, 9); // Taking only top 9
  // Function to get the CSS class based on the rank
  const getRankClass = (index) => {
    if (index === 0) return 'top1';
    if (index === 1) return 'top2';
    if (index === 2) return 'top3';
    return '';
  };

  const capitalizeFullName = (name) => {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <div>
      {loading ? (
        <p>Loading sales data...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div className='side-leaderboard-container'>
          {sortedSalesData.map((data, index) => (
            <div
              className='place-wrapper'
              key={index}
              onClick={() => handleUserProfile(data.userId)}>
              <div className='leaderboard-pf'>
                <img
                  style={{ borderRadius: '50%' }}
                  alt='number placement'
                  src={profilePics[data.userId] || ProfileIcon}
                />
                <div className={`rank ${getRankClass(index)}`}>
                  {' '}
                  {index + 1}
                </div>{' '}
                {/* Rank starts from 1 */}
              </div>
              <div className='side-s-l'>
                <span style={{ fontSize: '14px' }}>
                  {' '}
                  {capitalizeFullName(data.agentName)}
                </span>
                <div>
                  <td>
                    {' '}
                    {data.totalACASales} ACA <span> </span> |
                  </td>
                  <td style={{ marginRight: '5px' }}>
                    {' '}
                    {data.totalU65Sales} U65 <span> </span> |
                  </td>
                  <td style={{ paddingLeft: '5px' }}>
                    {' '}
                    $
                    {(
                      data.totalPremium + data.totalAncillariesPremium
                    ).toLocaleString()}
                  </td>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const U65SalesNextRanks = ({ path }) => {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profilePics, setProfilePics] = useState({}); // State to store profile pictures
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

  useEffect(() => {
    const fetchSalesData = async () => {
      const token = localStorage.getItem('accessToken');

      try {
        const response = await axios.get(`${apiUrl}/${path}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSalesData(response.data);
        response.data.forEach(async (agent) => {
          if (!agent.userId) {
            return;
          }
          try {
            const profilePicData = await getProfilePic(agent.userId); // Fetch profile picture
            const profilePicUrl = profilePicData
              ? profilePicData.profilePicUrl
              : null;

            // Check if profile picture exists
            setProfilePics((prevState) => ({
              ...prevState,
              [agent.userId]: profilePicUrl || ProfileIcon, // Set fetched picture or fallback to default
            }));
          } catch (error) {
            console.error(
              `Error fetching profile picture for ${agent.userId}:`,
              error
            );
            setProfilePics((prevState) => ({
              ...prevState,
              [agent.userId]: ProfileIcon, // Fallback to default if error occurs
            }));
          }
        });
      } catch (err) {
        setError(
          err.response ? err.response.data : 'Error fetching sales data'
        );
      } finally {
        setLoading(false);
      }
    };
    fetchSalesData();
  }, [apiUrl, path]);

  // Sort the salesData by total leads in descending order and take ranks 10-13
  const sortedSalesData = [...salesData]
    .sort(
      (a, b) =>
        b.totalPremium +
        b.totalAncillariesPremium -
        (a.totalPremium + a.totalAncillariesPremium)
    )
    .slice(8, 12); // Taking ranks 10-13

  const capitalizeFullName = (name) => {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <div>
      {loading ? (
        <p>Loading sales data...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div className='grid-link-container'>
          <div className='grid'>
            {sortedSalesData.length > 0 &&
              sortedSalesData.map((data, index) => (
                <div key={index + 9}>
                  <div className='leaderboard-pf'>
                    <img
                      style={{
                        borderRadius: '50%',
                        height: '28px',
                      }}
                      alt='number placement'
                      src={profilePics[data.userId] || ProfileIcon}
                    />
                  </div>
                </div>
              ))}
          </div>{' '}
          {sortedSalesData.length > 0 && (
            <div className='grid-link'>
              <span>See All</span>
              <img src={RightIcon} alt='right arrow icon' />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const RightSideBar = ({ path }) => {
  const isRightBarOpen = useSelector(
    (state) => state.sidebar.notificationCollapse
  );
  const rightStyle = {
    width: isRightBarOpen ? ' 300px' : '0px',
    padding: isRightBarOpen ? '16px' : '0px',
  };
  return (
    <aside className='right' style={rightStyle}>
      <span style={{ fontSize: '14px' }}>Notifications</span>
      <div className='notification-container'></div>
      <span style={{ fontSize: '14px' }}>Leaderboard</span>
      <div className='asd-leaderboard-container'>
        <U65Sales path={path} />
        <U65SalesNextRanks path={path} />
      </div>
    </aside>
  );
};

export default RightSideBar;
