/** @format */

import React, { useEffect, useState } from 'react';
import backArrow from 'img/ArrowLeft.svg';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedFormId } from 'state/slice/buttonSlice';
import { Card, Col, Row, Button } from 'antd';
import { getCustomerById } from 'api/customerRoute';
import moment from 'moment';
import CustomerPlans from './customerPlans';

const CustomerDetails = () => {
  const [customer, setCustomer] = useState(null);
  const [ssnVisible, setSsnVisible] = useState(false);
  const [spouseSsnVisible, setSpouseSsnVisible] = useState(false);
  const [dependentsSsnVisible, setDependentsSsnVisible] = useState({});
  const [editState, setEditState] = useState({
    ancillaries: [],
    privateMed: false,
    majorMed: false,
  });
  const [editableData, setEditableData] = useState({
    ancillaries: [],
    privateMed: {},
    majorMed: {},
  });
  const { userAccess } = useSelector((state) => state.user);
  const { role } = useSelector((state) => state.user.userData);
  const selectedFormId = useSelector((state) => state.button.selectedFormId);
  const dispatch = useDispatch();

  const toggleSSNVisibility = () => {
    if (!role || userAccess?.ssnAccess) {
      setSsnVisible((prev) => !prev);
    }
  };

  const toggleSpouseSSNVisibility = () => {
    if (!role || userAccess?.ssnAccess) {
      setSpouseSsnVisible((prev) => !prev);
    }
  };

  const toggleDependentSSNVisibility = (index) => {
    setDependentsSsnVisible((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    const fetchFormDetails = async () => {
      if (selectedFormId) {
        try {
          const customerData = await getCustomerById(selectedFormId);
          setCustomer(customerData);
          setEditState({
            ancillaries: customerData.ancillaries?.map(() => false) || [],
            privateMed: false,
            majorMed: false,
          });
          setEditableData({
            ancillaries: customerData.ancillaries || [],
            privateMed: customerData.privateMed || {},
            majorMed: customerData.majorMed || {},
          });
        } catch (error) {
          console.error('Error fetching form details:', error);
        }
      }
    };

    fetchFormDetails();
  }, [selectedFormId]);

  const toggleEditMode = (planType, index = null) => {
    setEditState((prevState) => {
      if (index !== null) {
        const newAncillariesEditState = [...prevState.ancillaries];
        newAncillariesEditState[index] = !newAncillariesEditState[index];
        return { ...prevState, ancillaries: newAncillariesEditState };
      } else {
        return { ...prevState, [planType]: !prevState[planType] };
      }
    });
  };

  const handleChange = (planType, index, field, value) => {
    setEditableData((prevData) => {
      if (index !== null) {
        const newAncillaries = [...prevData.ancillaries];
        newAncillaries[index] = { ...newAncillaries[index], [field]: value };
        return { ...prevData, ancillaries: newAncillaries };
      } else {
        return {
          ...prevData,
          [planType]: { ...prevData[planType], [field]: value },
        };
      }
    });
  };

  const handleFormView = (formId) => {
    dispatch(setSelectedFormId(formId));
  };

  const renderSSN = (ssn, visible) => {
    if (!ssn) return 'N/A';
    // Show the full SSN if visible, otherwise show last 4 digits
    if (visible) {
      return ssn;
    } else {
      return `***-**-${ssn.slice(-4)}`;
    }
  };

  if (!customer) {
    return <div>No form selected.</div>;
  }

  return (
    <div className='customer-details-container'>
      <button
        className='white-back-button'
        style={{ marginBottom: '20px' }}
        onClick={() => handleFormView(null)}>
        <img src={backArrow} alt='Back Arrow' />
        <span>Back</span>
      </button>

      {/* First Row: Personal Information */}
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card title='Personal Information'>
            <div style={{ padding: '10px' }}>
              <p>
                <strong>First Name:</strong> {customer.firstName}
              </p>
              <p>
                <strong>Last Name:</strong> {customer.lastName}
              </p>
              <p>
                <strong>Phone:</strong> {customer.phoneNumber}
              </p>
              <p>
                <strong>Date of Birth:</strong>{' '}
                {moment.utc(customer.dateOfBirth).format('YYYY-MM-DD')}
              </p>
              <p>
                <strong>Household Income:</strong>{' '}
                {customer.householdIncome || 'N/A'}
              </p>
              <p>
                <strong>Ocupation :</strong> {customer.typeOfWork || 'N/A'}
              </p>
              <p>
                <strong>SSN:</strong> {renderSSN(customer.SSN, ssnVisible)}{' '}
                <Button type='link' onClick={toggleSSNVisibility}>
                  {ssnVisible ? 'Hide' : 'Show'}
                </Button>
              </p>
              <p>
                <strong>FFM ID:</strong> {customer.FFMID || 'N/A'}
              </p>
              <p>
                <strong>Member ID:</strong> {customer.memberId || 'N/A'}
              </p>
            </div>
          </Card>
        </Col>

        {/* Address Information */}
        <Col span={8}>
          <Card title='Address Information'>
            <div style={{ padding: '10px' }}>
              <p>
                <strong>Street:</strong> {customer.address?.street || 'N/A'}
              </p>
              <p>
                <strong>City:</strong> {customer.address?.city || 'N/A'}
              </p>
              <p>
                <strong>State:</strong> {customer.address?.state || 'N/A'}
              </p>
              <p>
                <strong>Apt/Unit:</strong> {customer.address?.aptUnite || 'N/A'}
              </p>
              <p>
                <strong>Postal Code:</strong>{' '}
                {customer.address?.postalCode || 'N/A'}
              </p>

              <p>
                <strong>Country:</strong> {customer.address?.country || 'N/A'}
              </p>
            </div>
          </Card>
        </Col>

        {/* Back Office Information */}
        <Col span={8}>
          <Card title='Back Office Information'>
            <div style={{ padding: '10px' }}>
              <p>
                <strong>Agent Name:</strong> {customer.agentName || 'N/A'}
              </p>
              <p>
                <strong>AOR:</strong> {customer.AOR || 'N/A'}
              </p>
              <p>
                <strong>Health Sherpa:</strong> {customer.healthSherpa || 'N/A'}
              </p>
              <p>
                <strong>NPN OverRide:</strong> {customer.NPN || 'N/A'}
              </p>
              <p>
                <strong>QLE:</strong> {customer.QLE || 'N/A'}
              </p>
              <p>
                <strong>Enrollment Fee:</strong> $
                {customer.enrollmentFee || 'N/A'}
              </p>
            </div>
          </Card>
        </Col>
      </Row>

      {/* New Row: Spouse and Dependents Information */}
      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        {(customer.spouseInfo ||
          (customer.dependentsInfo && customer.dependentsInfo.length > 0)) && (
          <Col sm={24} md={24} lg={24} xl={24}>
            <div className='form-heading'>
              <span>Spouse and Dependent</span>
            </div>
          </Col>
        )}

        {customer.spouseInfo && (
          <Col span={8}>
            <Card title='Spouse Information'>
              <div style={{ padding: '10px' }}>
                <p>
                  <strong>First Name:</strong> {customer.spouseInfo.firstName}
                </p>
                <p>
                  <strong>Last Name:</strong> {customer.spouseInfo.lastName}
                </p>
                <p>
                  <strong>Date of Birth:</strong>{' '}
                  {moment
                    .utc(customer.spouseInfo.dateOfBirth)
                    .format('YYYY-MM-DD')}
                </p>
                <p>
                  <strong>SSN:</strong>{' '}
                  {renderSSN(customer.spouseInfo.SSN, spouseSsnVisible)}{' '}
                  <Button type='link' onClick={toggleSpouseSSNVisibility}>
                    {spouseSsnVisible ? 'Hide' : 'Show'}
                  </Button>
                </p>
              </div>
            </Card>
          </Col>
        )}
        {customer.dependentsInfo && customer.dependentsInfo.length > 0 && (
          <Col span={8}>
            <Card title='Dependents Information'>
              {customer.dependentsInfo.map((dependent, index) => (
                <div key={index} style={{ padding: '10px' }}>
                  <p>
                    <strong>First Name:</strong> {dependent.firstName}
                  </p>
                  <p>
                    <strong>Last Name:</strong> {dependent.lastName}
                  </p>
                  <p>
                    <strong>Date of Birth:</strong>{' '}
                    {moment.utc(dependent.dateOfBirth).format('YYYY-MM-DD')}
                  </p>
                  <p>
                    <strong>SSN:</strong>{' '}
                    {renderSSN(dependent.SSN, dependentsSsnVisible[index])}{' '}
                    <Button
                      type='link'
                      onClick={() => toggleDependentSSNVisibility(index)}>
                      {dependentsSsnVisible[index] ? 'Hide' : 'Show'}
                    </Button>
                  </p>
                </div>
              ))}
            </Card>
          </Col>
        )}
      </Row>

      {/* Plans Section */}
      <CustomerPlans
        customer={customer}
        editState={editState}
        editableData={editableData}
        toggleEditMode={toggleEditMode}
        handleChange={handleChange}
      />

      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        <Col sm={24} md={24} lg={24} xl={24}>
          <div className='form-heading'>
            <span>Comments </span>
          </div>
        </Col>
        <Col sm={24} md={24} lg={24} xl={24}>
          <div className='form-input-wrapper'>
            <span>{customer.notes}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerDetails;
