/** @format */
import React, { useState, useEffect } from 'react';
import { Table, Input, Button, message, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

const { Search } = Input;
const { Option } = Select;

const CallLogsTable = ({ path }) => {
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editedLog, setEditedLog] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [pageSize, setPageSize] = useState(100);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(
          `${apiUrl}/api/${path}`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startTime: startDate,
              endTime: endDate,
            },
          }
        );
        setLogs(response.data);
        setFilteredLogs(response.data); // Initialize filtered logs
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, [apiUrl, startDate, endDate, path]);

  const handleEdit = (id) => {
    setEditId(id);
    const logToEdit = logs.find((log) => log._id === id);
    setEditedLog({ ...logToEdit });
  };

  const handleSave = async () => {
    const token = localStorage.getItem('accessToken');

    try {
      await axios.put(
        `${apiUrl}/api/vendors/call-logs/update/${editedLog._id}`,
        editedLog,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedLogs = logs.map((log) =>
        log._id === editedLog._id ? editedLog : log
      );

      setLogs(updatedLogs);

      // Reapply the last search filter after saving
      const reFilteredData = updatedLogs.filter((log) => {
        const logValue = log[searchKey]?.toString().toLowerCase() || '';
        return logValue.includes(searchValue.toLowerCase());
      });

      setFilteredLogs(reFilteredData);
      setEditId(null);
      setEditedLog({});
      message.success('Call log updated successfully');
    } catch (error) {
      setError('Failed to save log data');
      message.error('Failed to save log data');
    }
  };

  const handleCancel = () => {
    setEditId(null);
    setEditedLog({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedLog({
      ...editedLog,
      [name]: value,
    });
  };

  const handleBillableChange = (value) => {
    setEditedLog({
      ...editedLog,
      Billable: value,
    });
  };

  const handleSearch = (key, value) => {
    setSearchKey(key);
    setSearchValue(value);

    const filteredData = logs.filter((log) => {
      const logValue = log[key]?.toString().toLowerCase() || '';
      return logValue.includes(value.toLowerCase());
    });
    setFilteredLogs(filteredData);
  };
  const columns = [
    {
      title: 'Call Date',
      dataIndex: 'callDate',
      key: 'callDate',
      render: (text) => {
        if (!text) return 'N/A';
        try {
          const utcDate = new Date(text);
          if (isNaN(utcDate.getTime())) {
            return 'Invalid Date';
          }
          const correctedTime = new Date(
            utcDate.getTime() + 7 * 60 * 60 * 1000
          );
          return correctedTime.toLocaleString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          });
        } catch (error) {
          return 'Error';
        }
      },
    },
    { title: 'Call Length (s)', dataIndex: 'callLength', key: 'callLength' },
    {
      title: 'Call From',
      dataIndex: 'callFrom',
      key: 'callFrom',
      filterDropdown: () => (
        <Search
          placeholder='Search Call From'
          onSearch={(value) => handleSearch('callFrom', value)}
          style={{ width: 200 }}
        />
      ),
      filterIcon: <SearchOutlined />,
    },
    {
      title: 'Call To',
      dataIndex: 'callTo',
      key: 'callTo',
      filterDropdown: () => (
        <Search
          placeholder='Search Call To'
          onSearch={(value) => handleSearch('callTo', value)}
          style={{ width: 200 }}
        />
      ),
      filterIcon: <SearchOutlined />,
    },
    {
      title: 'Queue ID',
      dataIndex: 'queueId',
      key: 'queueId',
      filterDropdown: () => (
        <Search
          placeholder='Search Queue ID'
          onSearch={(value) => handleSearch('queueId', value)}
          style={{ width: 200 }}
        />
      ),
      filterIcon: <SearchOutlined />,
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentName',
      key: 'agentName',
      filterDropdown: () => (
        <Search
          placeholder='Search Agent Name'
          onSearch={(value) => handleSearch('agentName', value)}
          style={{ width: 200 }}
        />
      ),
      filterIcon: <SearchOutlined />,
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      key: 'vendorName',
      filterDropdown: () => (
        <Search
          placeholder='Search Vendor Name'
          onSearch={(value) => handleSearch('vendorName', value)}
          style={{ width: 200 }}
        />
      ),
      filterIcon: <SearchOutlined />,
    },
    {
      title: 'Billable',
      dataIndex: 'Billable',
      key: 'Billable',
      render: (text, record) =>
        editId === record._id ? (
          <Select
            value={editedLog.Billable}
            onChange={handleBillableChange}
            style={{ width: 120 }}>
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        ) : text ? (
          'Yes'
        ) : (
          'No'
        ),
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      render: (text, record) =>
        editId === record._id ? (
          <Input name='cost' value={editedLog.cost} onChange={handleChange} />
        ) : (
          `$${text}`
        ),
    },

    { title: 'Status', dataIndex: 'statusName', key: 'statusName' },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) =>
        editId === record._id ? (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button type='primary' onClick={handleSave}>
              Save
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </div>
        ) : (
          <Button onClick={() => handleEdit(record._id)}>Edit</Button>
        ),
    },
  ];

  return (
    <div className='call-logs-table' style={{ overflowX: 'auto' }}>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {!loading && !error && (
        <Table
          columns={columns}
          dataSource={filteredLogs}
          rowKey={(record) => record._id}
          pagination={{
            current: currentPage,
            pageSize: pageSize, // Controlled by a state variable for dynamic page size
            pageSizeOptions: ['10', '20', '50', '100'], // Options for selectable page sizes
            showSizeChanger: true, // Enables the page size dropdown
            onShowSizeChange: (current, size) => setPageSize(size), // Updates page size
            onChange: (page) => setCurrentPage(page),
          }}
        />
      )}
    </div>
  );
};

export default CallLogsTable;
