/** @format */

import React, { useState } from 'react';
import { Table, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedFormId } from 'state/slice/buttonSlice';
import { setUserProfile } from 'state/slice/buttonSlice';

const CustomerTable = ({ customers, loading }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  // Capitalize the first letter of a name
  const capitalizeName = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  // Handle menu click for user actions
  const handleFormView = (formId) => {
    dispatch(setSelectedFormId(formId));
    // Additional logic for form view if needed
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text) => capitalizeName(text),
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text) => capitalizeName(text),
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
    },
    {
      title: 'FFM ID',
      dataIndex: 'FFMID',
      key: 'FFMID',
      render: (text) => (text ? text : 'N/A'), // Display 'N/A' if FFMID is null or undefined
      sorter: (a, b) => (a.FFMID || '').localeCompare(b.FFMID || ''),
    },
    {
      title: 'Member ID',
      dataIndex: 'memberId',
      key: 'memberId',
      sorter: (a, b) => a.memberId.localeCompare(b.memberId),
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentName',
      key: 'agentName',
      sorter: (a, b) => a.agentName.localeCompare(b.agentName),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item
              key='viewUser'
              onClick={() => handleFormView(record._id)}>
              View Customer
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <EllipsisOutlined style={{ fontSize: 24, cursor: 'pointer' }} />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={customers}
      columns={columns}
      rowKey={(record) => record._id}
      loading={loading}
      pagination={{
        current: currentPage,

        onChange: (page) => setCurrentPage(page),
      }}
    />
  );
};

export default CustomerTable;
