/** @format */

import React, { createContext, useContext } from 'react';
import RightSideBar from './widgets/rightSidebar';
import AdminMenu from './widgets/menu';
import Header from 'components/header';
import UserProfile from 'components/userProfile';
import { useSelector } from 'react-redux';
import Menu from 'components/menu';

// Create a context
const UserContext = createContext();

// Export a hook to use the context
export const useUserContext = () => useContext(UserContext);

const AdminLayout = ({ children }) => {
  const { userData } = useSelector((state) => state.user);

  return (
    <UserContext.Provider value={userData}>
      <div className='container'>
        <Menu />
        <Header />
        <main className='main'>{children}</main>
        <RightSideBar path={'api/stats/agent/sales'} />
        <UserProfile />
      </div>
    </UserContext.Provider>
  );
};

export default AdminLayout;
