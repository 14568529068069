/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";
import Dot from "img/Dot.svg";
import { Col, Row, Divider } from "antd";
import { useDispatch } from "react-redux";
import { setSelectedOffice } from "state/slice/viewOfficeAction"; // Adjust import path as needed

const OfficeCard = ({ endpoint }) => {
  const [offices, setOffices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App
  useEffect(() => {
    const fetchOffices = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${apiUrl}/api/offices/office-list`);
        setOffices(response.data);
      } catch (error) {
        console.error("Error fetching offices:", error);
        setError("Failed to load offices.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchOffices();
  }, [endpoint, apiUrl]);

  const handleViewClick = (office) => {
    dispatch(
      setSelectedOffice({
        officeId: office.officeId,
      })
    );
  };
  const handleImageError = (e) => {
    e.target.src = Dot; // Fallback image on error
  };

  if (isLoading) return <div>Loading offices...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Row
      gutter={[24, 24]}
      justify={"start"} /*className="office-cards-container"*/
    >
      {offices.map((office) => (
        <Col
          xs={12}
          sm={8}
          md={8}
          lg={6}
          xl={6}
          key={office.officeId}
          className="card-wrapper"
          style={{ marginLeft: "24px" }} // Add margin-bottom for additional vertical spacing
          onClick={() => handleViewClick(office)}>
          <div className="company-heading">
            <span>{office.officeName}</span>
            <span>{office.address}</span>
          </div>
          <div className="card-goal-progress">
            <img src={Dot} alt="Bullet list" />
            <span>Weekly Goal In progress</span>
          </div>
          <div class="progress-bar">
            <div class="progress" style={{ width: "0%" }}></div>
          </div>
          <div className="card-goal-stats">
            <div>
              <spann>0 </spann>{" "}
              <span style={{ color: "rgba(28, 28, 28, 0.20)" }}>/</span>{" "}
              <span>100 Total Sales</span>
            </div>
            <span>0%</span>
          </div>{" "}
          <div className="card-images">
            <div></div>
            <div>
              <img
                src={office.logoUrl}
                alt="Company logo"
                loading="lazy"
                onError={handleImageError}
              />
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default OfficeCard;
