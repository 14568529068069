/** @format */

import React from 'react';
import Skeleton from 'react-loading-skeleton';

const DashboardSkeleton = () => {
  return (
    <>
      <div className='stats-container'>
        <div className='rates'>
          <div className='pr-pl-rr-sp-wrp'>
            <div>
              <div className='rates-blue'>
                <Skeleton width={100} height={30} />
              </div>
              <div className='rates-light'>
                <Skeleton width={100} height={30} />
              </div>
              <div className='rates-light'>
                <Skeleton width={100} height={30} />
              </div>
              <div className='rates-light'>
                <Skeleton width={100} height={30} />
              </div>
            </div>
            <div>
              <div className='rates-light'>
                <Skeleton width={100} height={30} />
              </div>
              <div className='rates-light'>
                <Skeleton width={100} height={30} />
              </div>
              <div className='rates-light'>
                <Skeleton width={100} height={30} />
              </div>
              <div className='rates-light'>
                <Skeleton width={100} height={30} />
              </div>
            </div>
          </div>
          <div className='l-s-cr-wrp'>
            <div className='rates-purple'>
              <Skeleton width={100} height={30} />
            </div>
            <div className='rates-purple'>
              <Skeleton width={100} height={30} />
            </div>
            <div className='rates-purple'>
              <Skeleton width={100} height={30} />
            </div>
          </div>
        </div>
        <div className='chart-container'>
          <Skeleton height={200} />
        </div>
        <div className='pie-container'>
          <Skeleton height={200} />
        </div>
      </div>
    </>
  );
};

export default DashboardSkeleton;
