/** @format */

import axios from 'axios';
const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

export const searchCustomer = async (query) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(
      `${apiUrl}/api/super-admin/customer/search`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          query,
        },
      }
    );
    return response.data; // Return the filtered users
  } catch (error) {
    console.error('Error searching customer:', error);
    throw error;
  }
};

export const searchCustomerbyOfficeId = async (query) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(
      `${apiUrl}/api/super-admin/customer/office/search`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          query,
        },
      }
    );
    return response.data; // Return the filtered users
  } catch (error) {
    console.error('Error searching customer:', error);
    throw error;
  }
};

/**
 * Fetches customer data by their unique identifier (ID).
 *
 * @param {string} id - The unique identifier of the customer to be fetched.
 * @returns {Promise<Object>} - A promise that resolves to the customer data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getCustomerById = async (id) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(
      `${apiUrl}/api/super-admin/customer/${id}`, // API endpoint to fetch customer by ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Return the customer data
  } catch (error) {
    console.error('Error fetching customer by ID:', error);
    throw error;
  }
};

/**
 * Updates customer data by their unique identifier (ID).
 *
 * @param {string} id - The unique identifier of the customer to be updated.
 * @param {Object} updatedData - The updated customer data (e.g., updated fields, new ancillaries).
 * @returns {Promise<Object>} - A promise that resolves to the updated customer data.
 * @throws {Error} - Throws an error if the update fails.
 */
export const updateCustomerById = async (id, updatedData) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.put(
      `${apiUrl}/api/super-admin/customer/update/${id}`, // API endpoint to update customer by ID
      updatedData, // Updated customer data (e.g., adding ancillaries or updating fields)
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Return the updated customer data
  } catch (error) {
    console.error('Error updating customer by ID:', error);
    throw error;
  }
};
