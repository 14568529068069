import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBackOfficeType } from "state/slice/tabSlice";
import { toggleBackoffice } from "state/slice/tabSlice";
import Exit from "img/exit.svg";
import BatchNPNRegistrationForm from "./registerNPN";
import BatchAORegistrationForm from "./registerAOR";

const RegisterBackOffice = () => {
  const backOfficeType = useSelector((state) => state.activeTab.backOfficeType);
  const dispatch = useDispatch();
  const handlExit = () => {
    dispatch(toggleBackoffice(false)); // Corrected action dispatch
  };

  const handleBackOfficeType = (type) => {
    dispatch(setBackOfficeType(type));
  };
  return (
    <div className="form-layout">
      <div className="exit-form-wrapper">
        <button
          className="exit-form"
          style={{ float: "right", marginBottom: "30px" }}
          onClick={handlExit}>
          <img src={Exit} alt="Exit Button" style={{ cursor: "pointer" }} />
        </button>
      </div>
      <div className="form-container">
        {backOfficeType === "" && (
          <div>
            <button onClick={() => handleBackOfficeType("NPN")}>NPN</button>
            <button onClick={() => handleBackOfficeType("AOR")}>AOR</button>
          </div>
        )}

        {backOfficeType === "NPN" && <BatchNPNRegistrationForm />}
        {backOfficeType === "AOR" && <BatchAORegistrationForm />}
      </div>
    </div>
  );
};

export default RegisterBackOffice;
