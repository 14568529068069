/** @format */

import React, { useEffect, useState } from 'react';

import Exit from 'img/exit.svg';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { useDispatch, useSelector } from 'react-redux';
import U65AgentSalesChart from './u65AgentSaleChart';
import Envelope from 'img/EnvelopeSmallsvg.svg';
import ProfileIcon from 'img/defaultUser.jpg';
import { setUserProfile } from 'state/slice/buttonSlice';
import { getUser } from 'api/userRoutes';
import { getProfilePic } from 'api/routes';
import { getMonthlySummary } from 'api/statRoutes';
import { Row, Col } from 'antd';

import RightArrow from 'img/ArrowLineRightBlack.svg';
import Map from 'img/MapPin.svg';

const UserProfile = () => {
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();
  //console.log('profile Pic:', userData.totalprivateMed);
  //console.log('first Name:', userData);
  const userId = useSelector((state) => state.button.userId);
  //console.log(userId);
  useEffect(() => {
    if (!userId) {
      return;
    }
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getUser(userId);
        const userProfilePic = await getProfilePic(userId);
        const userSales = await getMonthlySummary(userId);
        setUserData({
          ...userProfile,
          profilePicUrl: userProfilePic ? userProfilePic.profilePicUrl : null,
          ...userSales,
        });
      } catch (error) {
        console.error('Error retrieving data', error);
      }
    };

    fetchUserProfile();
  }, [userId]);

  const getPercentage = (num, total) => {
    if (total === 0) {
      return 0; // Return 0 if the total is 0 to avoid division by zero
    }
    return (num / total) * 100;
  };

  console.log('fetch user', userId);
  return (
    <div>
      {userId && (
        <div className='office-profile-container'>
          <div className='profile-wrapper'>
            <Row gutter={[16, 16]} justify={'center'}>
              <Col lg={24}>
                {' '}
                <div className='exit-sb-pf'>
                  <span
                    onClick={() => dispatch(setUserProfile({ userId: null }))}>
                    Close
                  </span>
                  <img
                    src={Exit}
                    alt='Exit Button'
                    onClick={() => dispatch(setUserProfile({ userId: null }))}
                  />{' '}
                </div>
              </Col>
              <Col lg={24}>
                <div className='sb-profile-container'>
                  <img
                    className='  sb-pf-pic'
                    style={{ width: !userData.profilePicUrl && '158px' }}
                    src={userData.profilePicUrl || ProfileIcon}
                    alt='company logo'
                  />
                  <div className='user-sb-info'>
                    <div>
                      <span>
                        {capitalizeFirstLetter(userData.firstname) +
                          ' ' +
                          capitalizeFirstLetter(userData.lastname)}
                      </span>
                    </div>

                    <div>
                      <img src={Map} alt='Map Icon' />
                      <span>{userData.officeName}</span>
                    </div>
                    <div>
                      <img src={Envelope} alt='Phone Icon' />
                      <span>{userData.email}</span>
                    </div>
                  </div>
                </div>
              </Col>
              <div className='office-pf-info-wrp'>
                <div className='pf-goal-wrapper'>
                  <span>Goal completion</span>

                  <div
                    className='progress-bar'
                    style={{
                      height: '28px',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}>
                    <div
                      className='progress'
                      style={{
                        width: ` ${getPercentage(userData.totalSales, 100)}%`,
                        height: '100%',
                        paddingTop: '5px',
                      }}>
                      {getPercentage(userData.totalSales, 100)}%
                    </div>
                  </div>
                </div>
                <div className='profile-stats-table'>
                  <table>
                    <thead>
                      <tr>
                        <th>ACA</th>
                        <th>U65</th>
                        <th style={{ borderRight: 'none' }}>Premium</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{userData.totalMajorMed}</td>
                        <td>{userData.totalprivateMed}</td>
                        <td style={{ borderRight: 'none' }}>
                          $
                          {(
                            userData.totalAncillariesPremium +
                            userData.totalPrivateMedPremium
                          ).toFixed(2)}{' '}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <Col lg={24}>
                <div
                  className='chart-container'
                  style={{ background: '#F7F9FB' }}>
                  <span>Weekly Sales Activity</span>
                  <U65AgentSalesChart
                    userId={userId}
                    style={{
                      padding: '0px',
                      height: '136px',
                      minWidth: '100%',
                    }}
                  />
                </div>
              </Col>
              <Col lg={24}>
                <div className='agent-pfrm-link-wpr'>
                  <h2>Agent Performance</h2>
                  <div>
                    <span>Open Dashboard</span>
                    <img src={RightArrow} alt='right arrow icon' />
                  </div>
                </div>
              </Col>
            </Row>{' '}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
