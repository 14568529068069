/** @format */
import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip
);

const options = {
  type: "line",
  responsive: true,
  interaction: {
    mode: "index", // Display the tooltip for all items at this index
    intersect: false, // Tooltip displays when hovering anywhere on the axis, not necessarily over an element
  },
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Weekly Premium Comparison",
    },
  },

  elements: {
    line: {
      tension: 0.4, // Adjusts the curve tension of all lines
    },
  },

  scales: {
    x: {
      grid: {
        display: false, // No vertical grid lines
      },
      border: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true, // No horizontal grid lines
      },

      border: {
        display: false,
      },
    },
  },
};

const PremiumsChart = ({ endpoint }) => {
  const [chartData, setChartData] = useState({
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    datasets: [
      {
        label: "This Week",
        data: [],
        borderColor: "rgb(151, 166, 252)",
        backgroundColor:
          "radial-gradient(49.06% 54.41% at 50% 18.37%, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.00) 100%);", // Set the background color
        fill: true, // Fill the area under the line
      },
      {
        label: "Previous Week",
        data: [],
        borderColor: "#A1E3CB",
        borderDash: [5, 5], // Creates a dotted line
        backgroundColor: "rgba(255, 99, 132, 0.1)", // Ensure there's no fill for the previous week
        fill: false, // No fill for the previous week line
      },
    ],
  });
  const [currentWeekTotal, setCurrentWeekTotal] = useState(0);
  const [previousWeekTotal, setPreviousWeekTotal] = useState(0);
  const chartRef = useRef(null);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(`${apiUrl}/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const salesData = response.data.map((day) => day.totalPremium);
        const previousSalesData = response.data.map(
          (day) => day.previousTotalPremium
        );
        const currentWeekTotal = salesData.reduce(
          (acc, value) => acc + value,
          0
        );
        const previousWeekTotal = previousSalesData.reduce(
          (acc, value) => acc + value,
          0
        );
        const chart = chartRef.current;

        if (chart) {
          const ctx = chart.ctx;
          const gradient = ctx.createRadialGradient(
            ctx.canvas.width / 2,
            ctx.canvas.height / 2,
            0,
            ctx.canvas.width / 2,
            ctx.canvas.height / 2,
            Math.max(ctx.canvas.width, ctx.canvas.height) / 2
          );
          gradient.addColorStop(0, "rgba(0, 0, 0, 0.08)");
          gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

          setCurrentWeekTotal(currentWeekTotal);
          setPreviousWeekTotal(previousWeekTotal);

          setChartData((prevData) => ({
            ...prevData,
            datasets: [
              {
                ...prevData.datasets[0],
                data: salesData,
                backgroundColor: gradient,
              },
              {
                ...prevData.datasets[1],
                data: previousSalesData,
              },
            ],
          }));
        }
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };
    fetchData();
  }, [apiUrl, endpoint]);

  return (
    <div className="premium-chart-container">
      <div className="premiumm-chart-header ">
        <span className="heading">Premium</span>
        <span style={{ color: "rgba(28, 28, 28, 0.20)" }}>|</span>
        <div className="legend-wrapper">
          <div className="current-week"></div>
          <div>
            <span>Current Week </span>
            <span style={{ fontSize: "12px", fontWeight: "600" }}>
              ${currentWeekTotal.toLocaleString()}
            </span>
          </div>
        </div>
        <div className="legend-wrapper">
          <div className="previous-week"></div>
          <div>
            <span>Previous Week</span>
            <span style={{ fontSize: "12px", fontWeight: "600" }}>
              {" "}
              ${previousWeekTotal.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
      <Line
        ref={chartRef}
        width={892}
        height={273}
        data={chartData}
        options={options}
      />
    </div>
  );
};

export default PremiumsChart;
