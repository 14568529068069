/** @format */

import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ListedLeadsRoutes from 'api/listedLeadRoutes'; // Your API utility

const ListedLeads = ({ path }) => {
  const [listedLeads, setListedLeads] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [editId, setEditId] = useState(null);
  const [editedLog, setEditedLog] = useState({});

  // Fetch data when component mounts
  useEffect(() => {
    const fetchListedLeads = async () => {
      try {
        const data = await ListedLeadsRoutes.getListedLeads(path);
        setListedLeads(data);
      } catch (error) {
        console.error('Error fetching listed lead:', error);
      }
    };
    fetchListedLeads();
  }, [path]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
  };

  const handleEdit = (id) => {
    setEditId(id);
    const logToEdit = listedLeads.find((lead) => lead._id === id);
    setEditedLog({ ...logToEdit });
  };

  const handleSave = async () => {
    try {
      const updatedLead = await ListedLeadsRoutes.updateListedLeadById(
        editId,
        editedLog
      );
      setListedLeads((prevLeads) =>
        prevLeads.map((lead) => (lead._id === editId ? updatedLead : lead))
      );
      setEditId(null);
      setEditedLog({});
    } catch (error) {
      console.error('Error saving edited lead:', error);
    }
  };

  const handleCancel = () => {
    setEditId(null);
    setEditedLog({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedLog((prevLog) => ({ ...prevLog, [name]: value }));
  };

  const handleDelete = async (id) => {
    try {
      await ListedLeadsRoutes.deleteListedLeadById(id);
      setListedLeads((prevLeads) =>
        prevLeads.filter((lead) => lead._id !== id)
      );
    } catch (error) {
      console.error('Error deleting listed lead:', error);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90 }}>
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
  });

  const columns = [
    {
      title: 'List ID',
      dataIndex: 'listId',
      key: 'listId',
      sorter: (a, b) => a.listId.localeCompare(b.listId),
      ...getColumnSearchProps('listId'),
      render: (text, record) =>
        editId === record._id ? (
          <Input
            name='listId'
            value={editedLog.listId}
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'List Name',
      dataIndex: 'listName',
      key: 'listName',
      sorter: (a, b) => a.listName.localeCompare(b.listName),
      ...getColumnSearchProps('listName'),
      render: (text, record) =>
        editId === record._id ? (
          <Input
            name='listName'
            value={editedLog.listName}
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Office ID',
      dataIndex: 'officeId',
      key: 'officeId',
      render: (officeId) => (officeId ? officeId : 'N/A'),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text) => {
        if (!text) return 'N/A';
        try {
          const utcDate = new Date(text);
          if (isNaN(utcDate.getTime())) {
            return 'Invalid Date';
          }
          const correctedTime = new Date(
            utcDate.getTime() + 7 * 60 * 60 * 1000
          );
          return correctedTime.toLocaleString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          });
        } catch (error) {
          return 'Error';
        }
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) =>
        editId === record._id ? (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button type='primary' onClick={handleSave}>
              Save
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </div>
        ) : (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button type='link' onClick={() => handleEdit(record._id)}>
              Edit
            </Button>
            <Popconfirm
              title='Are you sure to delete this lead?'
              onConfirm={() => handleDelete(record._id)}
              okText='Yes'
              cancelText='No'>
              <Button type='link' danger>
                Delete
              </Button>
            </Popconfirm>
          </div>
        ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={listedLeads}
      rowKey={(record) => record._id}
      pagination={{ pageSize: 10 }}
    />
  );
};

export default ListedLeads;
