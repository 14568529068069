import { createSlice } from "@reduxjs/toolkit";

const usersOfficeId = createSlice({
  name: "usersOfficeId",
  initialState: {
    value: "",
  },

  reducers: {
    setUsersOfficeId: (state, action) => {
      state.value = action.payload.usersOfficeId;
    },
  },
});

export const { setUsersOfficeId } = usersOfficeId.actions;

///Selector
export const user = (state) => state.usersOfficeId.value;

export default usersOfficeId.reducer;
