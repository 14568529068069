/** @format */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SignOut from 'img/SignOut.svg';
const Logout = () => {
  const isMenuOpen = useSelector((state) => state.sidebar.menuCollapse);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the authentication tokens
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('refreshToken');

    // Redirect to the sign-in page
    navigate('/signin');
  };

  return (
    <li
      style={{
        alignItems: isMenuOpen ? '' : 'center',
        width: isMenuOpen ? '' : '44px',
      }}
      onClick={handleLogout}>
      <NavLink
        className={`nav-link `}
        style={{
          justifyContent: isMenuOpen ? 'normal' : 'center',
        }}>
        <img src={SignOut} alt='log out button' />{' '}
        {isMenuOpen && <span>Log Out</span>}
      </NavLink>
    </li>
  );
};

export default Logout;
