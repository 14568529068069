/** @format */
import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { message, Button, Upload, Modal, Slider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { accessToken } from 'utils/accessToken';
import Cropper from 'react-easy-crop';
import getCroppedImg from 'utils/getCroppedImg'; // Utility to crop the image and convert to blob

const ProfilePictureUpload = ({ userId, onUploadSuccess }) => {
  const [uploading, setUploading] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleUpload = async (croppedBlob) => {
    const formData = new FormData();
    formData.append('file', croppedBlob);
    formData.append('userId', userId);

    try {
      const token = localStorage.getItem('accessToken');
      setUploading(true);
      const response = await axios.post(
        `${apiUrl}/api/settings/profile-picture/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        message.success('Profile picture uploaded successfully');
        if (onUploadSuccess) {
          onUploadSuccess(response.data.profilePic); // Pass back the profilePic data if needed
        }
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      message.error('Failed to upload profile picture');
    } finally {
      setUploading(false);
      setModalVisible(false); // Hide the modal after upload
    }
  };

  const onFileChange = async ({ file }) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImageSrc(reader.result); // Set the image source for cropping
      setModalVisible(true); // Open the modal to crop the image
    });
    reader.readAsDataURL(file);
  };

  const handleCropSave = async () => {
    try {
      const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      await handleUpload(croppedBlob); // Pass the cropped image blob to the upload function
    } catch (e) {
      console.error(e);
      message.error('Failed to crop the image');
    }
  };

  return (
    <div>
      <Upload
        name='profilePic'
        customRequest={onFileChange}
        showUploadList={false}
        accept='image/*'>
        <Button icon={<UploadOutlined />} loading={uploading}>
          {uploading ? 'Uploading' : 'Upload Profile Picture'}
        </Button>
      </Upload>

      {/* Cropping modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleCropSave}
        okText='Save'
        title='Crop your image'>
        {imageSrc && (
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: 400,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {/* Cropper */}
            <div style={{ position: 'relative', width: '80%', height: 400 }}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1} // Use square crop, as the image will later be masked as a circle
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />

              {/* Circular overlay */}
              <div
                style={{
                  borderRadius: '50%',
                  border: '2px dashed #fff',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  width: '100%',
                  height: '100%',
                  transform: 'translate(-50%, -50%)',
                  pointerEvents: 'none',
                }}
              />
            </div>

            {/* Zoom slider */}
            <Slider
              vertical
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={setZoom}
              style={{ marginLeft: 20, height: 200 }}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ProfilePictureUpload;
