/** @format */

import React, { useState } from 'react';
import axios from 'axios';
import Exit from 'img/exit.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { toggleListedLeadForm } from 'state/slice/tabSlice';

const AddListedLeads = () => {
  const [listedLeads, setListedLeads] = useState([
    { listId: '', listName: '' },
  ]);
  const dispatch = useDispatch();
  const selectedOfficeId = useSelector((state) => state.dropDown.officeId); // Accessing officeId from Redux store
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedLeads = [...listedLeads];
    updatedLeads[index][name] = value;
    setListedLeads(updatedLeads);
  };

  const handleAddLead = () => {
    setListedLeads([...listedLeads, { listId: '', listName: '' }]);
  };

  const handleRemoveLead = (index) => {
    const updatedLeads = listedLeads.filter((_, i) => i !== index);
    setListedLeads(updatedLeads);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('accessToken'); // Assuming the token is stored in local storage
      const response = await axios.post(
        `${apiUrl}/api/leads/listed-leads`,
        listedLeads.map((lead) => ({
          ...lead,
          officeId: selectedOfficeId, // Attach the selected officeId to each lead
        })),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Success case
      toast.success(
        ` Listed Leads added successfully ${response.data.message}`
      );
      setListedLeads([{ listId: '', listName: '' }]); // Reset form
    } catch (error) {
      console.error('Error during listed leads addition:', error);

      if (error.response && error.response.data) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      } else {
        toast.error('An unexpected error occurred during addition');
      }
    }
  };

  const handleExit = () => {
    dispatch(toggleListedLeadForm(false));
  };

  return (
    <div className='form-layout'>
      <div className='exit-form-wrapper' style={{ width: '880px' }}>
        <button
          className='exit-form'
          style={{ float: 'right', marginBottom: '30px' }}
          onClick={handleExit}>
          <img src={Exit} alt='Exit Button' style={{ cursor: 'pointer' }} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className='vendors-form-conainer'>
        <h2>Add New Listed Leads</h2>
        <div className='vendors-form-wrapper'>
          {listedLeads.map((lead, index) => (
            <div
              className='vendors-input-container'
              style={{ justifyContent: 'center' }}
              key={index}>
              <input
                type='text'
                name='listId'
                placeholder='List ID'
                value={lead.listId}
                onChange={(e) => handleChange(index, e)}
                required
              />
              <input
                type='text'
                name='listName'
                placeholder='List Name'
                value={lead.listName}
                onChange={(e) => handleChange(index, e)}
                required
              />
              <button type='button' onClick={() => handleRemoveLead(index)}>
                Remove
              </button>
            </div>
          ))}
        </div>
        <div className='vendors-form-button-conainer'>
          <button type='button' onClick={handleAddLead}>
            Add Listed Lead
          </button>
          <button type='submit'>Submit</button>
        </div>
      </form>
    </div>
  );
};

export default AddListedLeads;
