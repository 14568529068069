/** @format */

// utils/formatCurrency.js

/**
 * Formats a number into currency format with commas and two decimal places.
 * @param {number} value - The number to be formatted.
 * @returns {string} - The formatted number with commas and two decimal places.
 */
const formatCurrency = (value) => {
  if (isNaN(value)) return '$0.00';
  return `$${value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

module.exports = formatCurrency;
