/** @format */
/**
 * # this component is to list all of the forms, by office type + officeID for the super admin page .
 * # Author: Claude Lamarre
 */
import React, { useState, useEffect } from 'react';
import {
  Table,
  Input,
  DatePicker,
  Select,
  Button,
  Popconfirm,
  message,
  Tag,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getFormDatas, getVendors, deleteForm, updateForm } from 'api/routes';
import { useSelector } from 'react-redux';

const AdminFormTable = ({ endpoint, endpoint2, setFormDatas }) => {
  const [error, setError] = useState(null); // Error handling
  const [vendors, setVendors] = useState([]); // Vendors state
  const [formDatasLocal, setFormsDatasLocal] = useState([]); // Local form data state
  const [editRowId, setEditRowId] = useState(null); // Track the row being edited
  const [editingInsuranceKey, setEditingInsuranceKey] = useState(null); // Track the insurance record being edited
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formDataResponse = await getFormDatas(
          endpoint,
          startDate,
          endDate
        );
        setFormsDatasLocal(formDataResponse);
        setFormDatas(formDataResponse); // Update parent state

        const vendorsResponse = await getVendors(endpoint2);
        setVendors(vendorsResponse);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      }
    };

    fetchData();
  }, [endpoint, endpoint2, startDate, endDate, setFormDatas]);

  const handleDelete = async (id) => {
    try {
      await deleteForm(id); // Call delete API
      setFormsDatasLocal((prev) => prev.filter((form) => form._id !== id));
      setFormDatas((prev) => prev.filter((form) => form._id !== id)); // Update parent state
      message.success('Form deleted successfully');
    } catch (error) {
      console.error('Error deleting form:', error);
      message.error('Failed to delete form');
    }
  };

  const handleChange = (e, recordId) => {
    const { name, value } = e.target;
    setFormsDatasLocal((prev) =>
      prev.map((item) =>
        item._id === recordId ? { ...item, [name]: value } : item
      )
    );
  };

  const handleSelectChange = (value, name, recordId) => {
    setFormsDatasLocal((prev) =>
      prev.map((item) =>
        item._id === recordId ? { ...item, [name]: value } : item
      )
    );
  };

  const handleInsuranceChange = (e, recordId, insuranceKey, fieldName) => {
    const { value } = e.target;
    setFormsDatasLocal((prevForms) =>
      prevForms.map((item) => {
        if (item._id === recordId) {
          const updatedItem = { ...item };
          if (insuranceKey.includes('-majorMed')) {
            updatedItem.majorMed = {
              ...updatedItem.majorMed,
              [fieldName]: value,
            };
          } else if (insuranceKey.includes('-privateMed')) {
            updatedItem.privateMed = {
              ...updatedItem.privateMed,
              [fieldName]: value,
            };
          } else if (insuranceKey.includes('-ancillary-')) {
            const index = parseInt(insuranceKey.split('-ancillary-')[1], 10);
            updatedItem.ancillaries = [...updatedItem.ancillaries];
            updatedItem.ancillaries[index] = {
              ...updatedItem.ancillaries[index],
              [fieldName]: value,
            };
          }
          return updatedItem;
        }
        return item;
      })
    );
  };

  const handleInsuranceDateChange = (
    date,
    dateString,
    recordId,
    insuranceKey,
    fieldName
  ) => {
    setFormsDatasLocal((prevForms) =>
      prevForms.map((item) => {
        if (item._id === recordId) {
          const updatedItem = { ...item };
          if (insuranceKey.includes('-majorMed')) {
            updatedItem.majorMed = {
              ...updatedItem.majorMed,
              [fieldName]: dateString,
            };
          } else if (insuranceKey.includes('-privateMed')) {
            updatedItem.privateMed = {
              ...updatedItem.privateMed,
              [fieldName]: dateString,
            };
          } else if (insuranceKey.includes('-ancillary-')) {
            const index = parseInt(insuranceKey.split('-ancillary-')[1], 10);
            updatedItem.ancillaries = [...updatedItem.ancillaries];
            updatedItem.ancillaries[index] = {
              ...updatedItem.ancillaries[index],
              [fieldName]: dateString,
            };
          }
          return updatedItem;
        }
        return item;
      })
    );
  };

  const handleInsuranceSelectChange = (
    value,
    recordId,
    insuranceKey,
    fieldName
  ) => {
    setFormsDatasLocal((prevForms) =>
      prevForms.map((item) => {
        if (item._id === recordId) {
          const updatedItem = { ...item };
          if (insuranceKey.includes('-majorMed')) {
            updatedItem.majorMed = {
              ...updatedItem.majorMed,
              [fieldName]: value,
            };
          } else if (insuranceKey.includes('-privateMed')) {
            updatedItem.privateMed = {
              ...updatedItem.privateMed,
              [fieldName]: value,
            };
          } else if (insuranceKey.includes('-ancillary-')) {
            const index = parseInt(insuranceKey.split('-ancillary-')[1], 10);
            updatedItem.ancillaries = [...updatedItem.ancillaries];
            updatedItem.ancillaries[index] = {
              ...updatedItem.ancillaries[index],
              [fieldName]: value,
            };
          }
          return updatedItem;
        }
        return item;
      })
    );
  };

  const handleInsuranceUpdate = async (recordId, insuranceRecord) => {
    try {
      // Build the update data
      let updateData = {};

      if (insuranceRecord.key.includes('-majorMed')) {
        updateData = { majorMed: insuranceRecord };
      } else if (insuranceRecord.key.includes('-privateMed')) {
        updateData = { privateMed: insuranceRecord };
      } else if (insuranceRecord.key.includes('-ancillary-')) {
        const index = parseInt(insuranceRecord.key.split('-ancillary-')[1], 10);
        // Need to get the current ancillaries array and update the specific one
        const currentItem = formDatasLocal.find(
          (item) => item._id === recordId
        );
        const updatedAncillaries = [...currentItem.ancillaries];
        updatedAncillaries[index] = insuranceRecord;
        updateData = { ancillaries: updatedAncillaries };
      }

      // Update the specific insurance record in your data source
      await updateForm(recordId, updateData); // Adjust as needed
      message.success('Insurance record updated successfully');

      // Update the local state
      setFormsDatasLocal((prevForms) =>
        prevForms.map((item) => {
          if (item._id === recordId) {
            return { ...item, ...updateData };
          }
          return item;
        })
      );

      setEditingInsuranceKey(null); // Exit edit mode
    } catch (error) {
      console.error('Error updating insurance record:', error);
      message.error('Failed to update insurance record');
    }
  };
  const handleUpdate = async (recordId, updatedData) => {
    try {
      await updateForm(recordId, updatedData); // Call update API
      message.success('Form updated successfully');
      setFormsDatasLocal((prevForms) =>
        prevForms.map((item) =>
          item._id === recordId ? { ...item, ...updatedData } : item
        )
      );
      setEditRowId(null); // Exit edit mode after updating
    } catch (error) {
      console.error('Error updating form:', error);
      message.error('Failed to update form');
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90 }}>
          Search
        </Button>
        <Button onClick={clearFilters} size='small' style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
  });

  const dateFormat = (date) => {
    if (!date) return '';
    const dateObj = new Date(date);
    if (isNaN(dateObj)) return '';
    const localDate = new Date(
      dateObj.getTime() + dateObj.getTimezoneOffset() * 60000
    );
    return !isNaN(localDate)
      ? localDate.toLocaleDateString('en-US')
      : 'Invalid date';
  };

  const expandedRowRender = (record) => {
    const insuranceData = [];

    if (record.majorMed) {
      insuranceData.push({
        key: record._id + '-majorMed',
        parentId: record._id,
        type: 'Major Med',
        carrier: record.majorMed.carrier,
        premium: record.majorMed.premium,
        billingDate: record.majorMed.billingDate,
        status: record.majorMed.status,
      });
    }

    if (record.privateMed) {
      insuranceData.push({
        key: record._id + '-privateMed',
        parentId: record._id,
        type: 'Private Med',
        carrier: record.privateMed.carrier,
        premium: record.privateMed.premium,
        billingDate: record.privateMed.billingDate,
        status: record.privateMed.status,
      });
    }

    if (record.ancillaries) {
      record.ancillaries.forEach((ancillary, index) => {
        insuranceData.push({
          key: record._id + `-ancillary-${index}`,
          parentId: record._id,
          type: `Ancillary ${index + 1}`,
          carrier: ancillary.carrier,
          premium: ancillary.premium,
          billingDate: ancillary.billingDate,
          status: ancillary.status,
        });
      });
    }

    const expandedColumns = [
      { title: 'Type', dataIndex: 'type', key: 'type' },
      { title: 'Carrier', dataIndex: 'carrier', key: 'carrier' },
      {
        title: 'Premium',
        dataIndex: 'premium',
        key: 'premium',
        render: (text, insuranceRecord) =>
          editingInsuranceKey === insuranceRecord.key ? (
            <Input
              name='premium'
              value={insuranceRecord.premium || ''}
              onChange={(e) =>
                handleInsuranceChange(
                  e,
                  insuranceRecord.parentId,
                  insuranceRecord.key,
                  'premium'
                )
              }
              placeholder='Premium'
            />
          ) : (
            insuranceRecord.premium
          ),
      },
      {
        title: 'Billing Date',
        dataIndex: 'billingDate',
        key: 'billingDate',
        render: (text, insuranceRecord) =>
          editingInsuranceKey === insuranceRecord.key ? (
            <DatePicker
              value={
                insuranceRecord.billingDate
                  ? moment(insuranceRecord.billingDate)
                  : null
              }
              format='MM/DD/YYYY'
              onChange={(date, dateString) =>
                handleInsuranceDateChange(
                  date,
                  dateString,
                  insuranceRecord.parentId,
                  insuranceRecord.key,
                  'billingDate'
                )
              }
            />
          ) : insuranceRecord.billingDate ? (
            dateFormat(insuranceRecord.billingDate)
          ) : null,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, insuranceRecord) =>
          editingInsuranceKey === insuranceRecord.key ? (
            <Select
              value={insuranceRecord.status || ''}
              onChange={(value) =>
                handleInsuranceSelectChange(
                  value,
                  insuranceRecord.parentId,
                  insuranceRecord.key,
                  'status'
                )
              }>
              <Select.Option value='Paid'>Paid</Select.Option>
              <Select.Option value='Cancel'>Cancel</Select.Option>
              <Select.Option value='Pending'>Pending</Select.Option>
              <Select.Option value='Declined'>Declined</Select.Option>
            </Select>
          ) : (
            <Tag
              color={
                insuranceRecord.status === 'Paid'
                  ? 'green'
                  : insuranceRecord.status === 'Cancel'
                  ? 'red'
                  : insuranceRecord.status === 'Pending'
                  ? 'orange'
                  : insuranceRecord.status === 'Declined'
                  ? 'volcano'
                  : ''
              }>
              {insuranceRecord.status}
            </Tag>
          ),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, insuranceRecord) =>
          editingInsuranceKey === insuranceRecord.key ? (
            <Button
              type='primary'
              onClick={() =>
                handleInsuranceUpdate(insuranceRecord.parentId, insuranceRecord)
              }>
              Save
            </Button>
          ) : (
            <Button onClick={() => setEditingInsuranceKey(insuranceRecord.key)}>
              Edit
            </Button>
          ),
      },
    ];

    return (
      <Table
        columns={expandedColumns}
        dataSource={insuranceData}
        pagination={false}
        rowKey={(insuranceRecord) => insuranceRecord.key}
      />
    );
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...getColumnSearchProps('firstName'),
      render: (text, record) =>
        editRowId === record._id ? (
          <Input
            value={text}
            name='firstName'
            onChange={(e) => handleChange(e, record._id)}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...getColumnSearchProps('lastName'),
      render: (text, record) =>
        editRowId === record._id ? (
          <Input
            value={text}
            name='lastName'
            onChange={(e) => handleChange(e, record._id)}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      ...getColumnSearchProps('phoneNumber'),
      render: (text, record) =>
        editRowId === record._id ? (
          <Input
            value={text}
            name='phoneNumber'
            onChange={(e) => handleChange(e, record._id)}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      key: 'vendorName',
      ...getColumnSearchProps('vendorName'),
      render: (text, record) =>
        editRowId === record._id ? (
          <Select
            showSearch
            value={record.vendorName}
            placeholder='Select a vendor'
            optionFilterProp='children'
            onChange={(value) =>
              handleSelectChange(value, 'vendorName', record._id)
            }
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: 200 }}>
            {vendors.map((vendor) => (
              <Select.Option key={vendor._id} value={vendor.vendorName}>
                {vendor.vendorName}
              </Select.Option>
            ))}
          </Select>
        ) : (
          text
        ),
    },
    {
      title: 'FFMID',
      dataIndex: 'FFMID',
      key: 'FFMID',
      ...getColumnSearchProps('FFMID'),
      render: (text, record) =>
        editRowId === record._id ? (
          <Input
            value={text}
            name='FFMID'
            onChange={(e) => handleChange(e, record._id)}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Member ID',
      dataIndex: 'memberId',
      key: 'memberId',
      ...getColumnSearchProps('memberId'),
      render: (text, record) =>
        editRowId === record._id ? (
          <Input
            value={text}
            name='memberId'
            onChange={(e) => handleChange(e, record._id)}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentName',
      key: 'agentName',
      ...getColumnSearchProps('agentName'),
      render: (text, record) =>
        editRowId === record._id ? (
          <Input
            value={text}
            name='agentName'
            onChange={(e) => handleChange(e, record._id)}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) =>
        editRowId === record._id ? (
          <Button
            type='primary'
            onClick={() => handleUpdate(record._id, record)}>
            Save
          </Button>
        ) : (
          <div>
            <Button onClick={() => setEditRowId(record._id)}>Edit</Button>
            <Popconfirm
              title='Are you sure you want to delete this form?'
              onConfirm={() => handleDelete(record._id)}
              okText='Yes'
              cancelText='No'>
              <Button type='danger' style={{ marginLeft: '8px' }}>
                Delete
              </Button>
            </Popconfirm>
          </div>
        ),
    },
  ];

  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender, // Render the nested table when expanded
        }}
        dataSource={formDatasLocal}
        rowKey={(record) => record._id}
        pagination={{
          current: currentPage,
          pageSize: pageSize, // Controlled by a state variable for dynamic page size
          pageSizeOptions: ['10', '20', '50', '100'], // Options for selectable page sizes
          showSizeChanger: true, // Enables the page size dropdown
          onShowSizeChange: (current, size) => setPageSize(size), // Updates page size
          onChange: (page) => setCurrentPage(page),
        }}
      />
    </div>
  );
};

export default AdminFormTable;
