/** @format */

import axios from 'axios';
import { accessToken } from 'utils/accessToken';
const token = localStorage.getItem('accessToken'); // Assuming you are storing the auth token in localStorage
const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

export const getGlobalVendors = async () => {
  try {
    const response = await axios.get(
      `${apiUrl}/vendors/global-list`,
      accessToken
    );
    return response.data;
  } catch (error) {
    console.error('Error getting global names list:', error);
  }
};
