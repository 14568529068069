/** @format */

import React from 'react';

import { useState } from 'react';
import Filter from 'img/ArrowsDownUp.svg';
import AgentLayout from 'components/agents/layout';
import Search from 'img/Search.svg';
import U65Podium from 'components/u65Podium';
import U65AgentStatsTable from 'components/agents/widgets/agentU65StatsTable';
import FiveDayLeaderBoard from 'components/admin/widgets/fiveDayLeaderboard';

const ACALeaderboard = () => {
  const [activeTab, setActiveTab] = useState('Leaderboard');

  const handleTab = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '28px',
      }}>
      <div className='campaign-header-containger'>
        <div className='leaderboard-tab-wrapper'>
          <span
            onClick={() => handleTab('Leaderboard')}
            className={activeTab === 'Leaderboard' ? 'active' : ''}>
            Agent leaderboard
          </span>
          <span
            onClick={() => handleTab('5Day')}
            className={activeTab === '5Day' ? 'active' : ''}>
            Weekly Leaderboard
          </span>
        </div>
      </div>
      {activeTab === 'Leaderboard' && (
        <div>
          {' '}
          <U65Podium path={'api/stats/sales-table'} />
          <div style={{ marginTop: '20px' }}>
            <div className='filter-container'>
              <div className='filter'>
                <img src={Filter} alt='filer icon' />
              </div>

              <div className='search'>
                <img src={Search} alt='search icon' />
                <input />
              </div>
            </div>

            <U65AgentStatsTable />
          </div>
        </div>
      )}
      {activeTab === '5Day' && <FiveDayLeaderBoard />}
    </div>
  );
};

const AgentLeaderboard = () => {
  return (
    <AgentLayout>
      <ACALeaderboard />
    </AgentLayout>
  );
};

export default AgentLeaderboard;
