/** @format */

import React, { useEffect, useState } from 'react';
import { getQAreport } from 'api/qaRoutes';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import GradeCard from './widgets/grade';
import { calculateGrade } from 'utils/calculateGrade';
import DoughnutChart from './widgets/QADoughnutChart';
import { setReportId } from 'state/slice/dropDownSlice';
import backArrow from 'img/ArrowLeft.svg';
const QAReport = () => {
  const [report, setReport] = useState(null);
  const reportId = useSelector((state) => state.dropDown.reportId);
  console.log('heres the report id', report);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchReports = async () => {
      try {
        const reportData = await getQAreport(reportId);
        setReport(reportData);
      } catch (error) {
        console.error('Error fetching user reports:', error);
      }
    };
    if (reportId) {
      fetchReports();
    }
  }, [reportId]);
  const handleReportView = (id) => {
    dispatch(setReportId(id));
    console.log(id);
    // Additional logic for form view if needed
  };
  // Ensure `report` and `report.pointDeduction` are defined before calling `calculateGrade`
  const gradeResults = report ? calculateGrade(report.pointDeduction) : null;

  console.log(gradeResults);
  return (
    <div>
      <button
        className='white-back-button'
        style={{ marginBottom: '20px' }}
        onClick={() => handleReportView(null)}>
        <img src={backArrow} alt='Back Arrow' />
        <span>Back</span>
      </button>
      {report && gradeResults && (
        <Row gutter={[28, 28]}>
          <Col md={6} lg={6}>
            <GradeCard grade={gradeResults} />
          </Col>
          <Col lg={6}>
            <DoughnutChart
              points={gradeResults.points}
              color={gradeResults.grade.color}
            />
          </Col>
          <Col lg={6}>
            <div className='deduction-report'>
              {report.pointDeduction.map((point, index) => (
                <div>
                  <span style={{ color: 'red' }}>-{point.percentage}%</span>
                  <span key={index}>{point.faultType}</span>
                </div>
              ))}
            </div>
          </Col>
          <Col lg={6}>
            <div className='deduction-report'>
              <div className='audio-player'>
                <audio controls>
                  <source src={report.recordingURL} type='audio/mpeg' />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {report && report.questions && (
        <Row gutter={[12, 12]}>
          {report.questions.map((q, index) => (
            <Col lg={24} key={index}>
              <div
                className='form-title'
                style={{
                  margin: '20px 0',
                  fontWeight: 'bold',
                  color: 'white',
                }}>
                <h3>{q.question}</h3>
              </div>
              <Row gutter={[10, 10]}>
                <Col lg={12}>
                  <span>Answer:</span>
                  <div
                    className='form-heading'
                    style={{
                      margin: '20px 0',
                      fontWeight: 'bold',
                      color: 'white',
                    }}>
                    <span>{q.answer}</span>
                  </div>
                </Col>
                <Col lg={12}>
                  <span>Time Stamp:</span>
                  <div
                    className='form-heading'
                    style={{
                      margin: '20px 0',
                      fontWeight: 'bold',
                    }}>
                    <span>{q.timeStamp || 'none'}</span>
                  </div>
                </Col>
                <Col lg={24}>
                  <span>Notes:</span>
                  <div
                    className='form-heading'
                    style={{
                      margin: '20px 0',
                      fontWeight: 'bold',
                      color: 'white',
                    }}>
                    <span>{q.notes}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default QAReport;
