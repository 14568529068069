/** @format */

import { React } from 'react';
import sidebar from 'img/sidebar.png';
import notification from 'img/bell.png';
import { useDispatch, useSelector } from 'react-redux';
import Date from 'components/datePicker';
import { useLocation } from 'react-router-dom';
import TimeRangePicker from './timeRangePicker';
import { toggleNotification, toggleMenu } from 'state/slice/sidebar';

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = '/admin/leaderboard';

  const showDate = useSelector((state) => state.activeTab.showDate);
  console.log(showDate);

  // Condition to check if Date should be shown
  const shouldDisplayDatePicker = !(
    location.pathname === path && showDate === false
  );

  return (
    <header className='header'>
      <div className='right-heading-div'>
        <img
          src={sidebar}
          alt='Toggle side menu'
          onClick={() => dispatch(toggleMenu())}
        />
        <span>Dashboard</span>
      </div>
      {/* Conditional rendering of the Date component */}
      {shouldDisplayDatePicker && (
        <div className='date-filter-container'>
          <p>Filter By</p>
          <Date />
        </div>
      )}
      <div className='left-heading-div'>
        <img src={notification} alt='bell icon' />
        <img
          src={sidebar}
          alt='Toggle side bar'
          onClick={() => dispatch(toggleNotification())}
        />
      </div>
    </header>
  );
};

export default Header;
