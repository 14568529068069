/** @format */
import React from 'react';
import { Table, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setUserId } from 'state/slice/dropDownSlice'; // Import your action creator
import { setUserProfile } from 'state/slice/buttonSlice';

const UserTable = ({ users, loading }) => {
  const dispatch = useDispatch();

  // Capitalize the first letter of a name
  const capitalizeName = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  // Handle menu click for user actions
  const handleMenuClick = (user) => {
    dispatch(setUserId({ userId: user._id }));
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text) => capitalizeName(text),
      sorter: (a, b) => a.firstname.localeCompare(b.firstname),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname',
      render: (text) => capitalizeName(text),
      sorter: (a, b) => a.lastname.localeCompare(b.lastname),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Agency',
      key: 'officeName',
      sorter: (a, b) =>
        a.officeId.officeName.localeCompare(b.officeId.officeName),
      render: (record) => record.officeId?.officeName || 'N/A', // Safely access nested field
    },

    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item key='manageUser' onClick={() => handleMenuClick(record)}>
              Manage User
            </Menu.Item>
            <Menu.Item
              key='viewUser'
              onClick={() => dispatch(setUserProfile({ userId: record._id }))}>
              View Profile
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <EllipsisOutlined style={{ fontSize: 24, cursor: 'pointer' }} />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={users}
      columns={columns}
      rowKey={(record) => record._id}
      loading={loading}
      pagination={{ pageSize: 50 }}
    />
  );
};

export default UserTable;
