/** @format */

import React, { useState } from 'react';
import { Select, Input, message } from 'antd';
import SuperAdminLayout from '../../components/super-admin/layout';
import Filter from 'img/ArrowsDownUp.svg';
import { useSelector } from 'react-redux';
import CustomerTable from 'components/widgets/customerDirectoryTable';
import OfficeNames from 'components/super-admin/widgets/officeNames';
import CustomerDetails from 'components/customerDetails';

import { searchCustomer } from 'api/customerRoute';
const { Option } = Select;
const { Search } = Input;

const SuperCustomerDirectory = () => {
  const [filterBy, setFilterBy] = useState('Office');
  const [customers, setCustomers] = useState([]); // To store search results
  const [loading, setLoading] = useState(false); // Loading state for search
  const selectedFormId = useSelector((state) => state.button.selectedFormId);

  // Handle filter change between "Office" and "No Office"
  const handleFilterChange = (value) => {
    setFilterBy(value);
  };

  // Handle search for customer
  const handleSearch = async (query) => {
    if (!query) {
      message.warning('Please enter a search query');
      return;
    }

    setLoading(true);
    try {
      const results = await searchCustomer(query);
      setCustomers(results);
      if (results.length === 0) {
        message.info('No customers found');
      }
    } catch (error) {
      message.error('Error fetching customers');
      console.error('Search error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SuperAdminLayout>
      <div style={{ position: 'relative' }}>
        <div className='filter-container'>
          <div className='filter'>
            <img src={Filter} alt='Filter' />
          </div>

          {/* Search Input using Ant Design */}
          <div style={{ width: '200px' }}>
            <Search
              placeholder='Search Customer'
              enterButton='Search'
              onSearch={handleSearch} // Trigger search on enter
              loading={loading}
              style={{ marginLeft: 10 }}
            />
          </div>
        </div>
        {selectedFormId && <CustomerDetails />}
        {/* Pass customers and loading state to CustomerTable */}
        {!selectedFormId && (
          <CustomerTable customers={customers} loading={loading} />
        )}
      </div>
    </SuperAdminLayout>
  );
};

export default SuperCustomerDirectory;
