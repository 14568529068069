/** @format */

import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DoughnutChart = ({ points, color }) => {
  const remainingPoints = 100 - points; // Calculate remaining points
  console.log(points);

  const data = {
    labels: ['Score', 'Remaining'],
    datasets: [
      {
        data: [points, remainingPoints], // Use points and remaining points
        backgroundColor: [color, '#e0e0e0'], // Color for score and gray for remaining
        hoverBackgroundColor: [color],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    cutout: '70%', // Makes it look like a donut
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
  };

  return (
    <div
      style={{
        backgroundColor: '#e3f5ff',
        padding: '30px',
        borderRadius: '5px',
        position: 'relative',
        width: '100%',
      }}
      className='doughnut-card'>
      <h1 style={{ color: color }} className='doughnut-grade-card'>
        {points}%
      </h1>
      <Doughnut
        data={data}
        options={options}
        style={{ width: '100%', height: '100% !important' }}
      />
    </div>
  );
};

export default DoughnutChart;
