/** @format */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setOfficeId } from 'state/slice/dropDownSlice'; // Adjust import path as needed
import { Select } from 'antd';
const { Option } = Select;

const OfficeNames = () => {
  const selectedOfficeId = useSelector((state) => state.dropDown.officeId);
  const [offices, setOffices] = useState([]);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App
  useEffect(() => {
    const fetchOffices = async () => {
      setError(null);

      try {
        // Construct URL to include division as a query parameter
        const url = `${apiUrl}/api/offices/office-list`;
        const response = await axios.get(url);
        setOffices(response.data);
      } catch (error) {
        console.error('Error Fetching Offices:', error);
        setError('Failed to load offices.');
      }
    };
    fetchOffices();
  }, [apiUrl]); // Effect runs when division prop changes

  const handleSelectChange = (value) => {
    const selectedOfficeId = value;
    dispatch(
      setOfficeId({
        officeId: selectedOfficeId,
      })
    );
  };
  const sortedNames = offices.sort((a, b) =>
    a.officeName.localeCompare(b.officeName)
  );

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Select
        showSearch
        defaultValue={selectedOfficeId ? selectedOfficeId : 'Select Office'}
        onChange={handleSelectChange}>
        {sortedNames.map((office) => (
          <Option key={office.officeId} value={office.officeId}>
            {office.officeName}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default OfficeNames;
