/** @format */

import { createSlice } from '@reduxjs/toolkit';

const viewOffice = createSlice({
  name: 'viewOffice',
  initialState: {
    selectedOffice: null,
    selectedOfficeType: null,
  },
  reducers: {
    // Assuming the action payload will be an object { officeId: "...", officeType: "..." }
    setSelectedOffice: (state, action) => {
      state.selectedOffice = action.payload.officeId;
      state.selectedOffice = action.payload.officeId;
    },
    clearSelectedOffice: (state) => {
      state.selectedOffice = null;
      state.selectedOfficeType = null;
    },
  },
});

export const { setSelectedOffice, clearSelectedOffice } = viewOffice.actions;

// Selectors
export const officeId = (state) => state.viewOffice.selectedOffice;
export const officeType = (state) => state.viewOffice.selectedOfficeType;
export const officeID = (state) => state.viewOffice.selectedOffice;

export default viewOffice.reducer;
