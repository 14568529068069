/** @format */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Table, Input, Button, Select, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
const { Option } = Select;

const PostDateTable = () => {
  const { userData } = useSelector((state) => state.user);
  const usersOfficeId = userData?.officeId || null; // This safely extracts officeId from userData
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
  const [formDatas, setFormsDatasLocal] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [error, setError] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [isEditingStatus, setIsEditingStatus] = useState(null);
  const selectedOfficeId = useSelector((state) => state.dropDown.officeId);
  useEffect(() => {
    const getFormDatas = async () => {
      const token = localStorage.getItem('accessToken');
      try {
        setError(null);
        const response = await axios.get(`${apiUrl}/api/custo/post-date/list`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            startTime: startDate,
            endTime: endDate,
            officeId: selectedOfficeId,
          },
        });

        // Make sure the data is always an array
        const formData = Array.isArray(response.data) ? response.data : [];
        setFormsDatasLocal(formData);
      } catch (error) {
        setError('Failed to load data');
        console.error('Error fetching data:', error);
      }
    };

    if (usersOfficeId === null && selectedOfficeId) {
      getFormDatas();
    } else if (usersOfficeId && startDate && endDate) {
      getFormDatas();
    }
  }, [startDate, endDate, apiUrl, selectedOfficeId, usersOfficeId]);

  const handleEdit = (index, form) => {
    setEditIndex(index);
    setEditedData({ ...form });
  };

  const handleSave = async (index) => {
    const token = localStorage.getItem('accessToken');

    // Ensure planType is always an array and handle other fields if needed
    const dataToSave = {
      ...editedData,
      planType: Array.isArray(editedData.planType)
        ? editedData.planType
        : typeof editedData.planType === 'string'
        ? editedData.planType.split(',').map((item) => item.trim()) // Convert single string to array
        : [], // Default to an empty array if somehow invalid
    };

    console.log('Data to Save:', dataToSave); // Debug log

    try {
      const response = await axios.put(
        `${apiUrl}/api/policy-form/admin/update-form/${editedData._id}`,
        dataToSave,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Save response:', response.data); // Debug log

      const updatedForms = [...formDatas];
      updatedForms[index] = response.data; // Update the table with the response data
      setFormsDatasLocal(updatedForms);

      setEditIndex(null);
      setEditedData({});
    } catch (error) {
      console.error('Error saving data:', error);
      setError('Failed to save data');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData({
      ...editedData,
      [name]: value,
    });
  };

  const dateFormat = (date) => {
    if (!date) return '';
    const dateObj = new Date(date);
    if (isNaN(dateObj)) return '';
    const localDate = new Date(
      dateObj.getTime() + dateObj.getTimezoneOffset() * 60000
    );
    return !isNaN(localDate) ? localDate.toDateString() : 'Invalid date';
  };

  const capitalizeFullName = (name) => {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Cancel':
        return 'status-cancel';
      case 'Declined':
        return 'status-declined';
      case 'Paid':
        return 'status-paid';
      default:
        return '';
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleStatusChange = (id, status, type, parentFormId) => {
    const updatedForms = formDatas.map((form) => {
      if (form._id === parentFormId) {
        if (type === 'Ancillary') {
          form.ancillaries = form.ancillaries.map((item) =>
            item._id === id ? { ...item, status } : item
          );
        } else if (type === 'Major Med') {
          form.majorMed.status = status;
        } else if (type === 'Private Med') {
          form.privateMed.status = status;
        }
      }
      return form;
    });
    setFormsDatasLocal(updatedForms);
  };

  const handleSaveStatus = async (parentFormId) => {
    const token = localStorage.getItem('accessToken');
    const form = formDatas.find((form) => form._id === parentFormId);
    if (!form) return;

    try {
      await axios.put(
        `${apiUrl}/api/policy-form/admin/update-form/${form._id}`,
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsEditingStatus(null); // Reset the edit mode
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...getColumnSearchProps('firstName'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.firstName}
            name='firstName'
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...getColumnSearchProps('lastName'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.lastName}
            name='lastName'
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      ...getColumnSearchProps('phoneNumber'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.phoneNumber}
            name='phoneNumber'
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      key: 'vendorName',
      ...getColumnSearchProps('vendorName'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.vendorName}
            name='vendorName'
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Member ID',
      dataIndex: 'memberId',
      key: 'memberId',
      ...getColumnSearchProps('memberId'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.memberId}
            name='memberId'
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      ...getColumnSearchProps('carrier'),
      render: (text, record, index) =>
        editIndex === index ? (
          <Input
            value={editedData.carrier}
            name='carrier'
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentName',
      key: 'agentName',
      ...getColumnSearchProps('agentName'),
      render: (text) => capitalizeFullName(text),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Cancel', value: 'Cancel' },
        { text: 'Declined', value: 'Declined' },
        { text: 'Paid', value: 'Paid' },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (text, record, index) =>
        editIndex === index ? (
          <Select
            value={editedData.status}
            name='status'
            onChange={(value) =>
              setEditedData({ ...editedData, status: value })
            }
            style={{ width: '100%' }}>
            <Option value='Cancel'>Cancel</Option>
            <Option value='Declined'>Declined</Option>
            <Option value='Paid'>Paid</Option>
          </Select>
        ) : (
          <span className={getStatusClass(text)}>{text}</span>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record, index) =>
        editIndex === index ? (
          <Button type='primary' onClick={() => handleSave(index)}>
            Save
          </Button>
        ) : (
          <Button onClick={() => handleEdit(index, record)}>Edit</Button>
        ),
    },
  ];

  const expandedRowRender = (record) => {
    const nestedColumns = [
      { title: 'Type', dataIndex: 'type', key: 'type' },
      { title: 'Carrier', dataIndex: 'carrier', key: 'carrier' },
      {
        title: 'Billing Date',
        dataIndex: 'billingDate',
        key: 'billingDate',
        render: (text) => dateFormat(text),
      },
      { title: 'Premium', dataIndex: 'premium', key: 'premium' },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, nestedRecord) =>
          isEditingStatus === nestedRecord._id ? (
            <Select
              value={text}
              style={{ color: getStatusClass(text) }}
              onChange={(value) =>
                handleStatusChange(
                  nestedRecord._id,
                  value,
                  nestedRecord.type,
                  record._id
                )
              }>
              <Option value='Paid' style={{ color: 'green' }}>
                Paid
              </Option>
              <Option value='Cancelled' style={{ color: 'red' }}>
                Cancelled
              </Option>
              <Option value='Declined' style={{ color: 'orange' }}>
                Declined
              </Option>
              <Option value='Pending' style={{ color: 'blue' }}>
                Pending
              </Option>
            </Select>
          ) : (
            <span className={getStatusClass(text)}>{text}</span>
          ),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, nestedRecord) =>
          isEditingStatus === nestedRecord._id ? (
            <Button onClick={() => handleSaveStatus(record._id)}>Save</Button>
          ) : (
            <Button
              onClick={() => {
                setIsEditingStatus(nestedRecord._id);
              }}>
              Edit Status
            </Button>
          ),
      },
    ];

    return (
      <>
        {record.ancillaries && record.ancillaries.length > 0 && (
          <Table
            columns={nestedColumns}
            dataSource={record.ancillaries.map((item) => ({
              ...item,
              type: 'Ancillary',
            }))}
            pagination={false}
            rowKey={(record) => record._id}
          />
        )}
        {record.majorMed && (
          <Table
            columns={nestedColumns}
            dataSource={[{ ...record.majorMed, type: 'Major Med' }]}
            pagination={false}
            rowKey={(record) => record._id}
          />
        )}
        {record.privateMed && (
          <Table
            columns={nestedColumns}
            dataSource={[{ ...record.privateMed, type: 'Private Med' }]}
            pagination={false}
            rowKey={(record) => record._id}
          />
        )}
      </>
    );
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        dataSource={formDatas}
        rowKey={(record) => record._id}
        expandable={{ expandedRowRender }}
        pagination={{ pageSize: 70 }}
      />
    </div>
  );
};

export default PostDateTable;
