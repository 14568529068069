/** @format */

// components/super-admin/widgets/UserDetails.js

// UserDetails.js
import React, { useEffect, useState } from 'react';
import { getUser, updateUserDetails } from 'api/userRoutes'; // Import updateUserDetails
import { useSelector, useDispatch } from 'react-redux';
import { clearUserId } from 'state/slice/dropDownSlice';
import { Spin, Button, Row, Col, Input, Select, Form, message } from 'antd';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { getOfficeDetails } from 'api/officeRoutes';
import DeleteUserComponent from './super-admin/widgets/deleteUser';
import UserAccessComponent from './super-admin/widgets/userAcessComponent';
// Correctly import Form // Import the function
const { Option } = Select;

const UserDetails = () => {
  const userId = useSelector((state) => state.dropDown.userId);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [officeOptions, setOfficeOptions] = useState([]);

  // State for office options
  const dispatch = useDispatch();

  // State for form data and editing mode
  const [form] = Form.useForm();
  const [updating, setUpdating] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Track editing mode

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const userData = await getUser(userId);
        setUser(userData);
        // Set form fields with fetched user data
        form.setFieldsValue({
          firstname: capitalizeFirstLetter(userData.firstname),
          lastname: capitalizeFirstLetter(userData.lastname),
          email: userData.email,
          role: userData.role,
          officeId: userData.officeId || '',
        });
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId, form]);

  // Fetch office list on component mount
  useEffect(() => {
    const fetchOfficeList = async () => {
      try {
        const officeResponse = await getOfficeDetails(); // Fetch office list
        setOfficeOptions(officeResponse); // Set office list in state
      } catch (error) {
        console.error('Error fetching office list:', error);
      }
    };

    fetchOfficeList();
  }, []);
  const handleSelectChange = (value) => {
    if (value === null) {
      form.setFieldsValue({
        officeId: null,
        officeName: 'No Office',
      });
    } else {
      const selectedOffice = officeOptions.find(
        (office) => office.officeId === value
      );
      if (selectedOffice) {
        form.setFieldsValue({
          officeId: selectedOffice.officeId,
          officeName: selectedOffice.officeName,
        });
      }
    }
  };
  const refetchUserData = async () => {
    const userData = await getUser(userId);
    setUser(userData);
    form.setFieldsValue({
      firstname: capitalizeFirstLetter(userData.firstname),
      lastname: capitalizeFirstLetter(userData.lastname),
      email: userData.email,
      role: userData.role,
      officeId: userData.officeId,
      officeName: userData.officeName,
    });
  };
  const handleClose = () => {
    // Dispatch the action to clear the userId
    dispatch(clearUserId());
  };

  const onFinish = async () => {
    setUpdating(true);
    try {
      // Get the latest form values
      const values = form.getFieldsValue();

      // Prepare updateData object
      const updateData = {
        firstname: values.firstname.toLowerCase(),
        lastname: values.lastname.toLowerCase(),
        email: values.email,
        role: values.role,

        officeId: values.officeId, // Always include officeId
        officeName: values.officeName, // Always include officeName
      };

      // Call the backend API to update user details
      await updateUserDetails(userId, updateData);
      refetchUserData();
      // Success message
      message.success('User updated successfully');
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating user:', error);
      message.error(error.response?.data?.message || 'Failed to update user');
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className='user-details'>
      <Row>
        <Col>
          <Button onClick={handleClose} style={{ marginBottom: 16 }}>
            Back to User List
          </Button>
        </Col>
      </Row>
      <Row justify='center' gutter={[28, 28]}>
        <div className='user-details-wrapper'>
          {loading ? (
            <Spin tip='Loading user details...' />
          ) : (
            <>
              {!isEditing
                ? // Display user details
                  user && (
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} lg={24}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}>
                          <h3 className='prof-heading'>User Details</h3>
                          <Button
                            type='primary'
                            onClick={() => setIsEditing(true)}>
                            Edit
                          </Button>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} lg={12}>
                        <div className='detail-wrapper'>
                          <p className='detail-title'>First Name</p>
                          <p className='detail-text'>
                            {capitalizeFirstLetter(user.firstname)}
                          </p>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} lg={12}>
                        <div className='detail-wrapper'>
                          <p className='detail-title'>Last Name</p>
                          <p className='detail-text'>
                            {capitalizeFirstLetter(user.lastname)}
                          </p>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} lg={12}>
                        <div className='detail-wrapper'>
                          <p className='detail-title'>User Name</p>
                          <p className='detail-text'>{user.username}</p>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} lg={12}>
                        <div className='detail-wrapper'>
                          <p className='detail-title'>Email</p>
                          <p className='detail-text'>{user.email}</p>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} lg={12}>
                        <div className='detail-wrapper'>
                          <p className='detail-title'>Role</p>
                          <p className='detail-text'>{user.role}</p>
                        </div>
                      </Col>

                      <Col xs={24} sm={24} lg={12}>
                        <div className='detail-wrapper'>
                          <p className='detail-title'>Office Name</p>
                          <p className='detail-text'>
                            <p>
                              {user?.officeId?.officeName || 'Synergy Custo'}
                            </p>
                          </p>{' '}
                          {/* Displaying the office name */}
                        </div>
                      </Col>
                    </Row>
                  )
                : // Display edit form
                  user && (
                    <Form
                      form={form}
                      layout='vertical'
                      onFinish={onFinish}
                      initialValues={{
                        firstname: capitalizeFirstLetter(user.firstname),
                        lastname: capitalizeFirstLetter(user.lastname),
                        email: user.email,
                        role: user.role,
                        officeId: user.officeId, // Initialize officeId here
                        officeName: user.officeName || '',
                        division: user.division || [],
                      }}>
                      {/* Hidden field to hold officeId */}
                      <Form.Item name='officeId' hidden>
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label='First Name'
                        name='firstname'
                        rules={[
                          { required: true, message: 'Enter first name' },
                        ]}>
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label='Last Name'
                        name='lastname'
                        rules={[
                          { required: true, message: 'Enter last name' },
                        ]}>
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label='Role'
                        name='role'
                        rules={[{ required: true, message: 'Select role' }]}>
                        <Select>
                          <Option value='Admin'>Admin</Option>
                          <Option value='Agent'>Agent</Option>
                          <Option value='Custo'>Custo</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label='Office Name'
                        name='officeName'
                        rules={[
                          { required: true, message: 'Select office name' },
                        ]}>
                        <Select
                          showSearch
                          onChange={handleSelectChange}
                          placeholder='Select Office'
                          optionFilterProp='children' // This tells Ant Design to filter based on the Option's children
                          filterOption={(input, option) =>
                            option?.children
                              ?.toLowerCase()
                              .includes(input.toLowerCase())
                          }>
                          <Option value={null}>No Office</Option>{' '}
                          {/* No Office Option */}
                          {officeOptions.map((office) => (
                            <Option
                              key={office.officeId}
                              value={office.officeId}>
                              {office.officeName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type='primary'
                          htmlType='submit'
                          loading={updating}>
                          Update User
                        </Button>
                        <Button
                          style={{ marginLeft: 8 }}
                          onClick={() => setIsEditing(false)}>
                          Cancel
                        </Button>
                      </Form.Item>
                    </Form>
                  )}
            </>
          )}
        </div>

        <UserAccessComponent />

        <div className='user-details-wrapper'>
          <DeleteUserComponent userId={userId} />
        </div>
      </Row>
    </div>
  );
};

export default UserDetails;
