/** @format */

// src/components/SalesChart.js

import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "This Week Sales Activity",
      position: "LeftTop",
    },
  },

  scales: {
    x: {
      grid: {
        display: false, // No vertical grid lines
      },
      border: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false, // No horizontal grid lines
      },

      border: {
        display: false,
      },
    },
  },
};

const data = {
  labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
  datasets: [
    {
      label: "Sales",
      data: [120, 150, 180, 130, 190], // Assuming 67 leads, no sales for 'Leads'
      backgroundColor: "#1C1C1C",
      borderWidth: 1,
      borderRadius: 20, // Rounded corners for bars
      barThickness: 7,
    },
    {
      label: "Leads",
      data: [300, 250, 200, 350, 300], // Assuming no leads, 45 sales for 'Sales'
      backgroundColor: " #1c1c1c79",
      opacity: "0.5",
      borderWidth: 1,
      borderRadius: 20, // Rounded corners for bars
      barThickness: 7,
    },
  ],
};

const SalesChart = () => {
  return (
    <div className="chart-container">
      <span>Weekly Sales Activity</span>
      <Bar width={544} height={193} options={options} data={data} />
    </div>
  );
};

export default SalesChart;
