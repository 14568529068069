/** @format */

import axios from 'axios';
const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
/**
 * Fetch ACA request forms by office ID, start time, and end time.
 *
 * @param {string} officeId - The office ID for which to fetch ACA request forms.
 * @param {string} startTime - The start time for filtering the forms.
 * @param {string} endTime - The end time for filtering the forms.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of ACA request forms.
 * @throws {Error} - Throws an error if the fetch fails.
 */
export const getAcaRequestFormsByofficeId = async (
  endpoint,
  startTime,
  endTime
) => {
  try {
    const token = localStorage.getItem('accessToken');
    const params = {
      startTime, // Mandatory start time
      endTime, // Mandatory end time
    };

    const response = await axios.get(`${apiUrl}/api/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Use token for authorization
      },
      params, // Pass officeId, startTime, and endTime as query parameters
    });

    return response.data; // Return the fetched ACA request forms
  } catch (error) {
    console.error('Error fetching ACA request forms:', error);
    throw error;
  }
};

/**
 * Fetch ACA request forms by office ID, start time, and end time.
 *
 * @param {string} officeId - The office ID for which to fetch ACA request forms.
 * @param {string} startTime - The start time for filtering the forms.
 * @param {string} endTime - The end time for filtering the forms.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of ACA request forms.
 * @throws {Error} - Throws an error if the fetch fails.
 */
export const getAcaRequestForms = async (startTime, endTime) => {
  try {
    const token = localStorage.getItem('accessToken');
    const params = {
      startTime, // Mandatory start time
      endTime, // Mandatory end time
    };

    const response = await axios.get(
      `${apiUrl}/api/super-admin/aca-request-forms`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Use token for authorization
        },
        params, // Pass officeId, startTime, and endTime as query parameters
      }
    );

    return response.data; // Return the fetched ACA request forms
  } catch (error) {
    console.error('Error fetching ACA request forms:', error);
    throw error;
  }
};
