/**
 * Returns a CSS class name based on the given rank index.
 *
 * @format
 * @param {number} index - The rank index.
 * @returns {string} The CSS class name corresponding to the rank.
 */

export const getRankClass = (index) => {
  if (index === 0) return 'top1';
  if (index === 1) return 'top2';
  if (index === 2) return 'top3';
  return 'rest';
};
