import React, { useState } from "react";
import axios from "axios";
import Exit from "img/exit.svg";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toggleCampaignsForm } from "state/slice/tabSlice";
const AddVendorsForm = () => {
  const [vendors, setVendors] = useState([
    { vendorName: "", vendorId: "", cost: "", buffer: "" },
  ]);
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newVendors = [...vendors];
    newVendors[index][name] = value;
    setVendors(newVendors);
  };

  const handleAddVendor = () => {
    setVendors([
      ...vendors,
      { vendorName: "", queueId: "", cost: "", buffer: "" },
    ]);
  };

  const handleRemoveVendor = (index) => {
    const newVendors = vendors.filter((_, i) => i !== index);
    setVendors(newVendors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("accessToken"); // Assuming the token is stored in local storage
      const response = await axios.post(
        `${apiUrl}/api/U65Vendors/batch-register`,
        vendors,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message);
    } catch (error) {
      console.error("Error during batch registration:", error);
      toast.error("Error during batch registration");
    }
  };
  const handlExit = () => {
    dispatch(toggleCampaignsForm(false)); // Corrected action dispatch
  };
  return (
    <div className="form-layout">
      <div className="exit-form-wrapper" style={{ width: "880px" }}>
        <button
          className="exit-form"
          style={{ float: "right", marginBottom: "30px" }}
          onClick={handlExit}>
          <img src={Exit} alt="Exit Button" style={{ cursor: "pointer" }} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="vendors-form-conainer ">
        <h2>Add New Vendors</h2>
        <div className="vendors-form-wrapper">
          {vendors.map((vendor, index) => (
            <div className="vendors-input-container" key={index}>
              <input
                type="text"
                name="vendorName"
                placeholder="Vendor Name"
                value={vendor.vendorName}
                onChange={(e) => handleChange(index, e)}
                required
              />
              <input
                type="number"
                name="queueId"
                placeholder="Queue ID"
                value={vendor.queueId}
                onChange={(e) => handleChange(index, e)}
                required
              />
              <input
                type="number"
                name="cost"
                placeholder="Cost"
                value={vendor.cost}
                onChange={(e) => handleChange(index, e)}
                required
              />
              <input
                type="number"
                name="buffer"
                placeholder="Buffer"
                value={vendor.buffer}
                onChange={(e) => handleChange(index, e)}
                required
              />
              <button type="button" onClick={() => handleRemoveVendor(index)}>
                Remove
              </button>
            </div>
          ))}{" "}
        </div>
        <div className="vendors-form-button-conainer">
          <button type="button" onClick={handleAddVendor}>
            Add Vendor
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
      {message && <p style={{ color: "green" }}>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default AddVendorsForm;
