/** @format */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchUserData, setLoading, setError } from 'state/slice/userSlice';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

  const signIn = async (username, password) => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/auth/login`,
        { username, password },
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(setLoading(true)); // Start loading state
    try {
      const signInResponse = await signIn(username, password);

      if (signInResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
        sessionStorage.setItem(
          'updatePasswordSession',
          JSON.stringify({
            session: signInResponse.session,
            username,
          })
        );
        navigate('/update-password');
      } else {
        // Store tokens and user role in localStorage
        localStorage.setItem('accessToken', signInResponse.accessToken);
        localStorage.setItem('idToken', signInResponse.idToken);
        localStorage.setItem('userRole', signInResponse.role);
        if (signInResponse.officeId) {
          localStorage.setItem('officeId', signInResponse.officeId);
        }

        // Navigate based on role
        const userRole = signInResponse.role;
        if (userRole === 'Admin') {
          dispatch(fetchUserData());

          navigate('/admin/dashboard');
        } else if (userRole === 'Agent') {
          dispatch(fetchUserData());
          navigate('/agent/dashboard');
        } else if (userRole === 'Custo') {
          dispatch(fetchUserData());
          navigate('/custo/forms');
        } else {
          navigate('/dashboard'); // Fallback for unknown roles
        }
      }
    } catch (error) {
      setError(
        error.message ||
          'Error signing in. Please check your credentials and try again.'
      );
      dispatch(setError(error.message)); // Update the error in Redux state
    } finally {
      dispatch(setLoading(false)); // End loading state
    }
  };

  return (
    <div className='signin-form-container'>
      <h2>Sign In</h2>
      <form onSubmit={handleSubmit} className='signin-form-wrapper'>
        <input
          type='text'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder='Username'
        />
        <input
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='Password'
        />
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <button type='submit'>Login</button>
      </form>
      <div>
        <span>Forgot your password? reset it</span>
        <span>
          {' '}
          <NavLink to='/reset-password'>here</NavLink>{' '}
        </span>
      </div>
    </div>
  );
};

export default LoginForm;
