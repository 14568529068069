/** @format */

import React, { useState } from 'react';
import SuperAdminLayout from 'components/super-admin/layout';
import { useSelector, useDispatch } from 'react-redux';
import AddVendorsForm from 'components/super-admin/widgets/addVendorsForm';
import { toggleVendorsForm } from 'state/slice/tabSlice';
import { ToastContainer } from 'react-toastify';
import FullPieChart from 'components/super-admin/widgets/fullPieChart';
import DownArrow from 'img/ArrowLineDown.png';
import GlobalVendorsTable from 'components/super-admin/widgets/globalNames';
import GlobalTable from 'components/super-admin/widgets/globalTable';
const VendorsOverview = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('Top10');
  const vendor = useSelector((state) => state.activeTab.addVendors);

  const handleVendorsFrom = (bool) => {
    dispatch(toggleVendorsForm(bool));
  };
  const handleTab = (tab) => {
    setActiveTab(tab);
  };
  return (
    <SuperAdminLayout>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
        <div className='campaign-header-containger'>
          <div className='campaign-tab-wrapper'>
            <span
              onClick={() => handleTab('Top10')}
              className={activeTab === 'Top10' ? 'active' : ''}>
              Top Global Vendors (beta)
            </span>{' '}
            <span
              onClick={() => handleTab('global Names')}
              className={activeTab === 'global Names' ? 'active' : ''}>
              Global Names
            </span>
            <span
              onClick={() => handleTab('Global Vendors')}
              className={activeTab === 'Global Vendors' ? 'active' : ''}>
              Global Vendors
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            {/**  <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
                cursor: "pointer ",
              }}>
              <span onClick={() => handleUpload(true)}>Upload Logs</span>{" "}
              <img src={DownArrow} alt="down arrow" />
            </div>*/}

            {/* Corrected onClick handler */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2px',
                cursor: 'pointer ',
              }}>
              <span onClick={() => handleVendorsFrom(true)}>Add Vendors</span>{' '}
              <img src={DownArrow} alt='down arrow' />
            </div>
          </div>
        </div>
        {vendor && <AddVendorsForm />}
        {activeTab === 'Top10' && <FullPieChart />}
        {activeTab === 'Global Vendors' && <GlobalTable />}
        {activeTab === 'global Names' && <GlobalVendorsTable />}
        <ToastContainer />
      </div>
    </SuperAdminLayout>
  );
};

export default VendorsOverview;
