/** @format */

import axios from 'axios';

const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

class ListedLeadsRoutes {
  /**
   * Get all listed leads for
  
   * @returns {Promise<Object>} - A promise that resolves with the listed leads data
   */
  static async getListedLeads(endpoint) {
    try {
      const response = await axios.get(`${apiUrl}/${endpoint}`);
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to fetch listed leads by office ID: ${error.message}`
      );
    }
  }

  /**
   * Create multiple listed leads
   * @param {Array} dataArray - An array of listed lead objects to be created
   * @returns {Promise<Object>} - A promise that resolves with the created listed leads data
   */
  static async createListedLeads(dataArray) {
    try {
      const response = await axios.post(`${apiUrl}/listed-leads`, dataArray);
      return response.data;
    } catch (error) {
      throw new Error(`Failed to create listed leads: ${error.message}`);
    }
  }

  /**
   * Update a listed lead by ID
   * @param {string} id - The ID of the listed lead to update
   * @param {Object} data - The data to update
   * @returns {Promise<Object>} - A promise that resolves with the updated listed lead data
   */
  static async updateListedLeadById(id, data) {
    try {
      const response = await axios.put(
        `${apiUrl}/api/leads/listed-leads/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw new Error(`Failed to update listed lead: ${error.message}`);
    }
  }

  /**
   * Delete a listed lead by ID
   * @param {string} id - The ID of the listed lead to delete
   * @returns {Promise<Object>} - A promise that resolves with a success message
   */
  static async deleteListedLeadById(id) {
    try {
      const response = await axios.delete(`${apiUrl}/listed-leads/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Failed to delete listed lead: ${error.message}`);
    }
  }
}

export default ListedLeadsRoutes;
