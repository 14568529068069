/** @format */

// components/admin/OfficeNames.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthenticatedUserDownlineAccess } from 'api/downlineAccessRoutes'; // Ensure correct path
import { setOfficeId } from 'state/slice/dropDownSlice'; // Ensure correct path
import { Select, Spin } from 'antd';

const { Option } = Select;

const OfficeNames = () => {
  const dispatch = useDispatch();
  const selectedOfficeId = useSelector((state) => state.dropDown.officeId);
  const { downlineAccess } = useSelector((state) => state.user.userAccess); // Access userData from Redux

  const handleSelectChange = (value) => {
    dispatch(setOfficeId({ officeId: value }));
  };

  return (
    <div>
      <Select
        value={selectedOfficeId || undefined}
        onChange={handleSelectChange}
        style={{ width: '100%' }}
        placeholder='Select Office'
        showSearch
        optionFilterProp='children'
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }>
        {downlineAccess.map((office) => (
          <Option key={office._id} value={office._id}>
            {office.officeName}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default OfficeNames;
