/**
 * Calculates the Cost Per Acquisition (CPA) and formats it with two decimal places.
 * Returns 'No Data' if the denominator is zero or if the result is infinite.
 *
 * @param {number} num - The numerator.
 * @param {number} dem - The denominator.
 * @returns {string} - The formatted CPA or 'No Data'.
 */
import formatCurrency from "./formatCurrency";
const cpa = (num, dem) => {
  if (dem === 0 || !isFinite(num / dem)) {
    return { value: null, formatted: "No Data" };
  }
  const result = num / dem;
  return { value: result, formatted: formatCurrency(result) }; // Return both raw value and formatted string
};

export default cpa;
