/** @format */
import { useEffect } from 'react';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/HomePage';
import setupInterceptors from './setupInterceptors';
import Dashboard from './pages/super-admin/Dashboard';
import SignIn from 'pages/SignIn';
import VendorsOverview from 'pages/super-admin/VendorOverview';
import Customer from 'pages/customerDirectory';
import QualityAssuranceReport from 'pages/admin/QualityAssurance';
import AdminDashboard from 'pages/admin/AdminDashboard';
import AffiliatePerformance from 'pages/super-admin/AffiliatePerformance';
import CustomerDirectory from 'pages/super-admin/CustomerDirectory';
import AdminVendorsOverview from 'pages/admin/VendorOverview';
import CampaignOverview from 'pages/super-admin/CampaignOverview';
import Users from 'pages/super-admin/Users';
import ViewOffice from 'components/super-admin/widgets/officeProfile';
import UpdatePasswordForm from 'pages/UpdatePassword';
import AgentDashboard from 'pages/agents/AgentDashboard';
import AdminAgentsPage from 'pages/admin/Agents';
import Offices from 'pages/super-admin/Offices';
import AdminLeaderboard from 'pages/admin/Leaderboard';
import AffiliatePerformanceAcess from 'pages/admin/AffiliatePerformace';
import AgentLeaderboard from 'pages/agents/Leaderboard';
import Form from 'pages/agents/Form';
import SuperAdminForms from 'pages/super-admin/Forms';
import CustoPostDate from 'pages/custo/PostDates';
import Leaderboard from 'pages/super-admin/Leaderboard';
import AdminCampaignOverview from 'pages/admin/CampaignOverview';
import AdminForms from 'pages/admin/Forms';
import MultiStepResetForm from 'pages/PasswordReset';
import QualityAssurance from 'pages/custo/QualityAssurance';

function App() {
  useEffect(() => {
    setupInterceptors(); // Set up Axios interceptors on app load
  }, []);
  return (
    <Router>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route
          path='/affiliates-performance'
          element={<AffiliatePerformance />}
        />
        <Route
          path='/admin/affiliates-performance'
          element={<AffiliatePerformanceAcess />}
        />
        <Route path='/forms' element={<SuperAdminForms />} />
        {/** */} <Route path='/customers' element={<CustomerDirectory />} />
        <Route path='/dir/customers' element={<Customer />} />
        <Route path='/reset-password' element={<MultiStepResetForm />} />
        <Route path='admin/forms' element={<AdminForms />} />
        <Route path='/custo/quality-assurance' element={<QualityAssurance />} />
        <Route
          path='/admin/quality-assurance'
          element={<QualityAssuranceReport />}
        />
        <Route path='/leaderboard' element={<Leaderboard />} />
        <Route path='/Signin' element={<SignIn />} />
        <Route path='/admin/leaderboard' element={<AdminLeaderboard />} />
        <Route path='/offices' element={<Offices />} />
        <Route path='/vendors' element={<VendorsOverview />} />
        <Route path='/admin/vendors' element={<AdminVendorsOverview />} />
        <Route path='/admin/campaign' element={<AdminCampaignOverview />} />
        <Route path='/admin/dashboard' element={<AdminDashboard />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/campaign' element={<CampaignOverview />} />
        <Route path='/office-details/:officeId' element={<ViewOffice />} />
        <Route path='/update-password' element={<UpdatePasswordForm />} />
        <Route path='/agent/dashboard' element={<AgentDashboard />} />
        <Route path='/admin/users' element={<AdminAgentsPage />} />
        <Route path='/agent/leaderboard' element={<AgentLeaderboard />} />
        <Route path='agent/forms' element={<Form />} />
        <Route path='/custo/forms' element={<CustoPostDate />} />
        <Route path='/Users' element={<Users />} />
      </Routes>
    </Router>
  );
}

export default App;
