/** @format */

import { createSlice } from '@reduxjs/toolkit';

const toggleTab = createSlice({
  name: 'toggleTab',
  initialState: {
    activeTab: '',
    activeForm: '',
  },

  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setActiveTab } = toggleTab.actions;
export const tab = (state) => state.toggleTab.activeTab;

export default toggleTab.reducer;
