/** @format */

import { createSlice } from '@reduxjs/toolkit';
import { getUserAccess } from 'api/downlineAccessRoutes'; // Import the getUserAccess API function
import axios from 'axios';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: null, // Holds basic user details like userId, name, email, etc.
    userAccess: null, // Holds user access permissions like ssnAccess, downlineAccess, etc.
    isLoading: false,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.userData = action.payload; // Updates userData with all user info
    },
    setUserAccess: (state, action) => {
      state.userAccess = action.payload; // Updates userAccess with the user's access details
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App
export const { setUser, setUserAccess, setLoading, setError } =
  userSlice.actions;

// Asynchronous thunk action to fetch user data
export const fetchUserData = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const token = localStorage.getItem('accessToken');

    // Fetch user details
    const response = await axios.get(`${apiUrl}/api/users/user-details`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const userData = response.data; // Contains all user properties like userId, name, email, etc.

    // Dispatch the userData to the Redux store
    dispatch(setUser(userData));

    // Fetch user access details separately
    const userAccessResponse = await getUserAccess(userData._id);

    if (userAccessResponse.message === 'User access retrieved successfully') {
      // Dispatch the user access data to the Redux store
      dispatch(setUserAccess(userAccessResponse.access));
    }
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export default userSlice.reducer;
