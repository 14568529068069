/** @format */

import axios from 'axios';
import { accessToken } from 'utils/accessToken';
const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

/**
 * Function to submit the QA Form data to the backend.
 * @param {Object} formData - The form data including fields and the file (if any).
 */
export const submitQAForm = async (formData) => {
  try {
    const token = localStorage.getItem('accessToken'); // Assuming you are storing the auth token in localStorage
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure this header is set for file uploads
        Authorization: `Bearer ${token}`, // Add the access token for auth if needed
      },
      timeout: 600000, // 10 minutes timeout for large uploads
    };

    // Make the POST request to your backend API endpoint
    const response = await axios.post(
      `${apiUrl}/api/qa/qa-form`,
      formData,
      config
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error('Error submitting QA Form:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const getQAreports = async () => {
  try {
    const response = await axios.get(`${apiUrl}/api/qa/reports`, accessToken);
    return response.data;
  } catch (error) {
    console.error('Error fetching reports', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const getQAreport = async (id) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/qa/report/${id}`,
      accessToken
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching reports', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};
