/** @format */

import axios from 'axios';
import { accessToken } from 'utils/accessToken';
const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

export const getUserProfile = async () => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/settings/user-details`,
      accessToken
    );
    return response.data;
  } catch (error) {
    console.error('Error getting global names list:', error);
  }
};

export const getProfilePic = async () => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/settings/profile-picture`,
      accessToken
    );
    return response.data;
  } catch (error) {
    console.error('Error getting global names list:', error);
  }
};
