/** @format */

import React from "react";
import AgentLayout from "components/agents/layout";

import AgentStats from "components/agents/widgets/U65Stats";

const AgentDashboard = () => {
  return (
    <AgentLayout>
      <AgentStats />
    </AgentLayout>
  );
};

export default AgentDashboard;
