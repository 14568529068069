/** @format */

import { createSlice } from '@reduxjs/toolkit';

// this is a button slice to manage the state of buttons
const buttonSlice = createSlice({
  name: 'Button',
  initialState: {
    ///// this is for the refinster user form
    addUserForm: false,
    userId: null,
    setting: false,
    formDisplay: 'table',
    profileUpload: false,
    upload: null,
    selectedFormId: null, // Add this line
    rank: null,
  },

  reducers: {
    toggleForm: (state, action) => {
      state.addUserForm = action.payload;
    },
    toggleSetting: (state, action) => {
      state.setting = action.payload;
    },
    toggleProfileUpload: (state, action) => {
      state.profileUpload = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userId = action.payload.userId;
    },
    toggleUpload: (state, action) => {
      state.upload = action.payload;
    },
    setSelectedFormId: (state, action) => {
      state.selectedFormId = action.payload; // Add this line
    },
    clearSelectedFormId: (state) => {
      state.selectedFormId = null; // Add this line
    },
    setFormDisplay: (state, action) => {
      state.formDisplay = action.payload;
    },
    setRank: (state, action) => {
      state.rank = action.payload;
    },
  },
});

export const {
  toggleForm,
  setUserProfile,
  toggleUpload,
  setFormDisplay,
  setSelectedFormId,
  clearSelectedFormId,
  toggleProfileUpload,
  toggleSetting,
  setRank,
} = buttonSlice.actions;

export default buttonSlice.reducer;
