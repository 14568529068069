/** @format */

import React, { useEffect, useState } from 'react';
import { Table, Spin, message } from 'antd';
import ListedLeadLog from 'api/ListedLeadsLogRoutes';
import { useSelector } from 'react-redux';
const ListedLeadLogsTable = ({ enpoint }) => {
  const [logs, setLogs] = useState([]);

  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const data = await ListedLeadLog.getLogsByDate(
          startDate,
          endDate,
          enpoint
        );
        setLogs(data);
      } catch (error) {
        message.error('Failed to fetch listed lead logs');
      }
    };
    fetchLogs();
  }, [startDate, endDate, enpoint]);

  // Define columns for the Ant Design Table component
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      render: (text) => `$${text.toFixed(2)}`, // Format as currency
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => new Date(text).toLocaleDateString(),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={logs}
        rowKey='id'
        pagination={{
          current: currentPage,
          pageSize: pageSize, // Controlled by a state variable for dynamic page size
          pageSizeOptions: ['10', '20', '50', '100'], // Options for selectable page sizes
          showSizeChanger: true, // Enables the page size dropdown
          onShowSizeChange: (current, size) => setPageSize(size), // Updates page size
          onChange: (page) => setCurrentPage(page),
        }}
      />
    </div>
  );
};

export default ListedLeadLogsTable;
