/** @format */

export const calculateGrade = (pointDeductions) => {
  // Base score of 100 points
  const baseScore = 100;

  // Calculate total deduction percentage
  const totalDeduction = pointDeductions.reduce(
    (acc, item) => acc + item.percentage,
    0
  );

  // Final points after deductions
  const points = baseScore - totalDeduction;

  // Determine the letter grade and color
  let grade = {};
  switch (true) {
    case points >= 97:
      grade = { letter: 'A+', color: '#4CAF50' };
      break;
    case points >= 93 && points <= 96:
      grade = { letter: 'A', color: '#66BB6A' };
      break;
    case points >= 90 && points <= 92:
      grade = { letter: 'A-', color: '#81C784' };
      break;
    case points >= 87 && points <= 89:
      grade = { letter: 'B+', color: '#AED581' };
      break;
    case points >= 83 && points <= 86:
      grade = { letter: 'B', color: '#DCE775' };
      break;
    case points >= 80 && points <= 82:
      grade = { letter: 'B-', color: '#FFF176' };
      break;
    case points >= 77 && points <= 79:
      grade = { letter: 'C+', color: '#FFD54F' };
      break;
    case points >= 73 && points <= 76:
      grade = { letter: 'C', color: '#FFB74D' };
      break;
    case points >= 70 && points <= 72:
      grade = { letter: 'C-', color: '#FF8A65' };
      break;
    case points >= 67 && points <= 69:
      grade = { letter: 'D+', color: '#FF7043' };
      break;
    case points >= 63 && points <= 66:
      grade = { letter: 'D', color: '#F4511E' };
      break;
    case points >= 60 && points <= 62:
      grade = { letter: 'D-', color: '#E64A19' };
      break;
    default:
      grade = { letter: 'F', color: '#D32F2F' };
      break;
  }

  return { points, grade };
};
