/** @format */
import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getGlobalVendors } from 'api/vendorsAndCamapignRoutes'; // Import the API call
import moment from 'moment';

const GlobalVendorsTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  useEffect(() => {
    const fetchVendors = async () => {
      setLoading(true);
      const vendors = await getGlobalVendors();
      setData(vendors || []); // Set the fetched data
      setLoading(false);
    };
    fetchVendors();
  }, []);

  // Search functionality
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  // Define columns for the Ant Design Table
  const columns = [
    {
      title: 'Global Name',
      dataIndex: 'globalName',
      key: 'globalName',
      ...getColumnSearchProps('globalName'), // Add search props here
      sorter: (a, b) => a.globalName.localeCompare(b.globalName), // Alphabetical sorting
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  // Map the data to match the columns
  const formattedData = data.map((item) => ({
    key: item._id, // Set a unique key for each row
    globalName: item.globalName,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
  }));

  return (
    <Table
      columns={columns}
      dataSource={formattedData}
      loading={loading}
      pagination={{ pageSize: 100 }}
      rowKey='_id'
    />
  );
};

export default GlobalVendorsTable;
