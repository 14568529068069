/** @format */

import React from "react";
import SuperAdminLayout from "../../components/super-admin/layout";
import { useDispatch, useSelector } from "react-redux";
import OfficeCard from "components/super-admin/widgets/officeCard";
//import { officeId } from 'state/slice/viewOfficeAction';
import OfficeProfile from "components/super-admin/widgets/officeProfile";
import { setForm } from "state/slice/tabSlice"; // Adjust path as necessary
import MultiPartForm from "components/super-admin/widgets/newOfficeForm";

const Offices = () => {
  const dispatch = useDispatch();
  const activeForm = useSelector((state) => state.activeTab.activeForm);
  const selectedOfficeId = useSelector(
    (state) => state.viewOffice.selectedOffice
  );

  ///// this is to handle the office form based on which office is active
  const handleNewOffice = () => {
    dispatch(setForm(true));
  };

  return (
    <SuperAdminLayout>
      <div style={{ marginBottom: "25px" }} className="office-tab-container">
        {/** this is to set the office card that will be displayed */}
        <div className="office-type-container">
          <span className={"active"}>Affiliates Offices</span>
        </div>
        <div>
          <div className="add-office">
            <span onClick={handleNewOffice}>New Affiliate Office +</span>
          </div>
        </div>
      </div>
      {/** this renders the office card based on the active office  */}

      <OfficeCard />

      <div
        style={{
          display: activeForm === true ? "block" : "none",
        }}>
        <MultiPartForm />
      </div>

      <OfficeProfile officeId={selectedOfficeId} />
    </SuperAdminLayout>
  );
};

export default Offices;
