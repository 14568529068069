/** @format */

// userRoutes.js

import axios from 'axios';
const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

/**
 * Fetches user data from the backend for a given user ID.
 *
 * @param {string} userId - The ID of the user to fetch.
 * @returns {Promise<Object>} - A promise that resolves to the user data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getUser = async (userId) => {
  try {
    const token = localStorage.getItem('accessToken');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.get(
      `${apiUrl}/api/super-admin/user/${userId}`,
      config
    );

    return response.data; // Assuming the backend returns the user data in the response body
  } catch (error) {
    console.error('Error fetching user:', error.message);
    throw error; // Re-throw the error so it can be handled by the caller
  }
};

/**
 * Updates the details of a user with the given userId.
 *
 * @param {string} userId - The ID of the user to update.
 * @param {Object} updateData - The data to update the user with.
 * @returns {Promise<Object>} - A promise that resolves to the updated user data.
 * @throws {Error} - Throws an error if the update operation fails.
 */
export const updateUserDetails = async (userId, updateData) => {
  try {
    const token = localStorage.getItem('accessToken');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.put(
      `${apiUrl}/api/super-admin/user/${userId}`,
      updateData,
      config
    );

    return response.data; // Assuming the backend returns the updated user data
  } catch (error) {
    console.error('Error updating user:', error.message);
    throw error; // Re-throw the error so it can be handled by the caller
  }
};

/**
 * Fetches all users where officeId is null.
 *
 * @returns {Promise<Array>} - A promise that resolves to an array of users with null officeId.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getUsersWithNullOfficeId = async () => {
  try {
    const token = localStorage.getItem('accessToken');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.get(
      `${apiUrl}/api/super-admin/users/no-office`,
      config
    );

    return response.data; // Assuming the backend returns the list of users in the response body
  } catch (error) {
    console.error('Error fetching users with null officeId:', error.message);
    throw error; // Re-throw the error so it can be handled by the caller
  }
};

/**
 * Deletes a user from the system.
 *
 * @param {string} userId - The unique identifier of the user to be deleted.
 * @returns {Promise<Object>} The response data from the delete operation.
 * @throws Will throw an error if the delete operation fails.
 */
export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/api/super-admin/delete-user`,
      {
        data: {
          userId,
        },
      }
    );
    return response.data; // Return the response if needed
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

/**
 * Searches for users based on a query string.
 *
 * @param {string} query - The search term to find users.
 * @returns {Promise<Array>} The list of users that match the search term.
 * @throws Will throw an error if the search operation fails.
 */
export const searchUsers = async (query) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(`${apiUrl}/api/super-admin/users/search`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        query,
      },
    });
    return response.data; // Return the filtered users
  } catch (error) {
    console.error('Error searching users:', error);
    throw error;
  }
};
