import axios from "axios";

const setupInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      // If the response is successful, just return the response
      return response;
    },
    (error) => {
      // Check if the error is due to an expired session or unauthorized error
      if (
        error.response &&
        (error.response.status === 401 || // Standard unauthorized error
          (error.response.status === 400 &&
            error.response.data.__type === "NotAuthorizedException")) // Cognito-specific error
      ) {
        console.error(
          "Session expired or unauthorized. Redirecting to sign-in."
        );
        window.location.href = "/signin"; // Redirect to sign-in page
      } else {
        // Handle other errors
        return Promise.reject(error);
      }
    }
  );
};

export default setupInterceptors;
