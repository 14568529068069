/** @format */

import React from "react";

import SalesSummary from "components/super-admin/widgets/salesSummary";
import SuperAdminLayout from "../../components/super-admin/layout";

const Dashboard = () => {
  return (
    <SuperAdminLayout>
      <SalesSummary enpoint={"api/stats/super-admin/sales-summary"} />
    </SuperAdminLayout>
  );
};

export default Dashboard;
