/** @format */

import React, { useState, useEffect } from 'react';
import { DatePicker, Select, Space, TimePicker } from 'antd';
import { useDispatch } from 'react-redux';
import { setStartDate, setEndDate } from 'state/slice/dropDownSlice';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const { Option } = Select;
const { RangePicker } = DatePicker;

const PickerWithType = ({ type, onChange }) => {
  if (type === 'time') return <TimePicker onChange={onChange} />;
  if (type === 'date') return <DatePicker onChange={onChange} />;
  if (type === 'week') return <RangePicker picker='week' onChange={onChange} />;
  if (type === 'month')
    return <RangePicker picker='month' onChange={onChange} />;
  if (type === 'quarter')
    return <RangePicker picker='quarter' onChange={onChange} />;
  if (type === 'year') return <RangePicker picker='year' onChange={onChange} />;
  return <DatePicker picker={type} onChange={onChange} />;
};

const DateTimePicker = () => {
  const [type, setType] = useState('date');
  const dispatch = useDispatch();

  const handleDateChange = (dates) => {
    if (Array.isArray(dates) && dates.length === 2) {
      const [start, end] = dates;

      // Adjust to local time explicitly and then convert to ISO format
      const startDate = dayjs(start)
        .startOf('day')
        .add(dayjs().utcOffset(), 'minute')
        .toISOString();
      const endDate = dayjs(end)
        .endOf('day')
        .add(dayjs().utcOffset(), 'minute')
        .toISOString();

      console.log('Adjusted Start Date:', startDate);
      console.log('Adjusted End Date:', endDate);

      dispatch(setStartDate({ startDate }));
      dispatch(setEndDate({ endDate }));
    } else if (dates) {
      const startDate = dayjs(dates)
        .startOf('day')
        .add(dayjs().utcOffset(), 'minute')
        .toISOString();
      const endDate = dayjs(dates)
        .endOf('day')
        .add(dayjs().utcOffset(), 'minute')
        .toISOString();

      console.log('Single Date Adjusted Start:', startDate);
      console.log('Single Date Adjusted End:', endDate);

      dispatch(setStartDate({ startDate }));
      dispatch(setEndDate({ endDate }));
    } else {
      const todayStart = dayjs()
        .startOf('day')
        .add(dayjs().utcOffset(), 'minute')
        .toISOString();
      const todayEnd = dayjs()
        .endOf('day')
        .add(dayjs().utcOffset(), 'minute')
        .toISOString();

      console.log('Today Adjusted Start:', todayStart);
      console.log('Today Adjusted End:', todayEnd);

      dispatch(setStartDate({ startDate: todayStart }));
      dispatch(setEndDate({ endDate: todayEnd }));
    }
  };

  useEffect(() => {
    return () => {
      const todayStart = dayjs()
        .startOf('day')
        .add(dayjs().utcOffset(), 'minute')
        .toISOString();
      const todayEnd = dayjs()
        .endOf('day')
        .add(dayjs().utcOffset(), 'minute')
        .toISOString();
      dispatch(setStartDate({ startDate: todayStart }));
      dispatch(setEndDate({ endDate: todayEnd }));
    };
  }, [dispatch]);

  return (
    <Space>
      <Select value={type} onChange={setType}>
        <Option value='time'>Time</Option>
        <Option value='date'>Date</Option>
        <Option value='week'>Week</Option>
        <Option value='month'>Month</Option>
        <Option value='quarter'>Quarter</Option>
        <Option value='year'>Year</Option>
      </Select>
      {type === 'date' ? (
        <RangePicker onChange={handleDateChange} />
      ) : (
        <PickerWithType type={type} onChange={handleDateChange} />
      )}
    </Space>
  );
};

export default DateTimePicker;
