/** @format */

import React, { useState } from 'react';
import { Select, Input, message } from 'antd';
import SuperAdminLayout from 'components/super-admin/layout';
import Filter from 'img/ArrowsDownUp.svg';
import { useSelector } from 'react-redux';
import CustomerTable from 'components/widgets/customerDirectoryTable';
import OfficeNames from 'components/super-admin/widgets/officeNames';
import AdminLayout from 'components/admin/layout';
import CustomerDetails from 'components/customerDetails';

import { searchCustomer, searchCustomerbyOfficeId } from 'api/customerRoute';
const { Option } = Select;
const { Search } = Input;

const CustomerDirectory = () => {
  const [filterBy, setFilterBy] = useState('Office');
  const [customers, setCustomers] = useState([]); // To store search results
  const [loading, setLoading] = useState(false); // Loading state for search
  const selectedFormId = useSelector((state) => state.button.selectedFormId);
  const { userAccess } = useSelector((state) => state.user);
  const { officeId } = useSelector((state) => state.user.userData);
  // Handle filter change between "Office" and "No Office"
  const handleFilterChange = (value) => {
    setFilterBy(value);
  };

  // Handle search for customer
  const handleSearch = async (query) => {
    // Check if query is provided
    if (!query) {
      message.warning('Please enter a search query');
      return;
    }

    setLoading(true); // Set loading state to true

    try {
      let results;

      // Check if user has officeId, then use the appropriate search function
      if (officeId) {
        results = await searchCustomerbyOfficeId(query); // Assuming officeId is required for search
      } else {
        results = await searchCustomer(query); // General customer search if officeId is not required
      }

      // Update the customers state with the search results
      setCustomers(results);

      // Display message if no customers are found
      if (results.length === 0) {
        message.info('No customers found');
      }
    } catch (error) {
      // Handle any errors that occur during the search
      message.error('No customers Found');
      console.error('Search error:', error);
    } finally {
      setLoading(false); // Set loading state to false after the search completes
    }
  };

  return (
    <AdminLayout>
      <div style={{ position: 'relative' }}>
        <div className='filter-container'>
          <div className='filter'>
            <img src={Filter} alt='Filter' />
          </div>

          {/* Search Input using Ant Design */}
          <div style={{ width: '200px' }}>
            <Search
              placeholder='Search Customer'
              enterButton='Search'
              onSearch={handleSearch} // Trigger search on enter
              loading={loading}
              style={{ marginLeft: 10 }}
            />
          </div>
        </div>
        {selectedFormId && <CustomerDetails />}
        {/* Pass customers and loading state to CustomerTable */}
        {!selectedFormId && (
          <CustomerTable customers={customers} loading={loading} />
        )}
      </div>
    </AdminLayout>
  );
};

export default CustomerDirectory;
