// components/admin/widgets/FormDetails.js
import React, { useEffect, useState } from "react";
import backArrow from "img/ArrowLeft.svg";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedFormId } from "state/slice/buttonSlice";
import axios from "axios";
import { Descriptions } from "antd";
import moment from "moment";
const FormDetails = () => {
  const [formDetails, setFormDetails] = useState(null);
  const selectedFormId = useSelector((state) => state.button.selectedFormId);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Use the environment variable for the API URL
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchFormDetails = async () => {
      if (selectedFormId) {
        try {
          const response = await axios.get(
            `${apiUrl}/api/aca/request-form/form/${selectedFormId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
          setFormDetails(response.data);
        } catch (error) {
          console.error("Error fetching form details:", error);
        }
      }
    };

    fetchFormDetails();
  }, [selectedFormId, apiUrl]);
  const handleFormView = (formId) => {
    dispatch(setSelectedFormId(formId));
    // Additional logic for form view if needed
  };
  if (!formDetails) {
    return <div>No form selected.</div>;
  }

  return (
    <div className="form-details-container">
      <button
        className="white-back-button"
        style={{ marginBottom: "20px" }}
        onClick={() => handleFormView(null)}>
        <img src={backArrow} alt="back Arrow" />
        <span>Back</span>
      </button>
      <h2>Form Details</h2>
      <div className="form-details-section">
        <Descriptions title="Personal Information" bordered column={1}>
          <Descriptions.Item label="First Name">
            {formDetails.firstName}
          </Descriptions.Item>
          <Descriptions.Item label="Last Name">
            {formDetails.lastName}
          </Descriptions.Item>
          <Descriptions.Item label="Date of Birth">
            {new Date(formDetails.dateOfBirth).toLocaleString("en-US", {
              timeZone: "UTC",
            })}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {formDetails.phoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Best Phone Number">
            {formDetails.bestPhoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="SSN">{formDetails.SSN}</Descriptions.Item>
        </Descriptions>
      </div>
      <div className="form-details-section">
        <Descriptions title="Spouse Information" bordered column={1}>
          <Descriptions.Item label="Spouse First Name">
            {formDetails.spouseFirstName}
          </Descriptions.Item>
          <Descriptions.Item label="Spouse Last Name">
            {formDetails.spouseLastName}
          </Descriptions.Item>
          <Descriptions.Item label="Spouse Date of Birth">
            {formDetails.spouseDOB
              ? new Date(formDetails.spouseDOB).toLocaleString("en-US", {
                  timeZone: "UTC",
                })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Spouse SSN">
            {formDetails.spouseSSN}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="form-details-section">
        <Descriptions title="Address" bordered column={1}>
          <Descriptions.Item label="Street">
            {formDetails.street}
          </Descriptions.Item>
          <Descriptions.Item label="Apt/Unit">
            {formDetails.aptUnite}
          </Descriptions.Item>
          <Descriptions.Item label="City">{formDetails.city}</Descriptions.Item>
          <Descriptions.Item label="State">
            {formDetails.state}
          </Descriptions.Item>
          <Descriptions.Item label="Zip Code">
            {formDetails.zipCode}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="form-details-section">
        <Descriptions title="Plan Information" bordered column={1}>
          <Descriptions.Item label="Plan Name">
            {formDetails.planName}
          </Descriptions.Item>
          <Descriptions.Item label="AOR">{formDetails.AOR}</Descriptions.Item>
          <Descriptions.Item label=" agentName">
            {formDetails.agentName}
          </Descriptions.Item>
          <Descriptions.Item label="householdIncome">
            {formDetails.householdIncome}
          </Descriptions.Item>
          <Descriptions.Item label="Carrier">
            {formDetails.carrier}
          </Descriptions.Item>
          <Descriptions.Item label="QLE">{formDetails.QLE}</Descriptions.Item>
          <Descriptions.Item label="Health Sherpa">
            {formDetails.healthSherpa}
          </Descriptions.Item>
          <Descriptions.Item label="Today's Date">
            {new Date(formDetails.todaysDate).toLocaleString("en-US", {
              timeZone: "UTC",
            })}
          </Descriptions.Item>
          <Descriptions.Item label="Private Premium">
            {formDetails.privatePremium}
          </Descriptions.Item>
          <Descriptions.Item label="Total Premium">
            {formDetails.totalPremium}
          </Descriptions.Item>
          <Descriptions.Item label="Benefits URL">
            {formDetails.benefitsURL}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="form-details-section">
        <Descriptions title="Dependents" bordered column={1}>
          {formDetails.dependentsInfo &&
          formDetails.dependentsInfo.length > 0 ? (
            formDetails.dependentsInfo.map((dependent, index) => (
              <Descriptions.Item key={index} label={`Dependent ${index + 1}`}>
                {`${dependent.firstName} ${dependent.lastName}, DOB: ${new Date(
                  dependent.DOB
                ).toLocaleDateString()}, SSN: ${dependent.SSN}`}
              </Descriptions.Item>
            ))
          ) : (
            <Descriptions.Item>No dependents</Descriptions.Item>
          )}
        </Descriptions>
      </div>
      <div className="form-details-section">
        <Descriptions title="Notes" bordered column={1}>
          <Descriptions.Item label="Notes">
            {formDetails.notes
              ? formDetails.notes
                  .split("\n")
                  .map((note, index) => <div key={index}>{note}</div>)
              : "No notes available"}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default FormDetails;
