/** @format */

import React from "react";

import AdminLayout from "components/admin/layout";

import AdminU65Stats from "components/admin/widgets/adminSummaryStats";
const AdminDashboard = () => {
  return (
    <AdminLayout>
      <AdminU65Stats enpoint={"api/stats/admin/sales-summary"} />
    </AdminLayout>
  );
};

export default AdminDashboard;
