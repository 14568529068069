import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Leads and Conversions",
    },
    labels: {
      color: "#1C1C1C66",
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        color: "#1C1C1C66", // Custom label color for x-axis
      },
      border: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: "#1C1C1C66", // Custom label color for x-axis
      },
    },
  },
};

const MonthlyLeadsAndSalesChart = ({ endpoint }) => {
  const [chartData, setChartData] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Total Sales",
        data: Array(12).fill(0),
        backgroundColor: "#95A4FC",
        barThickness: 28,
        borderRadius: 5,
      },
      {
        label: "Total Billable Leads",
        data: Array(12).fill(0),
        backgroundColor: "rgba(149, 164, 252, .5)",
        barThickness: 28,
        borderRadius: 5,
      },
    ],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMonthlySummary = async () => {
      const token = localStorage.getItem("accessToken");

      try {
        const response = await axios.get(`${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;
        if (!data || !Array.isArray(data)) {
          throw new Error("Invalid data format");
        }

        const totalSalesData = data.map((item) => item.totalSales || 0);
        const totalBillableLeadsData = data.map(
          (item) => item.totalBillableLeads || 0
        );

        console.log("Fetched Total Sales Data:", totalSalesData);
        console.log(
          "Fetched Total Billable Leads Data:",
          totalBillableLeadsData
        );

        setChartData((prevState) => ({
          ...prevState,
          datasets: [
            {
              ...prevState.datasets[0],
              data: totalSalesData,
            },
            {
              ...prevState.datasets[1],
              data: totalBillableLeadsData,
            },
          ],
        }));
      } catch (err) {
        setError("Error fetching monthly summary data");
      } finally {
        setLoading(false);
      }
    };

    fetchMonthlySummary();
  }, [endpoint]);

  if (loading)
    return (
      <div className="chart-container">
        <Skeleton height={200} />
      </div>
    );
  if (error) return <p>{error}</p>;

  return (
    <div className="l-vs-c-r-container">
      <div>
        <span>Leads vs Conversion Rate</span>
      </div>
      <Bar data={chartData} width={800} options={options} />
      <div className="monthly-bar-legend">
        <div>
          <div className="lead-indicator"></div>
          <span>Lead</span>
        </div>
        <div>
          <div className="conversion-indicator"></div>
          <span>Conversion Rate</span>
        </div>
      </div>
    </div>
  );
};

export default MonthlyLeadsAndSalesChart;
