/** @format */
import axios from 'axios';
import { accessToken } from 'utils/accessToken';
const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

export const getMonthlySummary = async (userId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/stats/agent/monthly/sales-premium/${userId}`,
      accessToken
    );
    return response.data;
  } catch (error) {
    console.error('Error getting global names list:', error);
  }
};
