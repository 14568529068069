/** @format */

import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Space, message, Select, Popconfirm } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import { io } from 'socket.io-client';

const { Option } = Select;

const U65AdminCampaignTable = ({ path }) => {
  const [vendors, setVendors] = useState([]);
  const [globalVendors, setGlobalVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editedVendor, setEditedVendor] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100); // Default page size is 100
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

  useEffect(() => {
    const fetchVendors = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const [vendorResponse, globalVendorResponse] = await Promise.all([
          axios.get(`${apiUrl}/api/${path}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }),
          axios.get(`${apiUrl}/vendors/global-list`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }),
        ]);

        setVendors(vendorResponse.data);
        setGlobalVendors(globalVendorResponse.data);
        console.log("Success!! Here's the data:", vendorResponse.data);
      } catch (error) {
        console.error('Error fetching vendors:', error);
        setError('Failed to load vendors.');
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch vendors and global vendors on component mount
    fetchVendors();

    const socket = io(apiUrl);

    // Listen for the vendorRegistered event
    socket.on('vendorRegistered', (data) => {
      setVendors((prevVendors) => [...prevVendors, ...data.data]);
    });

    return () => {
      socket.off('vendorRegistered');
      socket.disconnect();
    };
  }, [apiUrl, path]);
  const handleEdit = (id) => {
    setEditId(id);
    const vendorToEdit = vendors.find((vendor) => vendor._id === id);
    setEditedVendor({ ...vendorToEdit });
  };

  const handleSave = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      await axios.put(
        `${apiUrl}/api/U65Vendors/admin/vendors-update/${editedVendor._id}`,
        editedVendor,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedVendors = vendors.map((vendor) =>
        vendor._id === editedVendor._id ? editedVendor : vendor
      );
      setVendors(updatedVendors);
      setEditId(null);
      setEditedVendor({});
      message.success('Vendor updated successfully');
    } catch (error) {
      console.error('Error saving vendor data:', error);
      setError('Failed to save vendor data');
      message.error('Failed to save vendor data');
    }
  };
  const handleDelete = async (id) => {
    const token = localStorage.getItem('accessToken');
    try {
      await axios.delete(
        `${apiUrl}/api/U65Vendors/admin/delete-vendors/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setVendors(vendors.filter((vendor) => vendor._id !== id));
      message.success('Vendor deleted successfully');
    } catch (error) {
      console.error('Error deleting vendor:', error);
      message.error('Failed to delete vendor');
    }
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const parsedValue = type === 'number' ? parseFloat(value) : value;
    setEditedVendor({
      ...editedVendor,
      [name]: parsedValue,
    });
  };

  const handleSelectChange = (value, option) => {
    const selectedVendor = globalVendors.find(
      (vendor) => vendor._id === option.key
    );
    setEditedVendor({
      ...editedVendor,
      globalName: selectedVendor.globalName,
      globalId: selectedVendor._id, // Store the ID in the globalId field
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      key: 'vendorName',
      ...getColumnSearchProps('vendorName'),
      sorter: (a, b) => a.vendorName.localeCompare(b.vendorName), // Alphabetical sorting
      render: (text, record) =>
        editId === record._id ? (
          <Input
            name='vendorName'
            value={editedVendor.vendorName}
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',

      render: (text, record) =>
        editId === record._id ? (
          <Input
            name='cost'
            value={editedVendor.cost}
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Vendor ID',
      dataIndex: 'queueId',
      key: 'queueId',
      ...getColumnSearchProps('queueId'),
      sorter: (a, b) => a.queueId.localeCompare(b.queueId), // Alphabetical sorting
      render: (text, record) =>
        editId === record._id ? (
          <Input
            name='queueId'
            value={editedVendor.queueId}
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Buffer',
      dataIndex: 'buffer',
      key: 'buffer',
      sorter: (a, b) => a.buffer.localeCompare(b.buffer),
      render: (text, record) =>
        editId === record._id ? (
          <Input
            name='buffer'
            value={editedVendor.buffer}
            onChange={handleChange}
          />
        ) : (
          text
        ),
    },
    {
      title: 'Global Name',
      dataIndex: 'globalName',
      key: 'globalName',
      sorter: (a, b) => a.globalName.localeCompare(b.globalName),
      render: (text, record) =>
        editId === record._id ? (
          <Select
            showSearch // Enable search within the dropdown
            placeholder='Select Global Name'
            optionFilterProp='children'
            value={editedVendor.globalName}
            onChange={handleSelectChange}
            style={{ width: 200 }}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {globalVendors.map((vendor) => (
              <Option key={vendor._id} value={vendor.globalName}>
                {vendor.globalName}
              </Option>
            ))}
          </Select>
        ) : (
          text
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) =>
        editId === record._id ? (
          <Space>
            <Button type='primary' onClick={handleSave}>
              Save
            </Button>
            <Popconfirm
              title='Are you sure you want to delete this vendor?'
              onConfirm={() => handleDelete(record._id)}
              okText='Yes'
              cancelText='No'>
              <Button type='danger'>Delete</Button>
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <Button onClick={() => handleEdit(record._id)}>Edit</Button>
            <Popconfirm
              title='Are you sure you want to delete this vendor?'
              onConfirm={() => handleDelete(record._id)}
              okText='Yes'
              cancelText='No'>
              <Button type='danger'>Delete</Button>
            </Popconfirm>
          </Space>
        ),
    },
  ];

  return (
    <div className='campaign-table' style={{ overflowX: 'auto' }}>
      {isLoading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {!isLoading && !error && (
        <Table
          columns={columns}
          dataSource={vendors}
          rowKey={(record) => record._id}
          pagination={{
            current: currentPage,
            pageSize: pageSize, // Controlled by a state variable for dynamic page size
            pageSizeOptions: ['10', '20', '50', '100'], // Options for selectable page sizes
            showSizeChanger: true, // Enables the page size dropdown
            onShowSizeChange: (current, size) => setPageSize(size), // Updates page size
            onChange: (page) => setCurrentPage(page),
          }}
        />
      )}
    </div>
  );
};

export default U65AdminCampaignTable;
