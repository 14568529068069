/** @format */

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { toggleUpload } from "state/slice/tabSlice"; // Assuming 'toggleUpload' action creator is correctly imported
import SuperAdminLayout from '../../components/super-admin/layout';
import Filter from 'img/ArrowsDownUp.svg';
import ListedLeads from 'components/listedLeads';
import AddListedLeads from 'components/super-admin/widgets/addListedLeadsForm';
import U65AdminCampaignTable from 'components/admin/widgets/U65CampaignTable';
import ListedLeadLogsTable from 'components/listedLeadLogTable';
import { toggleCampaignsForm } from 'state/slice/tabSlice';
import { toggleListedLeadForm } from 'state/slice/tabSlice';
import CallLogsTable from 'components/admin/widgets/callLogs';
import AddCampaignsForm from 'components/super-admin/widgets/addCampaignForm';
import CampaignPerformance from 'components/widgets/campaignPerformace';
import { ToastContainer } from 'react-toastify';
import OfficeNames from 'components/super-admin/widgets/officeNames';
import DownArrow from 'img/ArrowLineDown.png';
import { Select } from 'antd';

const { Option } = Select;
const CampaignOverview = () => {
  const selectedOfficeId = useSelector((state) => state.dropDown.officeId);
  const dispatch = useDispatch();

  const campaign = useSelector((state) => state.activeTab.addCampaigns);
  const handleVendorsFrom = (bool) => {
    dispatch(toggleCampaignsForm(bool));
  };

  const handleListedLeadsForm = (bool) => {
    dispatch(toggleListedLeadForm(bool));
  };
  //const upload = useSelector((state) => state.activeTab.upload);
  const listedLeads = useSelector((state) => state.activeTab.addListedLeads);
  const [activeTab, setActiveTab] = useState('Overview');
  const [filterBy, setFilterBy] = useState('No Office');

  const handleFilterChange = (value) => {
    setFilterBy(value);
  };

  /**if any point we might have to upload callLogs */
  /* const handleUpload = (bool) => {
    dispatch(toggleUpload(bool)); 
  };*/
  const handleTab = (tab) => {
    setActiveTab(tab);
  };

  const getEndpoint = (tab, path1, path2) => {
    if (activeTab === tab && filterBy === 'Office') {
      return path1;
    } else {
      return path2;
    }
  };

  return (
    <SuperAdminLayout>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div className='campaign-header-containger'>
          <div className='campaign-tab-wrapper'>
            <span
              onClick={() => handleTab('Overview')}
              className={activeTab === 'Overview' ? 'active' : ''}>
              Campaign Overview
            </span>
            <span
              onClick={() => handleTab('Listed Leads')}
              className={activeTab === 'Listed Leads' ? 'active' : ''}>
              Listed Leads
            </span>
            <span
              onClick={() => handleTab('Performance')}
              className={activeTab === 'Performance' ? 'active' : ''}>
              Campaign Performance
            </span>
            <span
              onClick={() => handleTab('Logs')}
              className={activeTab === 'Logs' ? 'active' : ''}>
              Call Logs
            </span>

            <span
              onClick={() => handleTab('Listed Leads Logs')}
              className={activeTab === 'Listed Leads Logs' ? 'active' : ''}>
              Lead Logs
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            {/**  <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
                cursor: "pointer ",
              }}>
              <span onClick={() => handleUpload(true)}>Upload Logs</span>{" "}
              <img src={DownArrow} alt="down arrow" />
            </div>*/}

            {/* Corrected onClick handler */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2px',
                cursor: 'pointer ',
              }}>
              <span onClick={() => handleVendorsFrom(true)}>Add Campaigns</span>{' '}
              <img src={DownArrow} alt='down arrow' />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '2px',
                cursor: 'pointer ',
              }}>
              <span onClick={() => handleListedLeadsForm(true)}>
                Add Listed Leads
              </span>{' '}
              <img src={DownArrow} alt='down arrow' />
            </div>
          </div>
        </div>
        <div className='filter-container'>
          <div className='filter'>
            <img src={Filter} alt='Filter' />
            <span>Filter By </span>
            <div className='a-p-tab-wrapper'>
              {/* Dropdown to select between "Office" and "No Office" */}
              <Select
                value={filterBy}
                onChange={handleFilterChange}
                style={{ width: 150 }}>
                <Option value='Office'>Office</Option>
                <Option value='No Office'>All Office</Option>
              </Select>
              {filterBy === 'Office' && <OfficeNames />}
            </div>
          </div>

          {/* Search Input using Ant Design */}
        </div>
        {activeTab === 'Overview' && (
          <U65AdminCampaignTable
            path={getEndpoint(
              'Overview',
              `U65Vendors/super-admin/vendors-list/${selectedOfficeId}`,
              'U65Vendors/super-admin/vendors'
            )}
          />
        )}
        {activeTab === 'Performance' && (
          <CampaignPerformance
            path={getEndpoint(
              'Performance',
              `super-admin/overview/campaign-performance/${selectedOfficeId}`,
              'super-admin/overview/all/campaign-performance'
            )}
          />
        )}
        {activeTab === 'Logs' && (
          <CallLogsTable
            path={getEndpoint(
              'Logs',
              `super-admin/overview/call-logs/${selectedOfficeId}`,
              'super-admin/overview/call-logs'
            )}
          />
        )}
        {activeTab === 'Listed Leads' && (
          <ListedLeads
            path={getEndpoint(
              'Listed Leads',
              `api/leads/listed-leads/office/${selectedOfficeId}`,
              'api/leads/all-listed-Leads'
            )}
          />
        )}

        {activeTab === 'Listed Leads Logs' && (
          <ListedLeadLogsTable
            enpoint={getEndpoint(
              'Listed Leads Logs',
              `listed-logs/logs/office/${selectedOfficeId}`,
              'listed-logs/logs/by-date'
            )}
          />
        )}
        {/**  {upload && <FileDrop />}{" "}*/}
        {/* Render FileDrop component if upload state is true */}
        {campaign && <AddCampaignsForm />}
        {listedLeads && <AddListedLeads />}
        <ToastContainer />
      </div>
    </SuperAdminLayout>
  );
};

export default CampaignOverview;
