/** @format */

import React from 'react';
import { Table, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setReportId } from 'state/slice/dropDownSlice';

const QAReportTable = ({ reports }) => {
  const dispatch = useDispatch();

  // Handle menu click for user actions
  const handleReportView = (id) => {
    dispatch(setReportId(id));
    console.log(id);
    // Additional logic for form view if needed
  };

  const columns = [
    {
      title: 'Lead ID',
      dataIndex: 'LeadId', // Matches the JSON key exactly
      key: 'LeadId',
    },
    {
      title: 'Call Date',
      dataIndex: 'dateOfCall', // Matches the JSON key for dateOfCall
      key: 'dateOfCall',
      render: (text) => (text ? new Date(text).toLocaleDateString() : 'N/A'), // Format date for readability
    },
    {
      title: 'Member ID',
      dataIndex: 'memberId', // Matches the JSON key for memberId
      key: 'memberId',
      render: (text) => (text ? text : 'N/A'), // Display 'N/A' if memberId is null or undefined
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentId', // Accessing nested field 'agentId.fullName'
      key: 'agentName',
      render: (agentId) => (agentId ? agentId.fullName : 'Unknown Agent'), // Display fullName if available
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item
              key='viewUser'
              onClick={() => handleReportView(record._id)}>
              View Customer
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <EllipsisOutlined style={{ fontSize: 24, cursor: 'pointer' }} />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={reports}
      columns={columns}
      rowKey={(record) => record._id}
      pagination={{ pageSize: 50 }}
    />
  );
};

export default QAReportTable;
