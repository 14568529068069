/** @format */

import React from 'react';
import AdminLayout from 'components/admin/layout';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCampaignsForm } from 'state/slice/tabSlice';
import AddVendorsForm from 'components/admin/widgets/addU65Vendors';
import CallLogsTable from 'components/admin/widgets/callLogs';
import U65AdminCampaignTable from 'components/admin/widgets/U65CampaignTable';
import ListedLeadLogsTable from 'components/listedLeadLogTable';
import DownArrow from 'img/ArrowLineDown.png';
import { ToastContainer } from 'react-toastify';
import CampaignPerformance from 'components/widgets/campaignPerformace';
const U65CampgaignOverview = () => {
  const [activeTab, setActiveTab] = useState('Overview');
  const handleTab = (tab) => {
    setActiveTab(tab);
  };
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.activeTab.addCampaigns);
  const handleVendorsFrom = (bool) => {
    dispatch(toggleCampaignsForm(bool));
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
      <div className='campaign-header-containger'>
        <div className='campaign-tab-wrapper'>
          <span
            onClick={() => handleTab('Overview')}
            className={activeTab === 'Overview' ? 'active' : ''}>
            Campaign Overview
          </span>
          <span
            onClick={() => handleTab('Performance')}
            className={activeTab === 'Performance' ? 'active' : ''}>
            Campaign Performance
          </span>
          <span
            onClick={() => handleTab('Logs')}
            className={activeTab === 'Logs' ? 'active' : ''}>
            Call Logs
          </span>
          <span
            onClick={() => handleTab('Listed Leads Logs')}
            className={activeTab === 'Listed Leads Logs' ? 'active' : ''}>
            Lead Logs
          </span>
        </div>
        {/**<div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2px",
            cursor: "pointer ",
          }}>
          <span onClick={() => handleVendorsFrom(true)}>Add Vendors</span>{" "}
          <img src={DownArrow} alt="down arrow" />
        </div> */}
      </div>
      {activeTab === 'Overview' && (
        <U65AdminCampaignTable path={'U65Vendors/admin/vendors-list'} />
      )}
      {activeTab === 'Performance' && (
        <CampaignPerformance path='performance/overview/campaign-performance' />
      )}
      {activeTab === 'Logs' && (
        <CallLogsTable path={'super-admin/vendors/call-logs'} />
      )}
      {activeTab === 'Listed Leads Logs' && (
        <ListedLeadLogsTable enpoint={`listed-logs/logs/office`} />
      )}
      {campaign && <AddVendorsForm />}
      <ToastContainer />
    </div>
  );
};
const AdminCampaignOverview = () => {
  return (
    <AdminLayout>
      <U65CampgaignOverview />
    </AdminLayout>
  );
};

export default AdminCampaignOverview;
