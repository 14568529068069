/** @format */
import React, { useState } from 'react';
import {
  Card,
  Col,
  Row,
  Input,
  Button,
  DatePicker,
  message,
  Select,
  Dropdown,
  Radio,
  Menu,
} from 'antd';
import moment from 'moment';
import plus from 'img/Plus.svg';
import { updateCustomerById } from 'api/customerRoute';

const CustomerPlans = ({ customer }) => {
  const [customerData, setCustomerData] = useState(customer);

  const [editState, setEditState] = useState({
    ancillaries: customerData.ancillaries?.map(() => false) || [],
    privateMed: false,
    majorMed: false,
  });

  const [editableData, setEditableData] = useState({
    ancillaries: customerData.ancillaries || [],
    privateMed: customerData.privateMed || {},
    majorMed: customerData.majorMed || {
      carrier: '',
      billingDate: '',
      premium: '',
      status: '',
    },
  });

  const toggleEditMode = (planType, index = null) => {
    setEditState((prevState) => {
      if (index !== null) {
        const newAncillariesEditState = [...prevState.ancillaries];
        newAncillariesEditState[index] = !newAncillariesEditState[index];
        return { ...prevState, ancillaries: newAncillariesEditState };
      } else {
        return { ...prevState, [planType]: !prevState[planType] };
      }
    });
  };

  const handleSave = async (planType, index = null) => {
    try {
      if (index !== null) {
        const updatedAncillaries = [...editableData.ancillaries];
        await updateCustomerById(customerData._id, {
          ancillaries: updatedAncillaries,
        });
        message.success('Ancillary Plan updated successfully!');
        setCustomerData((prevData) => ({
          ...prevData,
          ancillaries: updatedAncillaries,
        }));
      } else {
        await updateCustomerById(customerData._id, {
          [planType]: editableData[planType],
        });
        message.success(`${planType} updated successfully!`);
        setCustomerData((prevData) => ({
          ...prevData,
          [planType]: editableData[planType],
        }));
      }
      toggleEditMode(planType, index);
    } catch (error) {
      message.error(`Failed to update ${planType}`);
    }
  };

  const cancelEdit = (planType, index = null) => {
    if (index !== null) {
      setEditableData((prevData) => {
        const newAncillaries = [...prevData.ancillaries];
        if (!customerData.ancillaries[index]) {
          newAncillaries.splice(index, 1);
          setEditState((prevEditState) => {
            const newAncillariesEditState = [...prevEditState.ancillaries];
            newAncillariesEditState.splice(index, 1);
            return { ...prevEditState, ancillaries: newAncillariesEditState };
          });
        } else {
          newAncillaries[index] = { ...customerData.ancillaries[index] };
        }
        return { ...prevData, ancillaries: newAncillaries };
      });
    } else {
      setEditableData((prevData) => ({
        ...prevData,
        [planType]: customerData[planType],
      }));
    }
    toggleEditMode(planType, index);
  };

  const handleChange = (planType, index, field, value) => {
    setEditableData((prevData) => {
      if (index !== null) {
        const newAncillaries = [...prevData.ancillaries];
        newAncillaries[index] = { ...newAncillaries[index], [field]: value };
        return { ...prevData, ancillaries: newAncillaries };
      } else {
        return {
          ...prevData,
          [planType]: { ...prevData[planType], [field]: value },
        };
      }
    });
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Paid':
        return { backgroundColor: 'green', color: 'white', padding: '1px 4px' };
      case 'Pending':
        return {
          backgroundColor: 'yellow',
          color: 'black',
          padding: '1px 4px',
        };
      case 'Declined':
        return { backgroundColor: 'red', color: 'white', padding: '1px 4px' };
      case 'Cancelled':
        return { backgroundColor: 'gray', color: 'white', padding: '1px 4px' };
      default:
        return {};
    }
  };

  const handleAddPlan = (planType) => {
    if (planType === 'ancillaries') {
      setEditableData((prevData) => ({
        ...prevData,
        ancillaries: [
          ...prevData.ancillaries,
          { carrier: '', billingDate: '', premium: '', status: '' },
        ],
      }));
      setEditState((prevState) => ({
        ...prevState,
        ancillaries: [...prevState.ancillaries, true],
      }));
    } else if (planType === 'privateMed') {
      setEditableData((prevData) => ({
        ...prevData,
        privateMed: { carrier: '', billingDate: '', premium: '', status: '' },
      }));
      toggleEditMode('privateMed');
    } else if (planType === 'majorMed') {
      setEditableData((prevData) => ({
        ...prevData,
        majorMed: { carrier: '', billingDate: '', premium: '', status: '' },
      }));
      toggleEditMode('majorMed');
    }
    message.success(`${planType} plan added successfully!`);
  };

  const planMenu = (
    <Menu>
      <Menu.Item key='ancillaries' onClick={() => handleAddPlan('ancillaries')}>
        Add Ancillary Plan
      </Menu.Item>
      {!customerData.privateMed?.carrier && (
        <Menu.Item key='privateMed' onClick={() => handleAddPlan('privateMed')}>
          Add Private Medical Plan
        </Menu.Item>
      )}
      {!customerData.majorMed?.carrier && (
        <Menu.Item key='majorMed' onClick={() => handleAddPlan('majorMed')}>
          Add Major Medical Plan
        </Menu.Item>
      )}
    </Menu>
  );

  const statusOptions = ['Paid', 'Pending', 'Cancelled', 'Declined'];

  return (
    <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
      <Col sm={24} md={24} lg={24} xl={24}>
        <div
          className='form-heading'
          style={{ justifyContent: 'space-between', display: 'flex' }}>
          <span>Plans</span>
          <Dropdown overlay={planMenu} trigger={['click']}>
            <button
              type='primary'
              style={{ marginLeft: '10px' }}
              className='add-ancillary-button'>
              <span>Add</span>
              <img src={plus} alt='plus icon' />
            </button>
          </Dropdown>
        </div>
      </Col>

      {/* Ancillary Plans */}
      {editableData.ancillaries.map((plan, index) => (
        <Col span={8} key={index}>
          <Card
            title={`Ancillary Plan`}
            extra={
              <>
                {editState.ancillaries[index] ? (
                  <>
                    <Button
                      onClick={() => handleSave('ancillaries', index)}
                      style={{ marginRight: '10px' }}>
                      Save
                    </Button>
                    <Button
                      onClick={() => cancelEdit('ancillaries', index)}
                      style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button onClick={() => toggleEditMode('ancillaries', index)}>
                    Edit
                  </Button>
                )}
              </>
            }>
            <div style={{ padding: '10px' }} className='custo-input'>
              {editState.ancillaries[index] ? (
                <>
                  <p>
                    <strong>Carrier:</strong>{' '}
                    <Input
                      value={editableData.ancillaries[index].carrier}
                      onChange={(e) =>
                        handleChange(
                          'ancillaries',
                          index,
                          'carrier',
                          e.target.value
                        )
                      }
                    />
                  </p>
                  <p>
                    <strong>Is Post Date:</strong>{' '}
                    <Radio.Group
                      value={
                        editableData.ancillaries[index].isPostDate
                          ? 'Yes'
                          : 'No'
                      }
                      onChange={(e) =>
                        handleChange(
                          'ancillaries',
                          index,
                          'isPostDate',
                          e.target.value === 'Yes' ? true : false
                        )
                      }>
                      <Radio value='Yes'>Yes</Radio>
                      <Radio value='No'>No</Radio>
                    </Radio.Group>
                  </p>
                  <p>
                    <strong>
                      {editableData.ancillaries[index].isPostDate
                        ? 'Billing Date'
                        : 'Todays Date'}
                    </strong>{' '}
                    <input
                      type='date'
                      style={{ width: '100%' }}
                      value={editableData.ancillaries[index].billingDate || ''}
                      onChange={(e) =>
                        handleChange(
                          'ancillaries',
                          index,
                          'billingDate',
                          e.target.value || '' // Store as a string, empty if cleared
                        )
                      }
                    />
                  </p>
                  <p>
                    <strong>Premium:</strong>{' '}
                    <Input
                      value={editableData.ancillaries[index].premium}
                      onChange={(e) =>
                        handleChange(
                          'ancillaries',
                          index,
                          'premium',
                          e.target.value
                        )
                      }
                    />
                  </p>
                  <p>
                    <strong>Status:</strong>{' '}
                    <Select
                      value={editableData.ancillaries[index].status}
                      onChange={(value) =>
                        handleChange('ancillaries', index, 'status', value)
                      }
                      style={{ width: '100%' }}>
                      {statusOptions.map((status) => (
                        <Select.Option key={status} value={status}>
                          {status}
                        </Select.Option>
                      ))}
                    </Select>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <strong>Carrier:</strong> {plan.carrier}
                  </p>
                  <p>
                    <strong>Billing Date:</strong>{' '}
                    {moment.utc(plan.billingDate).format('YYYY-MM-DD')}
                  </p>
                  <p>
                    <strong>Premium:</strong> ${plan.premium || 0}
                  </p>
                  <p>
                    <strong>Status:</strong>{' '}
                    <span style={getStatusStyle(plan.status)}>
                      {plan.status || 'N/A'}
                    </span>
                  </p>
                </>
              )}
            </div>
          </Card>
        </Col>
      ))}

      {/* Private Medical Plan */}
      {editableData.privateMed.carrier && (
        <Col span={8}>
          <Card
            title='Private Medical Plan'
            extra={
              <>
                {editState.privateMed ? (
                  <>
                    <Button
                      onClick={() => handleSave('privateMed')}
                      style={{ marginRight: '10px' }}>
                      Save
                    </Button>
                    <Button
                      onClick={() => cancelEdit('privateMed')}
                      style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button onClick={() => toggleEditMode('privateMed')}>
                    Edit
                  </Button>
                )}
              </>
            }>
            <div style={{ padding: '10px' }} className='custo-input'>
              {editState.privateMed ? (
                <>
                  <p>
                    <strong>Carrier:</strong>{' '}
                    <Input
                      value={editableData.privateMed.carrier}
                      onChange={(e) =>
                        handleChange(
                          'privateMed',
                          null,
                          'carrier',
                          e.target.value
                        )
                      }
                    />
                  </p>
                  <p>
                    <strong>Is Post Date:</strong>{' '}
                    <Radio.Group
                      value={editableData.privateMed.isPostDate ? 'Yes' : 'No'}
                      onChange={(e) =>
                        handleChange(
                          'privateMed',
                          null,
                          'isPostDate',
                          e.target.value === 'Yes' ? true : false
                        )
                      }>
                      <Radio value='Yes'>Yes</Radio>
                      <Radio value='No'>No</Radio>
                    </Radio.Group>
                  </p>
                  <p>
                    <strong>
                      {editableData.privateMed.isPostDate
                        ? 'Billing Date'
                        : 'Todays Date'}
                    </strong>{' '}
                    <input
                      type='date'
                      value={editableData.privateMed.billingDate || ''}
                      style={{ width: '100%' }}
                      onChange={(e) =>
                        handleChange(
                          'privateMed',
                          null,
                          'billingDate',
                          e.target.value || ''
                        )
                      }
                    />
                  </p>
                  <p>
                    <strong>Premium:</strong>{' '}
                    <Input
                      value={editableData.privateMed.premium}
                      onChange={(e) =>
                        handleChange(
                          'privateMed',
                          null,
                          'premium',
                          e.target.value
                        )
                      }
                    />
                  </p>
                  <p>
                    <strong>Status:</strong>{' '}
                    <Select
                      value={editableData.privateMed.status}
                      onChange={(value) =>
                        handleChange('privateMed', null, 'status', value)
                      }
                      style={{ width: '100%' }}>
                      {statusOptions.map((status) => (
                        <Select.Option key={status} value={status}>
                          {status}
                        </Select.Option>
                      ))}
                    </Select>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <strong>Carrier:</strong> {customerData.privateMed.carrier}
                  </p>

                  <p>
                    <strong>Billing Date:</strong>{' '}
                    {customerData.privateMed.billingDate
                      ? moment
                          .utc(customerData.privateMed.billingDate)
                          .format('YYYY-MM-DD')
                      : 'N/A'}
                  </p>
                  <p>
                    <strong>Premium:</strong> ${customerData.privateMed.premium}
                  </p>
                  <p>
                    <strong>Status:</strong>{' '}
                    <span
                      style={getStatusStyle(customerData.privateMed.status)}>
                      {customerData.privateMed.status || 'N/A'}
                    </span>
                  </p>
                </>
              )}
            </div>
          </Card>
        </Col>
      )}

      {/* Major Medical Plan */}
      {(editableData.majorMed.carrier || editState.majorMed) && (
        <Col span={8}>
          <Card
            title='Major Medical Plan'
            extra={
              <>
                {editState.majorMed ? (
                  <>
                    <Button
                      onClick={() => handleSave('majorMed')}
                      style={{ marginRight: '10px' }}>
                      Save
                    </Button>
                    <Button
                      onClick={() => cancelEdit('majorMed')}
                      style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button onClick={() => toggleEditMode('majorMed')}>
                    Edit
                  </Button>
                )}
              </>
            }>
            <div style={{ padding: '10px' }} className='custo-input'>
              {editState.majorMed ? (
                <>
                  <p>
                    <strong>Carrier:</strong>{' '}
                    <Input
                      value={editableData.majorMed.carrier}
                      onChange={(e) =>
                        handleChange(
                          'majorMed',
                          null,
                          'carrier',
                          e.target.value
                        )
                      }
                    />
                  </p>
                  <p>
                    <strong>Is Post Date:</strong>{' '}
                    <Radio.Group
                      value={editableData.majorMed.isPostDate ? 'Yes' : 'No'}
                      onChange={(e) =>
                        handleChange(
                          'majorMed',
                          null,
                          'isPostDate',
                          e.target.value === 'Yes' ? true : false
                        )
                      }>
                      <Radio value='Yes'>Yes</Radio>
                      <Radio value='No'>No</Radio>
                    </Radio.Group>
                  </p>
                  <p>
                    {editableData.majorMed.isPostDate
                      ? 'Billing Date'
                      : 'Todays Date'}
                    <input
                      type='Date'
                      placeholder='MM/DD/YYYY'
                      value={editableData.majorMed.billingDate || ''}
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        const date = e.target.value;
                        handleChange(
                          'majorMed',
                          null,
                          'billingDate',
                          date || '' // Store as string, empty if cleared
                        );
                      }}
                    />
                  </p>
                  <p>
                    <strong>Premium:</strong>{' '}
                    <Input
                      value={editableData.majorMed.premium}
                      onChange={(e) =>
                        handleChange(
                          'majorMed',
                          null,
                          'premium',
                          e.target.value
                        )
                      }
                    />
                  </p>
                  <p>
                    <strong>Status:</strong>{' '}
                    <Select
                      value={editableData.majorMed.status}
                      onChange={(value) =>
                        handleChange('majorMed', null, 'status', value)
                      }
                      style={{ width: '100%' }}>
                      {statusOptions.map((status) => (
                        <Select.Option key={status} value={status}>
                          {status}
                        </Select.Option>
                      ))}
                    </Select>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <strong>Carrier:</strong> {customerData.majorMed.carrier}
                  </p>
                  <p>
                    <strong>Billing Date:</strong>{' '}
                    {customerData.majorMed.billingDate
                      ? moment
                          .utc(customerData.majorMed.billingDate)
                          .format('MM/DD/YYYY')
                      : 'N/A'}
                  </p>
                  <p>
                    <strong>Premium:</strong> ${customerData.majorMed.premium}
                  </p>
                  <p>
                    <strong>Status:</strong>{' '}
                    <span
                      style={getStatusStyle(
                        customerData.majorMed.status || customerData.request
                          ? 'Pending'
                          : 'N/A'
                      )}>
                      {customerData.majorMed.status || customerData.request
                        ? 'Pending'
                        : 'N/A'}
                    </span>
                  </p>
                </>
              )}
            </div>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default CustomerPlans;
