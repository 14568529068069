/** @format */
/**
 * # this page is to list all of the forms, by office type + officeID.
 * # Author: Claude Lamarre
 * # frameWork: react.js
 * #libraries: axios
 */

import React, { useState } from 'react';
import AdminLayout from 'components/admin/layout';
import AdminFormTable from 'components/admin/widgets/formTable';
import RequestFormsTable from 'components/admin/widgets/requestFormTable';
import PostDateTable from 'components/admin/widgets/adminPostDateTable';
import U65ExportCSVButton from 'components/super-admin/widgets/u65ExportButton';
import FormDetails from 'components/admin/widgets/formDetails';
import { useSelector, useDispatch } from 'react-redux';
import { clearSelectedFormId } from 'state/slice/buttonSlice';
import SubmitionForm from 'components/admin/widgets/saleSubmissionForm';
import ACARequestFormsTable from 'components/acaRequestFormTable';
import CustomerDetails from 'components/customerDetails';
const U65FormTables = () => {
  const [formDatas, setFormDatas] = useState([]); // State to hold the form data
  const [tab, setTab] = useState('Forms');
  const [showSubmissionForm, setShowSubmissionForm] = useState(false); // State to control form visibility
  const selectedFormId = useSelector((state) => state.button.selectedFormId);
  const dispatch = useDispatch();

  const handleTab = (tab) => {
    setTab(tab);
    dispatch(clearSelectedFormId());
  };

  const toggleSubmissionForm = () => {
    setShowSubmissionForm(!showSubmissionForm); // Toggle the form visibility
  };
  const hideSubmissionForm = () => {
    setShowSubmissionForm(false); // Set form visibility to false
  };
  return (
    <div className='form-page-container' style={{ position: 'relative' }}>
      <div className='form-page-header'>
        {!showSubmissionForm && ( // Only show tabs and export button when form is not visible
          <div className='admin-form-tab-wrapper'>
            <div>
              <span
                className={tab === 'Forms' ? 'table-active' : ''}
                onClick={() => handleTab('Forms')}>
                Forms
              </span>
            </div>
            <div>
              <span
                className={tab === 'Post' ? 'table-active' : ''}
                onClick={() => handleTab('Post')}>
                Post Dates
              </span>
            </div>
            <div>
              <span
                className={tab === 'Request' ? 'table-active' : ''}
                onClick={() => handleTab('Request')}>
                ACA Request Form (Legacy)
              </span>
            </div>
            <div>
              <span
                className={tab === 'New Request' ? 'table-active' : ''}
                onClick={() => handleTab('New Request')}>
                ACA Request Form
              </span>
            </div>
          </div>
        )}

        <div className='a-p-tab-wrapper'>
          {/* Button to enable the submission form */}
          {!showSubmissionForm && tab === 'Forms' && (
            <button
              onClick={toggleSubmissionForm}
              className='enable-form-button'>
              Submit a form
            </button>
          )}

          {/* Only show the CSV Export Button when the submission form is not visible */}
          {!showSubmissionForm && tab === 'Forms' && (
            <U65ExportCSVButton data={formDatas} />
          )}
        </div>
      </div>

      {/* Show the submission form if enabled, otherwise show the selected tab content */}
      {showSubmissionForm ? (
        <SubmitionForm hideForm={hideSubmissionForm} />
      ) : selectedFormId && tab === 'Request' ? (
        <FormDetails />
      ) : (
        <>
          {tab === 'Forms' && (
            <AdminFormTable
              endpoint={'api/policy-form/admin/form-list'}
              endpoint2={'api/U65Vendors/admin/vendors-list'}
              setFormDatas={setFormDatas}
            />
          )}
          {tab === 'Post' && (
            <PostDateTable
              path={'policy-form/admin/post-date/list'}
              setFormDatas={setFormDatas}
            />
          )}
          {tab === 'Request' && (
            <RequestFormsTable path={'aca/request-form/forms'} />
          )}
          {tab === 'New Request' && <ACARequestFormsTable />}
          {tab === 'New Request' && selectedFormId && <CustomerDetails />}
        </>
      )}
    </div>
  );
};

const AdminForms = () => {
  return (
    <AdminLayout>
      <U65FormTables />
    </AdminLayout>
  );
};

export default AdminForms;
