/** @format */
import React, { useEffect, useState } from 'react';
import AdminLayout from 'components/admin/layout';
import { useSelector } from 'react-redux';
import QAReportTable from 'components/QAreportTable';
import { getQAreports } from 'api/qaRoutes';
import QAReport from 'components/QAReport';

const QualityAssuranceReport = () => {
  const [reports, setReports] = useState([]);
  const reportId = useSelector((state) => state.dropDown.reportId);
  console.log('heres the teport id', reportId);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const reportData = await getQAreports();
        // console.log('Fetched Report Data:', reportData);
        setReports(Array.isArray(reportData) ? reportData : []);
      } catch (error) {
        console.error('Error Fetching Details', error);
      }
    };
    fetchReports();
  }, []);

  return (
    <AdminLayout>
      {reportId ? <QAReport /> : <QAReportTable reports={reports} />}
    </AdminLayout>
  );
};

export default QualityAssuranceReport;
