/** @format */

import React from 'react';
import LoginForm from 'components/loginForms';
import MainLogo from 'img/Logo-signing.png';

const SignIn = () => {
  return (
    <div className='signin-container'>
      <div className='logo-wrapper'>
        <img src={MainLogo} alt='Synergy Logo' />
      </div>
      <LoginForm />
    </div>
  );
};

export default SignIn;
