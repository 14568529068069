/** @format */
/**
 * # this component is to list all of the forms, by office type + officeID for the super admin page .
 * # Author: Claude Lamarre
 */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Filter from "img/ArrowsDownUp.svg";
import Search from "img/Search.svg";

// Custom modal component
const CustomModal = ({ show, handleClose, children }) => {
  if (!show) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div style={{ height: "40px" }}>
          <button className="close-button" onClick={handleClose}>
            ×
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const FormTable = ({ officeId, setFormDatas }) => {
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App
  const [formDatas, setFormsDatasLocal] = useState([]);
  const [vendors, setVendors] = useState([]); // State to store the list of vendors
  const [error, setError] = useState(null);
  const [editIndex, setEditIndex] = useState(null); // Track the index of the row being edited
  const [editedData, setEditedData] = useState({}); // Store the edited data
  const [isModalVisible, setIsModalVisible] = useState(false); // Control the visibility of the modal

  useEffect(() => {
    const getFormDatas = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/ACAform/form-list/${officeId}`,
          {
            params: {
              startTime: startDate,
              endTime: endDate,
            },
          }
        );
        setFormsDatasLocal(response.data);
        setFormDatas(response.data); // Update parent state
      } catch (error) {
        setError("Failed to load data");
        console.error("Error fetching data:", error);
      }
    };

    const getVendors = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/campaigns/vendor-list`);
        setVendors(response.data);
      } catch (error) {
        setError("Failed to load vendors");
        console.error("Error fetching vendors:", error);
      }
    };

    if (officeId) {
      getFormDatas();
      getVendors();
    }
  }, [officeId, startDate, endDate, apiUrl, setFormDatas]);

  const handleEdit = (index, form) => {
    setEditIndex(index);
    setEditedData({ ...form });
  };

  const handleSave = async (index) => {
    const token = localStorage.getItem("accessToken");
    try {
      await axios.put(
        `${apiUrl}/api/ACAform/admin/update-form/${editedData._id}`,
        editedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedForms = [...formDatas];
      updatedForms[index] = editedData;
      setFormsDatasLocal(updatedForms);
      setFormDatas(updatedForms); // Update parent state
      setEditIndex(null);
      setEditedData({});
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error saving data:", error);
      setError("Failed to save data");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData({
      ...editedData,
      [name]: value,
    });
  };

  const handleVendorChange = (vendorId) => {
    const selectedVendor = vendors.find(
      (vendor) => vendor.vendorId === vendorId
    );
    setEditedData({
      ...editedData,
      vendorName: selectedVendor.vendorName,
      vendorId: selectedVendor.vendorId,
    });
    setIsModalVisible(false); // Hide the modal after selecting a vendor
  };

  const dateFormat = (date) => {
    const dateObj = new Date(date);
    const localDate = new Date(
      dateObj.getTime() + dateObj.getTimezoneOffset() * 60000
    );
    return !isNaN(localDate) ? localDate.toDateString() : "Invalid date";
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const capitalizeFullName = (name) => {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ width: "100%" }}>
      <div className="filter-container">
        <div className="filter">
          <img src={Filter} alt="filter icon" />
          <span>Filter By division</span>
        </div>
        <div className="search">
          <img src={Search} alt="search icon" />
          <input />
        </div>
      </div>
      <div
        className={`data-table-container ${
          editIndex !== null ? "table-scroll" : ""
        }`}>
        <table
          className="data-table"
          style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Phone Number</th>
              <th>Vendor Name</th>
              <th>FFMId</th>
              <th>Carrier</th>
              <th>Health Sherpa</th>
              <th>Agent Name</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {formDatas.map((form, index) => (
              <tr key={index}>
                <td>
                  {editIndex === index ? (
                    <input
                      type="text"
                      name="firstName"
                      value={editedData.firstName}
                      onChange={handleChange}
                    />
                  ) : (
                    form.firstName
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      type="text"
                      name="lastName"
                      value={editedData.lastName}
                      onChange={handleChange}
                    />
                  ) : (
                    form.lastName
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      type="text"
                      name="phoneNumber"
                      value={editedData.phoneNumber}
                      onChange={handleChange}
                    />
                  ) : (
                    form.phoneNumber
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <>
                      <input
                        type="text"
                        name="vendorName"
                        value={editedData.vendorName}
                        readOnly
                        onClick={showModal}
                      />
                      <CustomModal
                        show={isModalVisible}
                        handleClose={handleCancel}>
                        <ul className="vendor-list">
                          {vendors.map((vendor) => (
                            <li
                              key={vendor.vendorId}
                              onClick={() =>
                                handleVendorChange(vendor.vendorId)
                              }>
                              {vendor.vendorName}
                            </li>
                          ))}
                        </ul>
                      </CustomModal>
                    </>
                  ) : (
                    form.vendorName
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      type="text"
                      name="FFMId"
                      value={editedData.FFMId}
                      onChange={handleChange}
                    />
                  ) : (
                    form.FFMId
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      type="text"
                      name="carrier"
                      value={editedData.carrier}
                      onChange={handleChange}
                    />
                  ) : (
                    form.carrier
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      type="text"
                      name="healthSherpa"
                      value={editedData.healthSherpa}
                      onChange={handleChange}
                    />
                  ) : (
                    form.healthSherpa
                  )}
                </td>
                <td>{capitalizeFullName(form.agentName)}</td>
                <td>{dateFormat(form.todaysDate)}</td>
                <td>
                  {editIndex === index ? (
                    <button onClick={() => handleSave(index)}>Save</button>
                  ) : (
                    <button onClick={() => handleEdit(index, form)}>
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FormTable;
