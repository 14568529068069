/** @format */

import React from 'react';

const GradeCard = ({ grade }) => {
  // Destructure the grade object to access points, letter, and color
  const {
    grade: { letter, color },
  } = grade;

  return (
    <div
      style={{
        backgroundColor: color,
        padding: '20px',
        borderRadius: '5px',
        color: 'white',
        width: '100%',
        height: '100%',
      }}
      className='grade-card-wrapper'>
      <h1> {letter}</h1>
    </div>
  );
};

export default GradeCard;
