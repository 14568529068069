/** @format */

import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sideBar",
  initialState: {
    menuCollapse: true,
    notificationCollapse: false,
    activeLink: "",
  },

  reducers: {
    toggleMenu: (state) => {
      state.menuCollapse = !state.menuCollapse;
    },
    toggleNotification: (state) => {
      state.notificationCollapse = !state.notificationCollapse;
    },
    setActiveLink: (state, action) => {
      state.activeLink = action.payload;
    },
  },
});

export const { toggleMenu, toggleNotification, setActiveLink } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
