/** @format */

import axios from 'axios';

const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;

class ListedLeadLog {
  /**
   * Helper method to get the token from localStorage (or sessionStorage).
   * Modify this as needed based on where you store the token.
   */
  static getToken() {
    return localStorage.getItem('accessToken'); // Adjust storage location if needed
  }

  /**
   * Get listed lead logs by date range.
   * @param {string} startDate - Start date for filtering logs.
   * @param {string} endDate - End date for filtering logs.
   * @returns {Promise<Object>} - A promise that resolves with the listed lead logs data.
   */
  static async getLogsByDate(startDate, endDate, endpoint) {
    try {
      const response = await axios.get(`${apiUrl}/api/${endpoint}`, {
        params: { startDate, endDate },
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(`Failed to fetch logs by date range: ${error.message}`);
    }
  }

  /**
   * Get listed lead logs by officeId and date range.
   * @param {string} officeId - Office ID to filter logs.
   * @param {string} startDate - Start date for filtering logs.
   * @param {string} endDate - End date for filtering logs.
   * @returns {Promise<Object>} - A promise that resolves with the listed lead logs data.
   */
  static async getLogsByOfficeIdAndDate(officeId, startDate, endDate) {
    try {
      const response = await axios.get(`${apiUrl}/logs/${officeId}`, {
        params: { startDate, endDate },
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to fetch logs by officeId and date: ${error.message}`
      );
    }
  }

  /**
   * Get total cost of logs by date range.
   * @param {string} startDate - Start date for filtering logs.
   * @param {string} endDate - End date for filtering logs.
   * @returns {Promise<Object>} - A promise that resolves with the total cost.
   */
  static async getListedLeadCostByDateRange(startDate, endDate) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/listed-logs/logs/cost/by-date`,
        {
          params: { startDate, endDate },
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to fetch total cost by date range: ${error.message}`
      );
    }
  }

  /**
   * Get total cost of logs by officeId and date range.
   * @param {string} officeId - Office ID to filter logs.
   * @param {string} startDate - Start date for filtering logs.
   * @param {string} endDate - End date for filtering logs.
   * @returns {Promise<Object>} - A promise that resolves with the total cost.
   */
  static async getListedLeadCostByOfficeIdAndDate(startDate, endDate) {
    try {
      const response = await axios.get(`${apiUrl}/api/listed-logs/logs/cost`, {
        params: { startDate, endDate },
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to fetch total cost by officeId and date: ${error.message}`
      );
    }
  }
}

export default ListedLeadLog;
