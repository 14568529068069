/** @format */

import React from 'react';

import { useState } from 'react';
import Filter from 'img/ArrowsDownUp.svg';
import AdminLayout from 'components/admin/layout';
import Search from 'img/Search.svg';
import { setShowDate } from 'state/slice/tabSlice';
import { useDispatch } from 'react-redux';
import AgentStatsTable from 'components/admin/widgets/agentU65StatsTable';
import FiveDayLeaderBoard from 'components/admin/widgets/fiveDayLeaderboard';
import U65Podium from 'components/u65Podium';

const U65Leaderboard = () => {
  const [activeTab, setActiveTab] = useState('Leaderboard');
  const dispatch = useDispatch();
  const handleTab = (tab, showDate) => {
    setActiveTab(tab);
    dispatch(setShowDate(showDate));
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '28px',
      }}>
      <div className='campaign-header-containger'>
        <div className='leaderboard-tab-wrapper'>
          <span
            onClick={() => handleTab('Leaderboard', true)}
            className={activeTab === 'Leaderboard' ? 'active' : ''}>
            Agent leaderboard
          </span>
          <span
            onClick={() => handleTab('5Day', false)}
            className={activeTab === '5Day' ? 'active' : ''}>
            Weekly Leaderboard
          </span>
        </div>
      </div>
      {activeTab === 'Leaderboard' && (
        <div>
          {' '}
          <U65Podium path={'api/stats/sales-table'} />
          <div style={{ marginTop: '20px' }}>
            <div className='filter-container'>
              <div className='filter'>
                <img src={Filter} alt='filer icon' />
                <span>Filter By division</span>
              </div>

              <div className='search'>
                <img src={Search} alt='search icon' />
                <input />
              </div>
            </div>
            <AgentStatsTable endpoint='api/stats/sales-table' />
          </div>
        </div>
      )}
      {activeTab === '5Day' && <FiveDayLeaderBoard />}
    </div>
  );
};
const AdminLeaderboard = () => {
  return (
    <AdminLayout>
      <U65Leaderboard />
    </AdminLayout>
  );
};

export default AdminLeaderboard;
