/** @format */

import { createSlice } from '@reduxjs/toolkit';

///////////// this tab slice is to manage the state of the tabs
const toggleTabSlice = createSlice({
  name: 'activeTab',
  initialState: {
    ////////////////this is for the Afeliate Office page
    activeOffice: 'ACA',
    activeForm: false,
    upload: false,
    backOffice: false,
    addVendors: false,
    addCampaigns: false,
    showDate: true,
    addListedLeads: false,
    backOfficeType: '',
    /// this is for the Affeliate perfomace page
    activeStatsTab: 'operation',
  },

  reducers: {
    setOffice: (state, action) => {
      state.activeOffice = action.payload;
    },
    setForm: (state, action) => {
      state.activeForm = action.payload;
    },
    setActiveStatsTab: (state, action) => {
      state.activeStatsTab = action.payload;
    },
    toggleUpload: (state, action) => {
      state.upload = action.payload;
    },
    toggleBackoffice: (state, action) => {
      state.backOffice = action.payload;
    },
    toggleVendorsForm: (state, action) => {
      state.addVendors = action.payload;
    },
    toggleCampaignsForm: (state, action) => {
      state.addCampaigns = action.payload;
    },
    toggleListedLeadForm: (state, action) => {
      state.addListedLeads = action.payload;
    },
    setBackOfficeType: (state, action) => {
      state.backOfficeType = action.payload;
    },
    setShowDate: (state, action) => {
      state.showDate = action.payload;
    },
  },
});

export const {
  setOffice,
  setForm,
  setActiveStatsTab,
  toggleUpload,
  toggleCampaignsForm,
  toggleBackoffice,
  toggleVendorsForm,
  setBackOfficeType,
  toggleListedLeadForm,
  setShowDate,
} = toggleTabSlice.actions;

export default toggleTabSlice.reducer;
