/** @format */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Exit from 'img/exit.svg';
import { useDispatch } from 'react-redux';
import { setSelectedOffice } from 'state/slice/viewOfficeAction';
import SalesChart from 'components/super-admin/widgets/saleChart';

import RightArrow from 'img/ArrowLineRightBlack.svg';
import Map from 'img/MapPin.svg';
import Phone from 'img/Phone.svg';
import { useSelector } from 'react-redux';

const OfficeProfile = ({ officeId }) => {
  const [officeData, setOfficeData] = useState({});
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App
  useEffect(() => {
    if (officeId) {
      /*basiccally if office id is not null fetch the office data and pass the office id */
    }
    {
      const fetchOfficeData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/api/offices/${officeId}`);
          setOfficeData(response.data);
        } catch (error) {
          console.error('Error retrieving data', error);
        }
      };

      fetchOfficeData();
    }
  }, [officeId, apiUrl]);

  const selectedOfficeId = useSelector(
    (state) => state.viewOffice.selectedOffice
  );
  console.log(selectedOfficeId);

  return (
    <div>
      {officeId && (
        <div className='office-profile-container'>
          <div className='profile-wrapper'>
            <div className='logo-profile-wrapper'>
              <div>
                <img
                  src={Exit}
                  alt='Exit Button'
                  onClick={() =>
                    dispatch(
                      setSelectedOffice({ officeId: null, officeType: null })
                    )
                  }
                  style={{ cursor: 'pointer', marginBottom: '30px' }}
                />{' '}
              </div>

              <img src={officeData.logoUrl} alt='company logo' />
            </div>
            <div className='office-pf-info-wrp'>
              <div className='pf-sectn-2 '>
                <span>{officeData.officeName}</span>
                <div>
                  <img src={Map} alt='Map Icon' />
                  <span>{officeData.address}</span>
                </div>
                <div>
                  <img src={Phone} alt='Phone Icon' />
                  <span>{officeData.phone}</span>
                </div>
              </div>
              <div className='pf-goal-wrapper'>
                <span>Goal completion</span>
                <div
                  className='progress-bar'
                  style={{
                    height: '28px',
                    fontSize: '18px',
                    fontWeight: '600',
                  }}>
                  <div
                    className='progress'
                    style={{ width: '0%', height: '100%', paddingTop: '5px' }}>
                    0%
                  </div>
                </div>
              </div>
              <div className='profile-stats-table'>
                <table>
                  <tr>
                    <th>Leads</th>
                    <th>Sales</th>
                    <th style={{ borderRight: 'none' }}>CR</th>
                  </tr>
                  <tr>
                    <td>0</td>
                    <td>0</td>
                    <td style={{ borderRight: 'none' }}>0%</td>
                  </tr>
                </table>
              </div>
            </div>
            <div>
              <SalesChart />
            </div>
            <div className='aflte-pfrm-link-wpr'>
              <h2>Affiliate Performance</h2>
              <div>
                <span>Open Dashboard</span>
                <img src={RightArrow} alt='right Arrow' />
              </div>
            </div>
            <div className='agent-pfrm-link-wpr'>
              <h2>Agent Performance</h2>
              <div>
                <span>Open Dashboard</span>
                <img src={RightArrow} alt='right arrroe icon' />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OfficeProfile;
