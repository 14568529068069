/** @format */

import React, { useEffect, useState } from 'react';
import { Table, Tooltip as TooltipAlias } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import cpa from 'utils/cpa';
import Info from 'img/Info.svg';
import formatCurrency from 'utils/formatCurrency';
const GlobalTable = () => {
  const [sortedData, setSortedData] = useState([]);
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL;
  const startDate = useSelector((state) => state.dropDown.startDate);
  const endDate = useSelector((state) => state.dropDown.endDate);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');

        const response = await axios.get(
          `${apiUrl}/api/performance/overview/global-performance`,
          {
            params: { startDate, endDate },
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const data = response.data;

        if (data.length > 0) {
          setSortedData(data); // Limit to top 10
        } else {
          setSortedData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [apiUrl, startDate, endDate]);
  const getColorBasedOnPercentage = (percentage) => {
    if (percentage >= 0.75) {
      return 'green'; // Green for 23 and above
    } else if (percentage >= 0.65 && percentage <= 0.74) {
      return '#FFDB58'; // Yellow for 13-22
    } else {
      return 'red'; // Red for 12 and below
    }
  };

  const rate = (total, fraction) => {
    if (total === 0) return 0;
    if (!total || !fraction) return 0;
    const percentage = (fraction / total) * 100;
    return Math.round(percentage);
  };
  const calculatePtc = (data) => {
    const premium =
      (data.totalAncillariesPremium || 0) + (data.totalPrivateMedPremium || 0);
    const cost = data.totalCost || 0;
    if (cost === 0) return 0;
    return premium / cost;
  };
  const siaScore = (data) => {
    const ptc = calculatePtc(data);
    const score = ptc * (data.totalACASales + data.totalU65Sales);
    return score;
  };
  const formatPtc = (value) => `${value.toFixed(2)}x`;
  const columns = [
    {
      title: (
        <TooltipAlias title='The name of the vendor'>Vendor Name</TooltipAlias>
      ),
      dataIndex: 'globalName',
      key: 'globalName',
      render: (_, record) => (
        <div>
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: record.color,
              marginRight: '10px',
            }}></span>
          <span>{record.globalName}</span>
        </div>
      ),
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          <span>CPA-A</span>
          <TooltipAlias title='Cost Per Acquisition without Major Med '>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'CPA-A',
      key: 'CPA-A',
      render: (_, record) => {
        const { formatted } = cpa(record.totalCost, record.totalU65Sales); // Only get formatted value
        return <span>{formatted}</span>; // Render the formatted value (string)
      },
      sorter: (a, b) => {
        const { value: cpaA } = cpa(a.totalCost, a.totalU65Sales); // Get raw number for sorting
        const { value: cpaB } = cpa(b.totalCost, b.totalU65Sales);

        if (cpaA === null || cpaB === null) return 0; // Handle 'No Data' case
        return cpaA - cpaB; // Sort by raw number
      },
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          <span> CPA-B</span>
          <TooltipAlias title='Cost Per Acquisition with Major Med'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'CPA-B',
      key: 'CPA-B',
      render: (_, record) => {
        const { formatted } = cpa(
          record.totalCost,
          record.totalU65Sales + record.totalACASales
        );
        return <span>{formatted}</span>; // Render formatted string
      },
      sorter: (a, b) => {
        const { value: cpaA } = cpa(
          a.totalCost,
          a.totalU65Sales + a.totalACASales
        ); // Get value for a
        const { value: cpaB } = cpa(
          b.totalCost,
          b.totalU65Sales + b.totalACASales
        ); // Get value for b

        if (cpaA === null || cpaB === null) return 0; // Handle "No Data" case or null values
        return cpaA - cpaB; // Sort based on raw values
      },
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span>Sales </span>
          <TooltipAlias title='Total Premium  to Total Marketing cost'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'Sales',
      key: 'Sales',
      render: (_, record) => (
        <span>{record.totalU65Sales + record.totalACASales}</span>
      ),
      sorter: (a, b) =>
        a.totalU65Sales + a.totalACASales - (b.totalU65Sales + b.totalACASales),
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span>ACA </span>
          <TooltipAlias title='Total ACA Sales'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'ACA',
      key: 'ACA',
      render: (_, record) => <span>{record.totalACASales}</span>,
      sorter: (a, b) => a.totalACASales - b.totalACASales,
    },

    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span>U65</span>
          <TooltipAlias title='Total ACA Sales'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'ACA',
      key: 'ACA',
      render: (_, record) => <span>{record.totalU65Sales}</span>,
      sorter: (a, b) => a.totalU65Sales - b.totalU65Sales,
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span>Ancillary</span>
          <TooltipAlias title='Total ACA Sales'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'Ancillary',
      key: 'Ancillary',
      render: (_, record) => <span>{record.totalAncillaries}</span>,
      sorter: (a, b) => a.totalAncillaries - b.totalAncillaries,
    },

    {
      title: (
        <TooltipAlias title='The total number of billable leads'>
          Q Call
        </TooltipAlias>
      ),
      dataIndex: 'totalBillableLeads',
      key: 'totalBillableLeads',
      render: (_, record) => <span>{record.totalBillableLeads}</span>,
      sorter: (a, b) => a.totalBillableLeads - b.totalBillableLeads,
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          <span> CR</span>
          <TooltipAlias title='Close Ratio'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'CR',
      key: 'CR',
      render: (_, record) => {
        const totalSales = record.totalACASales + record.totalU65Sales;
        const totalLeads = record.totalBillableLeads;

        // Calculate Close Ratio directly inside the render function

        return <span>{rate(totalLeads, totalSales)}%</span>;
      },
      sorter: (a, b) => {
        const crA =
          a.totalBillableLeads > 0
            ? Math.round(
                ((a.totalACASales + a.totalU65Sales) / a.totalBillableLeads) *
                  100
              )
            : 0;
        const crB =
          b.totalBillableLeads > 0
            ? Math.round(
                ((b.totalACASales + b.totalU65Sales) / b.totalBillableLeads) *
                  100
              )
            : 0;

        return crA - crB;
      },
    },

    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          <span>P.T.C.</span>
          <TooltipAlias title='Total Premium to Total Marketing cost'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'PTC',
      key: 'PTC',
      render: (_, record) => (
        <span
          style={{
            display: 'inline-block',
            padding: '2px 5px',
            borderRadius: '10px',
            color: '#fff',
            backgroundColor: getColorBasedOnPercentage(calculatePtc(record)),
          }}>
          {formatPtc(calculatePtc(record))}
        </span>
      ),
      sorter: (a, b) => calculatePtc(a) - calculatePtc(b),
    },

    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span> AP</span>
          <TooltipAlias title='Average Premium'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'AP',
      key: 'AP',
      render: (_, record) => (
        <span>
          {formatCurrency(record.totalPrivateMedPremium / record.totalU65Sales)}
        </span>
      ),
      sorter: (a, b) =>
        a.totalPrivateMedPremium / a.totalU65Sales -
        b.totalPrivateMedPremium / b.totalU65Sales,
    },

    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          {' '}
          <span>Total Premium</span>
        </span>
      ),
      dataIndex: 'Total Premium',
      key: 'Total Premium',
      render: (_, record) => (
        <span>
          {formatCurrency(
            record.totalPrivateMedPremium + record.totalAncillariesPremium
          )}
        </span>
      ),
      sorter: (a, b) =>
        a.totalPrivateMedPremium +
        a.totalAncillariesPremium -
        (b.totalPrivateMedPremium + b.totalAncillariesPremium),
    },
    {
      title: (
        <span style={{ display: 'flex', gap: '5px' }}>
          <span>SIA-Score</span>
          <TooltipAlias title='P.T.C times total sales'>
            <img src={Info} alt='info icon' />
          </TooltipAlias>
        </span>
      ),
      dataIndex: 'SIA',
      key: 'SIA',
      render: (_, record) => <span>{siaScore(record).toFixed(2)}</span>,
      sorter: (a, b) => siaScore(a) - siaScore(b),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={sortedData}
      rowKey={(record) => record.globalName}
      pagination={false}
    />
  );
};

export default GlobalTable;
