/** @format */

import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import Exit from "img/exit.svg"; // Ensure the path to your 'Exit' icon is correct
import { setActiveTab } from "state/slice/toggleTabAction";
import { toggleForm } from "state/slice/buttonSlice";
import { ToastContainer, toast } from "react-toastify";

const RegisterAgentsForm = ({ AdminofficeId }) => {
  const [userDetails, setUserDetails] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    role: "",
    isAOR: false,
  });
  const apiUrl = process.env.REACT_APP_SIADASH_BACKEND_URL; // Prefixed for Create React App

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    if (type === "checkbox" && name === "division") {
      // Handle checkbox inputs specifically for the 'division' array
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        division: checked
          ? [...prevDetails.division, value]
          : prevDetails.division.filter((div) => div !== value),
      }));
    } else if (type === "checkbox") {
      // Handle general boolean checkbox inputs (like isAOR)
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [name]: checked,
      }));
    } else {
      // Handle regular inputs like text, email, password, etc.
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...userDetails,
      /* this portion sets the office id and office type to the officeType
       and OfficeId  the it was  created on*/
      officeId: AdminofficeId,
    };

    try {
      await axios.post(`${apiUrl}/api/register-users`, payload);

      toast.success("User created successfully!");

      setUserDetails({
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        role: "",
      });
      //dispatch(clearSelectedOffice()); // Optionally clear the selected office after successful registration
      dispatch(setActiveTab("")); // Adjust this as needed for your app's flow
    } catch (error) {
      console.error("Error registering user:", error);
      toast.error("Errorr egistering user");
    }
  };

  return (
    <div className="form-layout ">
      <div className="exit-form-wrapper">
        <button
          className="exit-form"
          style={{ float: "right", marginBottom: "30px" }}
          onClick={() => dispatch(toggleForm(false))}>
          <img src={Exit} alt="Exit" style={{ cursor: "pointer" }} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="form-container">
        <div className="form-wrapper">
          <div className="form-header">
            <h2
              style={{
                marginTop: "25px",
                textAlign: "center",
                marginBottom: "8px",
              }}>
              Register New {/*props.officeType*/} Agent
            </h2>

            <div className="help-msg-wrapper">
              <span>If you need any help, please access Help Page.</span>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="name-wrapper">
              <input
                className="input"
                type="text"
                name="firstname"
                value={userDetails.firstname}
                onChange={handleChange}
                placeholder="First Name"
                required
              />

              <input
                type="text"
                className="input"
                name="lastname"
                value={userDetails.lastname}
                onChange={handleChange}
                placeholder="Last Name"
                required
              />
            </div>
            <input
              type="text"
              className="input"
              name="username"
              value={userDetails.username}
              onChange={handleChange}
              placeholder="Username"
              required
            />

            <input
              type="email"
              className="input"
              name="email"
              value={userDetails.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />

            <div className="aor-and-role-wrapper">
              <select
                name="role"
                value={userDetails.role}
                onChange={handleChange}
                required>
                <option value="">Select Role</option>
                <option value="Agent">Agent</option>
              </select>
              {/* New Checkbox for isAOR */}
              <label style={{ flexDirection: "row" }}>
                <input
                  type="checkbox"
                  name="isAOR"
                  checked={userDetails.isAOR}
                  onChange={handleChange}
                />{" "}
                Is Agent on Record?
              </label>
            </div>
            <div style={{ width: "100%" }} className="choice-button">
              <button type="button" onClick={() => dispatch(toggleForm(false))}>
                <span>Cancel</span>
              </button>
              <button type="submit">
                <span>Confirm</span>{" "}
              </button>
            </div>
          </div>
          <ToastContainer />
        </div>
      </form>
    </div>
  );
};

export default RegisterAgentsForm;
